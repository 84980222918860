import abonnement from "../assets/products/svg/abonnement.svg";
import achat from "../assets/products/svg/achat.svg";
import assistance from "../assets/products/svg/assistance.svg";
import connaissance from "../assets/products/svg/connaissance.svg";
import crm from "../assets/products/svg/crm.svg";
import dashboard from "../assets/products/svg/dashboard.svg";
import discussion from "../assets/products/svg/discussion.svg";
import document from "../assets/products/svg/document.svg";
import email from "../assets/products/svg/email.svg";
import fabrication from "../assets/products/svg/fabrication.svg";
import hr from "../assets/products/svg/hr.svg";
import icon from "../assets/products/svg/icon.svg";
import inventaire from "../assets/products/svg/inventaire.svg";
import location from "../assets/products/svg/location.svg";
import planification from "../assets/products/svg/planification.svg";
import pointVente from "../assets/products/svg/point_vente.svg";
import projet from "../assets/products/svg/projet.svg";
import service_sur_site from "../assets/products/svg/service_sur_site.svg";
import signature from "../assets/products/svg/signature.svg";
import siteWeb from "../assets/products/svg/site_web.svg";
import social from "../assets/products/svg/social.svg";
import studio from "../assets/products/svg/studio.svg";
import timesheets from "../assets/products/svg/timesheets.svg";
import ventes from "../assets/products/svg/ventes.svg";

import ecommerce from "../assets/products/svg/ecommerce.svg";
import blog from "../assets/products/svg/blog.svg";
import forums from "../assets/products/svg/forums.svg";
import elearning from "../assets/products/svg/elearning.svg";
import live_chat from "../assets/products/svg/live_chat.svg";
import frais from "../assets/products/svg/frais.svg";
import feuille_calcul from "../assets/products/svg/feuille_calcul.svg";
import voip from "../assets/products/svg/voip.svg";
import iot from "../assets/products/svg/iot.svg";

import validation from "../assets/products/svg/validation.svg";
import rendez_vous from "../assets/products/svg/rendez_vous.svg";
import service_site from "../assets/products/svg/service_site.svg";
import feuille_temps from "../assets/products/svg/feuille_temps.svg";
import events from "../assets/products/svg/events.svg";
import sondages from "../assets/products/svg/sondages.svg";
import sms from "../assets/products/svg/sms.svg";
import marketingAutomatisation from "../assets/products/svg/marketingAutomatisation.svg";
import parc from "../assets/products/svg/parc.svg";
import referral from "../assets/products/svg/referral.svg";
import evaluation from "../assets/products/svg/evaluation.svg";
import conges from "../assets/products/svg/conges.svg";
import recruitment from "../assets/products/svg/recruitment.svg";
import employe from "../assets/products/svg/employe.svg";
import qualite from "../assets/products/svg/qualite.svg";
import maintenance from "../assets/products/svg/maintenance.svg";
import plm from "../assets/products/svg/plm.svg";
import facturation from "../assets/products/svg/facturation.svg";

export const iconProduct = {
  blog,
  forums,
  elearning,
  live_chat,
  frais,
  feuille_calcul,
  ecommerce,
  abonnement,
  achat,
  assistance,
  iot,
  voip,
  connaissance,
  crm,
  dashboard,
  discussion,
  document,
  email,
  fabrication,
  hr,
  icon,
  inventaire,
  location,
  planification,
  pointVente,
  projet,
  service_sur_site,
  signature,
  siteWeb,
  social,
  studio,
  timesheets,
  ventes,
  validation,rendez_vous,service_site,feuille_temps,events,sondages,sms,marketingAutomatisation,parc,referral,evaluation,conges,recruitment,employe,qualite,maintenance,plm,facturation
};
