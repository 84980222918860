import React, { useEffect, useState } from 'react'
import categoryRepository from '../../../repositories/categoryRepository';
import { listProces } from '../../../types/constants/home/list_methode';
import { Categorie } from '../../../types/model/product';

export const ListServices = () => {
    const [services, setServices] = useState<Categorie[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);



    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await categoryRepository.fetchCategories();
                setServices(result);
            } catch (error) {
                setError("Failed to fetch offers");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);
    if (loading) return <p>Loading...</p>;
    if (error) return <div className=" w-full h-full flex flex-row  items-center justify-between">
        <div className=" w-full grid grid-cols-1  sm:grid-cols-2 xl:grid-cols-3 gap-8 lg:gap-16 ">
            {listProces.map((service) => (
                <div key={service.title} className="w-full py-2  shadow-md border-gray-600 rounded-md ">
                    <div className="flex flex-col gap-8 pb-4">
                        <div className="w-full bg-gray-100 min-h-80 h-80 flex items-center justify-center">
                            <img
                                src={service.icon as string ?? ""}
                                loading="lazy"
                                alt={service.title}
                                className="object-contain h-full"
                            />
                        </div>
                        <div className="flex flex-col px-4 gap-4 pr-12">
                            <h3 className="font-semibold text-2xl md:text-4xl pr-8 leading-10">
                                {service.title}
                            </h3>
                            <p className="text-justify">{service.description}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>;

    return (
        <div className=" w-full h-full flex flex-row  items-center justify-between">
            <div className=" w-full grid grid-cols-1  sm:grid-cols-2 xl:grid-cols-3 gap-8 lg:gap-16 ">
                {Array.isArray(services) && services.map((service, index) => (
                    <div key={`${service.codeProduitCategorie}_${index}`} className="w-full py-2  shadow-md border-gray-600 rounded-md ">
                        <div className="flex flex-col gap-8 pb-4">
                            <div className="w-full bg-gray-100 min-h-80 h-80 flex items-center justify-center">
                                <img
                                    src={service.image?.url as string ?? getImageCategorie(service.slug)}
                                    loading="lazy"
                                    alt={service.nom}
                                    className="object-contain h-full"
                                />
                            </div>
                            <div className="flex flex-col px-4 gap-4 pr-12">
                                <h3 className="font-semibold text-2xl md:text-4xl pr-8 leading-10">
                                    {service.nom}
                                </h3>
                                <p className="text-justify">{service.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}


function getImageCategorie(name: string) {
    return listProces.filter(cate => cate.title.toLowerCase() === name.toLowerCase())[0].icon ?? listProces[0].icon
}