import { CartItemParams } from './../types/model/cart';
import cartService from "../services/cartService";
import { Panier, PanierItem } from "../types/model/cart";

const cartRepository = {
    getCart: async (codeUtilisateur: string): Promise<Panier> => {
        try {
            const cartData: Panier = await cartService.getCart(codeUtilisateur);
            if (Array.isArray(cartData) && cartData.length > 0) {
                return cartData[0];  // Return the first element of the array
            } else {
                throw new Error("Cart not found or empty.");
            }
        } catch (error) {
            console.error("Erreur lors de la connexion", error);
            throw error;
        }
    },

    removeItem: async (codeUtilisateur: string, itemId: number): Promise<Panier> => {
        try {
            await cartService.removeItemFromCart(itemId);
            const cartData = await cartService.getCart(codeUtilisateur);
            if (Array.isArray(cartData) && cartData.length > 0) {
                return cartData[0];  // Return the first element of the array
            } else {
                throw new Error("Cart not found or empty.");
            }
        } catch (error) {
            console.error("Erreur lors de la suppression de l'article", error);
            throw error;
        }
    },

    // New method to add a PanierItem to the cart
    addCartItem: async (panierItem: CartItemParams): Promise<PanierItem> => {
        try {
            const updatedCart = await cartService.addCartItem(panierItem);
            return updatedCart; // Return the updated cart
        } catch (error) {
            console.error("Erreur lors de l'ajout de l'article au panier", error);
            throw error;
        }
    },


    
};

export default cartRepository;
