import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { OrdersTableSkeleton } from '../../components/loading/skeleton';
import useSubscriptions from '../../hooks/useSubscription'
import { ErrorPage } from '../error';
import EmptySubscription from "../../components/me/empty_table_component";
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import Pagination from '../../components/orders/pagination';
import { SubscriptionsTable } from '../../components/abonnements/subscriptionTables';
import { Status } from '../../types/model/order';
import { StatutAbonnement } from '../../types/model/abonnement';


import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export const Subscriptions = () => {
    // const dispatch = useDispatch<AppDispatch>();


    // Accéder aux données du store
    const { user } = useSelector((state: RootState) => state.auth);

    const { abonnements, loading, error, cancelAbonnement, loadingCancel, errorCancel } = useSubscriptions()


    // État pour le tri
    const [selectedOrderType, setSelectedOrderType] = useState<string | null>(
        null
    );
    const [selectedDuration, setSelectedDuration] = useState<string | null>(null);

    // Fonction pour gérer le changement de type de commande
    const handleOrderTypeChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setSelectedOrderType(event.target.value);
    };

    // Fonction pour gérer le changement de durée
    const handleDurationChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setSelectedDuration(event.target.value);
    };

    // Utilisation de useMemo pour optimiser le filtrage et tri des commandes
    const filteredOrders = useMemo(() => {
        let filtered = abonnements;

        // Filtrer par type de commande (statut)
        if (selectedOrderType && selectedOrderType !== "All orders") {
            filtered = filtered.filter(
                (order) => order.statut === selectedOrderType
            );
        }

        // Filtrer par durée
        if (selectedDuration) {
            const now = new Date();
            filtered = filtered.filter((order) => {
                const orderDate = new Date(order.dateDebut).getTime(); // Utilisation de getTime pour éviter les problèmes de comparaison
                switch (selectedDuration) {
                    case "this week":
                        return orderDate >= now.getTime() - 7 * 24 * 60 * 60 * 1000; // Semaine dernière
                    case "this month":
                        return orderDate >= now.getTime() - 30 * 24 * 60 * 60 * 1000; // Mois dernier
                    case "last 3 months":
                        return orderDate >= now.getTime() - 3 * 30 * 24 * 60 * 60 * 1000; // 3 derniers mois
                    case "last 6 months":
                        return orderDate >= now.getTime() - 6 * 30 * 24 * 60 * 60 * 1000; // 6 derniers mois
                    case "this year":
                        return orderDate >= now.getTime() - 365 * 24 * 60 * 60 * 1000; // Année dernière
                    default:
                        return true;
                }
            });
        }

        return filtered;
    }, [abonnements, selectedOrderType, selectedDuration]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;

    // Calcul du nombre total de pages
    const totalPages = useMemo(
        () => Math.ceil(filteredOrders.length / itemsPerPage),
        [filteredOrders]
    );

    const paginatedOrders = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return filteredOrders.slice(startIndex, endIndex);
    }, [filteredOrders, currentPage]);

    const handlePageChange = (newPage: number) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleCancel = useCallback(
        (itemId: string) => {
            if (user) {
                cancelAbonnement(itemId);
            }
        },
        [user, cancelAbonnement]
    );

    const showToast = useCallback(
        () => {
            if (!loadingCancel) {
                if (errorCancel) {
                    toast.error(errorCancel || 'Erreur inattendue');
                }

            }
        },
        [loadingCancel, errorCancel],
    )

    useEffect(() => {
        showToast()
    }, [errorCancel, loadingCancel, showToast])






    if (loading) return <section className="bg-white py-8 antialiased md:py-16 h-full">
        <div className="mx-auto max-w-6xl flex items-center  h-full w-full"><OrdersTableSkeleton /> </div>
    </section>;
    if (error) return <section className="bg-white py-8 antialiased md:py-16 h-full">
        <div className="mx-auto max-w-6xl flex items-center  h-full"><ErrorPage /></div>
    </section>;

    if (abonnements && abonnements.length === 0) {
        return <section className=" px-2 py-8 antialiased md:py-16">
            <EmptySubscription message="Aucun abonnement!" />
        </section>
    }
    return (
        <section className=" px-2 py-8 antialiased md:py-16">
            <div className="mx-auto max-w-6xl">
                <div className="gap-4 sm:flex sm:items-center sm:justify-between">
                    <h2 className="text-xl font-semibold text-gray-900  sm:text-2xl">
                        Mes Abonnements
                    </h2>
                    <div className="mt-6 gap-4 space-y-4 sm:mt-0 sm:flex sm:items-center sm:justify-end sm:space-y-0">
                        <div>
                            <label
                                htmlFor="order-type"
                                className="sr-only mb-2 block text-sm font-medium text-gray-900"
                            >
                                Sélectionnez le statut de la commande
                            </label>
                            <select
                                id="order-type"
                                className="block w-full min-w-[8rem] rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
                                onChange={handleOrderTypeChange}
                            >
                                <option value="All orders">Tous</option>
                                <option value={StatutAbonnement.PENDING}>En attente</option>
                                <option value={Status.EXPIRED}>En cour</option>
                                <option value={Status.CANCELLED}>Annuler</option>
                                <option value={Status.EXPIRED}>Expirer</option>

                            </select>
                        </div>
                        <span className="inline-block text-gray-500 dark:text-gray-400">
                            {" "}
                            Depuis{" "}
                        </span>
                        <div>
                            <label
                                htmlFor="duration"
                                className="sr-only mb-2 block text-sm font-medium text-gray-900"
                            >
                                Sélectionnez la durée
                            </label>
                            <select
                                id="duration"
                                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
                                onChange={handleDurationChange}
                            >
                                <option value="this week">Cette semaine</option>
                                <option value="this month">Ce Mois</option>
                                <option value="last 3 months">Les 3 derniers mois</option>
                                <option value="last 6 months">Les 3 derniers mois</option>
                                <option value="this year">Cette année</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="min-h-96 h-full">
                    <SubscriptionsTable subscriptions={paginatedOrders} cancelSubscription={handleCancel} />
                </div>
                <div className="mt-8 flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                    <div className=" hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                        <div>
                            <p className="text-sm text-gray-700">
                                Affichage de <span className="font-medium">1</span> à{" "}
                                <span className="font-medium">{paginatedOrders.length}</span> sur{" "}
                                <span className="font-medium">{filteredOrders.length}</span> résultats
                            </p>
                        </div>
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            handlePageChange={handlePageChange} />
                    </div>
                </div>
            </div></section>
    )
}
