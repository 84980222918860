import { clsx } from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { z } from "zod";
import { GoogleButton } from "../components/auth/google_button";
import { AppDispatch, RootState } from "../store";
import { register } from "../store/authSlice";

// 2. Créer un schéma de validation pour les données du formulaire
const formSchema = z
    .object({
        email: z.string().email({ message: "Email invalide" }),
        nom: z.string().min(1, { message: "Nom invalide" }),
        prenom: z.string().min(1, { message: "Prénom invalide" }),
        telephone: z.string().regex(/^\d+$/, { message: "Téléphone invalide, uniquement des chiffres sont acceptés" }),
        password: z
            .string()
            .min(6, {
                message: "Le mot de passe doit contenir au moins 6 caractères",
            }),
        confirmpass: z.string(),
        acceptTerm: z.boolean().refine((value) => value === true, {
            message: "Veuillez accepter les termes et conditions",
        }),
    })
    .refine((data) => data.password === data.confirmpass, {
        message: "Les mots de passe ne correspondent pas",
        path: ["confirmpass"],
    });
// 3. Définir un type TypeScript basé sur le schéma
type FormData = z.infer<typeof formSchema>;

const Register = () => {
    return (
        <div className="relative py-24 w-full ">
            <div className=" flex max-w-lg mx-auto  my-16 overflow-hidden bg-white rounded-lg lg:space-x-8 bg lg:max-w-xl">
                {/* <div className="items-center hidden lg:flex lg:w-1/2">
                    <img
                        src={Images.login}
                        alt="secure-login-animate.svg"
                    />
                </div>{" "} */}

                <div className="w-full px-6 py-8 md:px-8 ">
                    <h2 className="text-3xl font-semibold  text-gray-900">
                        Créer un compte
                    </h2>{" "}
                    <p className="mt-4   text-gray-500 ">
                        Rejoignez notre <span className="text-gray-800">communauté </span>
                        et profiter de toutes nos offres exclusives !
                    </p>{" "}
                    <div className="mt-8"></div>
                    <RegisterForm></RegisterForm>
                    <div className="flex items-center justify-between mt-8">
                        <span className="w-1/5 border-b  lg:w-1/4" />{" "}
                        <a
                            href="/"
                            className="text-xs text-center text-gray-500 uppercase hover:underline"
                        >
                            ou utilisez
                        </a>{" "}
                        <span className="w-1/5 border-b lg:w-1/4" />
                    </div>{" "}
                    <div className="mt-4 space-y-3 sm:flex sm:items-center sm:space-x-4 sm:space-y-0">
                        <GoogleButton />{" "}
                    </div>{" "}
                    <div className="text-sm font-medium text-gray-500  mt-8">
                        Vous avez déjà un compte ?
                        <a className="ml-1 text-blue-700  hover:underline" href="/signin">
                            Connectez-vous ici.
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;

const RegisterForm = () => {
    const [formData, setFormData] = useState<FormData>({
        email: "",
        nom: "",
        prenom: "",
        telephone: "",
        confirmpass: "",
        password: "",
        acceptTerm: false,
    });
    const navigate = useNavigate();

    // Créer un état pour les erreurs de validation
    const [formErrors, setFormErrors] = useState<{
        email?: string;
        password?: string;
        nom?: string;
        confirmPass?: string;
        prenom?: string;
        telephone?: string;
        acceptTerm?: string;
    }>({});
    const [hasSubmitted, setHasSubmitted] = useState(false); // État pour suivre la tentative d'inscription
    const dispatch = useDispatch<AppDispatch>();
    const { errorRegister, loadingRegister } = useSelector(
        (state: RootState) => state.auth
    );

    // Fonction de gestion du changement des inputs
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            acceptTerm: e.target.checked,
        });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        // Valider les données du formulaire
        const result = formSchema.safeParse(formData);

        if (!result.success) {
            // En cas d'erreurs de validation, extraire les messages et les afficher
            const errors = result.error.flatten().fieldErrors;
            setFormErrors({
                email: errors.email?.[0],
                password: errors.password?.[0],
                nom: errors.nom?.[0],
                telephone: errors.telephone?.[0],
                confirmPass: errors.confirmpass?.[0],
                prenom: errors.prenom?.[0],
                acceptTerm: errors.acceptTerm?.[0],
            });
        } else {
            // Indiquer qu'une tentative d'inscription a été faite
            setHasSubmitted(true);
            // Si tout est bon, vous pouvez envoyer les données
            dispatch(
                register(
                    formData.nom,
                    formData.prenom,
                    formData.email,
                    formData.password,
                    formData.telephone
                )
            );
            setFormErrors({});
        }
    };

    const showToast = useCallback(
        () => {
            if (!loadingRegister && hasSubmitted) {
                // Vérifier si une tentative a été faite
                if (errorRegister) {
                    toast.error(errorRegister || "Erreur inattendue");
                } else {
                    toast.success("Inscription réussie !!");
                    navigate("/signin");
                }
            }
        },
        [errorRegister, loadingRegister, hasSubmitted, navigate] // Inclure hasSubmitted dans les dépendances
    );

    useEffect(() => {
        showToast();
    }, [dispatch, errorRegister, loadingRegister, showToast]);

    return (
        <form autoComplete="nope" onSubmit={handleSubmit}>
            <div className="mt-4 flex md:flex-row gap-4 ">
                <div className="w-full md:w-1/2">
                    <label htmlFor="nom" className="block mb-2 text-sm text-gray-600 ">
                        Nom
                    </label>{" "}
                    <input
                        type="text"
                        name="nom"
                        id="nom"
                        required
                        value={formData.nom}
                        onChange={handleChange}
                        autoComplete="off"
                        className="w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-lg focus:ring-opacity-20"
                    />
                    {formErrors.nom && (
                        <span
                            className="block my-1 text-sm text-gray-600 "
                            style={{ color: "red" }}
                        >
                            {formErrors.nom}
                        </span>
                    )}
                </div>
                <div className="w-full md:w-1/2">
                    <label htmlFor="prenom" className="block mb-2 text-sm text-gray-600 ">Prénom</label>{" "}
                    <input

                        type="text"
                        name="prenom"
                        required
                        id="prenom"
                        value={formData.prenom}
                        onChange={handleChange}
                        className="w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-lg  focus:border-primary  focus:outline-none focus:ring focus:ring-primary  focus:ring-opacity-20"
                    />
                    {formErrors.prenom && (
                        <span
                            className="block my-1 text-sm text-gray-600 "
                            style={{ color: "red" }}
                        >
                            {formErrors.prenom}
                        </span>
                    )}
                </div>
            </div>{" "}
            <div className="mt-4 flex md:flex-row gap-4 ">
                <div className="w-full md:w-1/2">
                    <label htmlFor="email" className="block mb-2 text-sm text-gray-600 ">
                        E-mail
                    </label>{" "}
                    <input
                        type="email"
                        name="email"
                        id="email"
                        required
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-lg  focus:border-primary  focus:outline-none focus:ring focus:ring-primary  focus:ring-opacity-20"
                    />
                    {formErrors.email && (
                        <span
                            className="block my-1 text-sm text-gray-600 "
                            style={{ color: "red" }}
                        >
                            {formErrors.email}
                        </span>
                    )}
                </div>
                <div className="w-full md:w-1/2">
                    <label htmlFor="telephone" className="block mb-2 text-sm text-gray-600 ">
                        Téléphone
                    </label>
                    <input
                        type="tel"
                        name="telephone"
                        id="telephone"
                        placeholder="+237"
                        required
                        value={formData.telephone}
                        onChange={handleChange}
                        inputMode="tel" // Affiche un clavier numérique sur mobile
                        pattern="[0-9]+" // N'accepte que des chiffres
                        autoComplete="off"
                        className="w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-lg focus:border-primary focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-20"
                    />
                    {formErrors.telephone && (
                        <span className="block my-1 text-sm text-gray-600" style={{ color: "red" }}>
                            {formErrors.telephone}
                        </span>
                    )}
                </div>

            </div>{" "}
            <div className="relative mt-4">
                <label htmlFor="password" className="block mb-2 text-sm text-gray-600">
                    Mot de passe
                </label>
                <input
                    autoComplete={"new-password"}
                    type="password"
                    name="password"
                    id="password"
                    required
                    value={formData.password}
                    onChange={handleChange}
                    className="w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-lg  focus:border-primary  focus:outline-none focus:ring focus:ring-primary  focus:ring-opacity-20"
                />

                {formErrors.password && (
                    <span
                        className="block my-1 text-sm text-gray-600"
                        style={{ color: "red" }}
                    >
                        {formErrors.password}
                    </span>
                )}
            </div>
            <div className="relative mt-4">
                <label
                    htmlFor="confirmpass"
                    className="block mb-2 text-sm text-gray-600"
                >
                    Confirmez le mot de passe
                </label>
                <input
                    autoComplete={"new-password"}
                    type="password"
                    name="confirmpass"
                    id="confirmpass"
                    required
                    value={formData.confirmpass}
                    onChange={handleChange}
                    className="w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-lg  focus:border-primary  focus:outline-none focus:ring focus:ring-primary  focus:ring-opacity-20"
                />

                {formErrors.confirmPass && (
                    <span
                        className="block my-1 text-sm text-gray-600"
                        style={{ color: "red" }}
                    >
                        {formErrors.confirmPass}
                    </span>
                )}
            </div>
            <div className="flex justify-between mt-4">
                <div className="col-md-6 offset-md-4">
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            name="acceptTerm"
                            id="acceptTerm"
                            checked={formData.acceptTerm}
                            onChange={handleCheckboxChange}
                            className="border-gray-200 rounded shadow-sm text-primary  focus:border-primary  focus:outline-none focus:ring focus:ring-primary  focus:ring-opacity-20"
                        />{" "}
                        <label
                            htmlFor="acceptTerm"
                            className="font-medium text-gray-700 text-sm ml-2"
                        >
                            I accept the
                            <a
                                className="ml-1 text-blue-700 hover:underline"
                                href="/terms-and-conditions/"
                            >
                                Terms and Conditions
                            </a>
                        </label>
                    </div>
                    {formErrors.acceptTerm && (
                        <span
                            className="block my-1 text-sm text-gray-600"
                            style={{ color: "red" }}
                        >
                            {formErrors.acceptTerm}
                        </span>
                    )}
                </div>{" "}
            </div>{" "}
            <div className="mt-8">
                <button
                    type="submit"
                    className={clsx(
                        "w-full px-4 py-2 tracking-wide text-white transition-colors duration-300 transform rounded-md bg-primary-500 hover:bg-primary-500/70 focus:outline-none focus:bg-primary/70",
                        { hidden: loadingRegister }
                    )}
                >
                    S'inscrire
                </button>

                <button
                    type="button"
                    disabled={true}
                    className={clsx(
                        "w-full py-2 px-4 flex justify-center items-center  bg-primary-500/70   text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg",
                        { hidden: !loadingRegister }
                    )}
                >
                    <svg
                        width={20}
                        height={20}
                        fill="currentColor"
                        className="mr-2 animate-spin"
                        viewBox="0 0 1792 1792"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
                    </svg>
                    loading
                </button>
            </div>
        </form>
    );
};
