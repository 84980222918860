import React from "react";
import { Outlet } from "react-router-dom";
import { Stepper } from "../home/stepper/stepper";


export default function ShopLayout() {
    //px-4 lg:px-16 xl:px-48

    return (
        <div className=" flex flex-col items-center justify-center w-full h-full " >
            <div className="pt-8 sm:pt-0 md:pt-0 lg:pt-16 xl:pt-16  container px-4  xl:px-16 2xl:px-0  w-full space-y-4">
                <div className=" w-full flex items-center">
                    <Stepper />
                </div>

                <div className="h-full w-full ">
                    <Outlet />
                </div>



            </div>
        </div>
    );
}




