import { motion } from "framer-motion";
import React from "react";
import { Images } from "../../../constant";
import { listMethodes } from "../../../types/constants/home/list_methode";
import { H2 } from "../../commons/h_component";
import ImageMotion from "../../commons/wrappers/imageMotion";

export default function Fonctionnement() {
    return (
        <section className="min-h-96  py-8 w-full h-full flex justify-center">
            <div className="container  w-full  h-full flex flex-col px-4   xl:px-16 2xl:px-0 items-center gap-24 ">
                <ImageMotion
                    classNames=""
                    Component={

                        <H2 classNames="" Component={<p>Notre Approche</p>} />

                    }
                />

                <div className="w-full h-full flex flex-col md:items-center lg:items-start lg:flex-row gap-16 xl:items-center justify-between flex-col-reverse">
                    <ul className="w-full lg:w-2/5 xl:w-1/3 h-full  space-y-8">
                        {listMethodes.map((faq, index) => (
                            <motion.div
                                key={faq.title}
                                whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                                transition={{ duration: 0.5 }}
                                className='w-full '>
                                <li className="w-full flex  flex-row gap-8">
                                    <div className="min-w-20 min-h-20 w-20 h-20  md:min-w-28 md:min-h-28 md:w-28 md:h-28 rounded-full bg-gray-100 flex items-center justify-center">
                                        <img
                                            src={Images.ecriture}
                                            loading="lazy"
                                            sizes="(max-width: 479px) 48px, (max-width: 1279px) 56px, (max-width: 1439px) 4vw, 56px"
                                            alt="Icône écriture"
                                            className="image-2"
                                        />
                                    </div>
                                    <div className="w-full overflow-hidden text-ellipsis break-words flex  flex-col gap-4">
                                        <h3 className="text-xl font-bold">{index + 1} - {faq.title}</h3>
                                        <div className="">
                                            {faq.description}
                                        </div>
                                    </div>
                                </li>
                            </motion.div>


                        ))}
                    </ul>
                    <div className=" md:mt-0  sm:h-96  w-full  max-w-xl lg:w-1/2 lg:h-full  flex items-center justify-center">
                        <img
                            src={Images.bg5}
                            alt="background"
                            className="object-fill rounded-lg  h-full md:w-full"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
