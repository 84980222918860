import React from 'react';

const ContactThankYouPage: React.FC = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100 p-8">

            <div className='rounded-full bg-primary-500/50 p-8 flex items-center justify-center mb-6'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-36 stroke-[0.5px] stroke-white">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                </svg>

            </div>


            <h1 className="text-lg lg:text-4xl xl:text-6xl text-center font-bold text-primary-500 mb-6">Merci pour votre prise de contact !!!</h1>
            <p className="lg:text-lg  text-center text-gray-700">
                Votre message a été envoyé avec succès
            </p>
            <p className="lg:text-lg  text-center text-gray-700 mt-4">
                Nous vous répondrons sous peu.
            </p>

        </div>
    );
};

export default ContactThankYouPage;
