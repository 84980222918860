import React, { Suspense, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumbs } from '../components/breadcrumbs';
import CartComponent from '../components/cart/cart_component';
import EmptyCart from '../components/cart/empty_cart';
import { LoadingCartSkeleton } from '../components/cart/loading_cart_skeleton';
import { AppDispatch, RootState } from '../store';
import { ErrorPage } from './error';
import { fetchCart, removeCartItem } from '../store/cartSlice';
import { fetchCurrentOrder } from '../store/orderSlice';
import { useAdresseContext } from '../context/AdresseContext';

export default function Cart() {
    const dispatch = useDispatch<AppDispatch>();
    const { fetchAdresses } = useAdresseContext(); // Access the fetchAdresses function

    // Access data from Redux store
    const { cart, loading, error } = useSelector((state: RootState) => state.cart);
    const { user } = useSelector((state: RootState) => state.auth);

    // Define a function to fetch the cart and address data
    const fetch = useCallback(
        () => {
            dispatch(fetchCart(user?.codeUtilisateur ?? null));  // Fetch cart using user info

            if (user) {
                dispatch(fetchCurrentOrder(user.codeUtilisateur)); // Fetch current order

                fetchAdresses(user.codeUtilisateur); // Fetch addresses
            }
        },
        [dispatch, fetchAdresses, user], // Ensure fetchAdresses is part of dependencies
    );

    // Handle item removal using Redux
    const handleRemoveItem = useCallback(
        (itemId: number) => {
            dispatch(removeCartItem(user?.codeUtilisateur ?? null, itemId)); // Remove item from cart
        },
        [dispatch, user],
    );

    useEffect(() => {
        // Fetch cart and addresses when the component mounts
        fetch();
    }, [fetch]);

    const renderCartContent = () => {
        if (cart && cart.items.length > 0) {
            return <CartComponent cart={cart} handleDelete={handleRemoveItem} />;
        } else {
            return <EmptyCart />;
        }
    };

    return (
        <section className='w-full flex flex-col justify-center bg-gray-100'>
            <Breadcrumbs />
            <div className='w-full bg-gray-50'>
                <div className='mx-auto container px-4 xl:px-16 2xl:px-0'>
                    {loading ? (
                        <LoadingCartSkeleton />  // Display skeleton during loading
                    ) : error ? (
                        <div className='py-24'><ErrorPage /></div>
                    ) : (
                        <Suspense fallback={<LoadingCartSkeleton />}>
                            {renderCartContent()}
                        </Suspense>
                    )}
                </div>
            </div>
        </section>
    );
}
