import { Abonnement } from './../types/model/abonnement';
import subscriptionService from "../services/subscription_service";
import { SubscriptionData } from "../types/model/subscribe_data_model";

const subscriptionRepository = {
    getSubscriptionData: async (code: string, codeItem: string | null): Promise<SubscriptionData> => {
        try {
            const subscriptionData = await subscriptionService.getSubscriptionData(code, codeItem);
            return subscriptionData;
        } catch (error) {
            console.error("Erreur lors de la récupération des données de souscription", error);
            throw error;
        }
    },
    getSubscriptions: async (): Promise<Abonnement[]> => {
        try {
            const subscriptions = await subscriptionService.getSubscriptions();
            return subscriptions;
        } catch (error) {
            console.error("Erreur lors de la récupération des abonnements", error);
            throw error;
        }
    },
    cancelSubscription: async (code: string): Promise<void> => {
        try {
            await subscriptionService.cancelSubscription(code);
            return;
        } catch (error) {
            console.error("Erreur lors de la récupération des données de souscription", error);
            throw error;
        }
    },

};

export default subscriptionRepository;