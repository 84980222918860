import React from 'react'
import { Images } from '../../constant';
import ImageMotion from '../commons/wrappers/imageMotion';


export const BannerAboutComponent = () => {
    return (
        <div className="relative flex flex-col items-center bg-white relative items-center lg:h-full">


            <div className='container w-full px-4  xl:px-16 2xl:px-24 h-full flex flex-col lg:flex-row gap-x-16'>

                <div className='lg:w-[58%] h-full   flex flex-col justify-center pt-8 pb-4  gap-10'>


                    <span className='text-3xl xl:text-5xl 2xl:pr-16 leading-normal font-semibold'>Expert en transformation digitale (Cloud | Devops | CyberSécurite | Agilité) </span>
                    <span className='text-lg 2xl:pr-24 leading-8'>Chez <span className='font-semibold text-underline text-primary-500'>CloudApps</span>,  nous nous engageons à fournir des solutions de haute qualité à des tarifs accessibles pour les petites et moyennes entreprises. Nous offrons un accompagnement sur mesure pour une digitalisation complète et personnalisée, en optimisant vos processus et en améliorant votre efficacité opérationnelle.</span>
                    <p className="text-gray-500 text-base font-normal leading-relaxed text-start">
                        La sécurité étant l'une de nos priorités majeures, notre équipe est composée d'experts certifiés au niveau international dans chacun des domaines où nous offrons nos services. Ils sont dédiés à vous accompagner pour garantir un niveau de sécurité élevé, adapté aux spécificités de votre entreprise.</p>
                </div>
                <div className='relative  lg:w-[42%]  flex-grow '>
                    <ImageMotion classNames='relative w-full h-full flex items-center ' Component={<img loading={'lazy'}

                        src={Images.about}//"https://img.freepik.com/photos-gratuite/vue-ingenieur-au-travail-pour-celebration-journee-ingenieurs_23-2151615006.jpg?ga=GA1.1.1837181882.1709727885&semt=ais_hybrid"
                        alt="rectangle17"

                        className='object-contain rounded-lg max-w-full w-full ' />
                    }></ImageMotion>
                </div>
            </div>


        </div>
    );
}
