import service from "../services/services";
import { Service } from "../types/model/product";

const serviceRepository = {
  getServices: async (): Promise<Service[]> => {
    const data = await service.getServices();
    
    return data
      .filter(product => product.visible)
      .map(product => ({
        ...product,
        description: product.description.replace(/\\n/g, "\n\n"),
      }));
  },
};

export default serviceRepository;