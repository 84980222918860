// src/repositories/categoryRepository.ts
import categoryService from '../services/productCategories';
import { Categorie } from '../types/model/product';

const categoryRepository = {
  fetchCategories: async (): Promise<Categorie[]> => {
    return await categoryService.getCategories();
  },
  
};

export default categoryRepository;
