import clsx from 'clsx';
import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import LoadingSpinner3 from '../../components/loading/loading_spinner3';
import SubscriptionForm from '../../components/subscription/odoo/subscribe_form';
import { initialLocation } from '../../constant/constant';
import useSubscriptionData from '../../hooks/useSubscriptionData';
import { InternalError } from '../InternalError';
import { AppDispatch } from '../../store';
import { setInitData } from '../../store/subscriptionSlice';
import { FormBaseState } from '../../types/model/state_form_subscribe';

export const SubscribePage = () => {
    const { code, codeItem } = useParams<{ code: string, codeItem?: string }>();
    const dispatch = useDispatch<AppDispatch>();

    const { data, error, loading } = useSubscriptionData(code ?? '', codeItem ?? null);

    const navigate = useNavigate();

    // useCallback to memoize the handleNextStep function
    const handleNextStep = useCallback(() => {
        navigate(`/cart`);
    }, [navigate]);

    // useMemo to memoize the initial state based on data and codeItem
    const initialState: FormBaseState = useMemo(() => {

        let state: FormBaseState = {
            numUsers: 1,
            hostingType: 'cloud',
            typePlan: 'starter',
            isAnnual: true,
            website: false,
            typeMaintenance: null,
            numberOfItem: 1,
            selectedProducts: [],
            cost: 0,
            maintenance: false,
            supportImplementation: false,
            totalPrice: 0,
            numberInstallationDays: 3,
            location: initialLocation,
            urlServer: undefined,
            errorUrl: false
        };

        if (codeItem && data && !loading && data.item) {
            const item = data.item;

            state = {
                numUsers: item.nombreUtilisateurs,
                hostingType: item.typeHebergement.toLowerCase(),
                typePlan: item.planSouscription.nom.toLowerCase(),
                isAnnual: item.periodeSouscription.periode > 1,
                website: item.siteWeb ?? false,
                typeMaintenance: item.supportMaintenance?.codeSupportMaintenance ?? null,
                numberOfItem: item.nombreItems,
                selectedProducts: item.implementationServices
                    ? item.implementationServices.map(e => e.nomService)
                    : [],
                cost: 0,
                urlServer: item.urlInstance ?? undefined,
                maintenance: item.supportMaintenance !== null,
                supportImplementation: item.implementationServices !== null && item.implementationServices.length !== 0,
                totalPrice: item.prixTotal,
                numberInstallationDays: 3,
                location: initialLocation,
                errorUrl: false
            };
        }
        // console.log(state.urlServer)
        return state;
    }, [codeItem, data, loading]);



    useEffect(() => {
        if (!loading && data?.item) {
            // console.log(initialState)
            dispatch(setInitData(initialState));
        }
    }, [dispatch, loading, data, initialState]);



    if ((loading || !data) && !error) {
        return (
            <div className="w-full h-screen flex items-center">
                <div className="mx-auto container px-4 xl:px-16 2xl:px-0">
                    <LoadingSpinner3 />
                </div>
            </div>
        );
    }

    if (error || !(data && (data.product.nom.toLowerCase().includes('odoo') || data.product.nom.toLowerCase().includes('glpi')) && !data.product.visible)) {
        return (
            <section className="w-full h-screen flex items-center">
                <div className="mx-auto container px-4 xl:px-16 2xl:px-0">
                    <InternalError />
                </div>
            </section>
        );
    }

    return (
        <div className={clsx('flex flex-col items-center relative items-center lg:h-full pt-14 pb-24')}>
            <div className="w-full container mx-auto px-4 xl:px-16 2xl:px-0">
                <SubscriptionForm onNext={handleNextStep} dataSubscription={data!} subscriptionType={data.product.nom.toLowerCase().includes("odoo") ? "odoo" : "glpi"} />
            </div>
        </div>
    );
};
