import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSuffixeServer, getUrlServer, isValidUrl, normaliseUrl } from "../../../constant/functions";
import { AppDispatch, RootState } from "../../../store";
import { setUrlServer } from "../../../store/subscriptionSlice";
import coreApi from "../../../services/path/core_api";
import { useParams } from "react-router-dom";

interface ModalUrlConfFormProps {
  subscriptionType: 'glpi' | 'odoo';  // Type de souscription
}

const ModalUrlConfForm: React.FC<ModalUrlConfFormProps> = ({ subscriptionType }) => {
  const [showModal, setShowModal] = useState(false);
  const [isUrlValid, setIsUrlValid] = useState<boolean>(true); // URL valide par défaut
  const [isCheckingUrl, setIsCheckingUrl] = useState<boolean>(false);  // Pour désactiver le bouton "Enregistrer" pendant la vérification
  const [userInteracted, setUserInteracted] = useState<boolean>(false); // Nouveau state pour traquer l'interaction utilisateur
  const dispatch = useDispatch<AppDispatch>();

  const { codeItem } = useParams<{ codeItem?: string }>();

  // Sélecteur dynamique basé sur le type de souscription
  const { urlServer } = useSelector((state: RootState) =>
    state.subscription
  );

  const [url, setUrl] = useState<string>(urlServer ?? getUrlServer({ company: "", subscriptionType }));
  const [company, setCompany] = useState<string | null>(null);

  // Modifier dynamiquement l'URL dans le store correspondant (GLPI ou Odoo)
  const dispatchUrlServer = useCallback(
    (url: string) => {

      dispatch(setUrlServer(url));
    },
    [dispatch]
  );

  // Fonction pour vérifier si l'URL est déjà utilisée (API Call)
  const checkUrlExists = useCallback(async (url: string) => {
    setIsCheckingUrl(true);
    const coreUrl = codeItem
      ? `/check-url/?codeItem=${codeItem}&urlInstance=${url}`
      : `/check-url/?urlInstance=${url}`;

    try {
      const response = await coreApi.get(coreUrl);
      setIsUrlValid(!response.data.exists); // If the URL already exists, it's not valid
    } catch (error) {
      console.error("Error checking URL:", error);
      setIsUrlValid(false); // If an error occurs, consider the URL invalid
    } finally {
      setIsCheckingUrl(false); // Stop the checking indicator
    }
  }, [codeItem]);

  // Fonction avec debounce pour vérifier l'URL après un délai de 500 ms
  useEffect(() => {
    if (userInteracted && isValidUrl(url)) {
      const handler = setTimeout(() => {
        checkUrlExists(url);  // Appel API après 500 ms si l'URL est valide
      }, 500);
      return () => clearTimeout(handler);  // Effacer le timeout si l'utilisateur continue à taper
    }
  }, [url, userInteracted, checkUrlExists]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setUserInteracted(true); // Marquer que l'utilisateur a interagi
      let value = e.target.value;
      if (value !== '') {
        value = normaliseUrl(value); // Normalise l'URL saisie et ajoute https:// si nécessaire
      }
      setUrl(value); // Met à jour l'état avec la nouvelle URL
    },
    []
  );

  const handleSave = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (isUrlValid) {
      dispatchUrlServer(url);  // Appelle dynamique
      setShowModal(false);
    }
  };

  useEffect(() => {
    const companyValue = company || '';
    if (companyValue !== '') {
      setUrl(getUrlServer({ company: companyValue, subscriptionType }));
      setUserInteracted(true);
    }
  }, [company, subscriptionType]);

  // Initialisation de l'URL au chargement du modal si urlServer est défini
  useEffect(() => {
    if (urlServer) {
      setUrl(urlServer);
    }
  }, [urlServer]);

  return (
    <>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        className="text-white h-12 flex flex-row px-4  items-center justify-center bg-primary-500 rounded"
      >
        <FontAwesomeIcon icon={faCog} className="size-5 sm:mr-2" />
        <span className="hidden sm:block">Configurer</span>
      </button>
      {showModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-96 md:w-auto my-6 mx-auto md:max-w-2xl shadow">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-xl lg:text-3xl font-semibold">
                    URL de l'Instance
                  </h3>
                  <button
                    onClick={() => setShowModal(false)}
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                  >
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="space-y-4 text-base text-gray-800">
                    <span>
                      Si vous souhaitez modifier l'URL de votre instance <span className="capitalize">{subscriptionType}</span>, vous devez disposer au préalable d'un nom de domaine, et vous devez créer un enregistrement DNS lié à cette URL et qui pointe vers notre adresse (vip.ic-cloudapps.com).
                    </span>
                    <p className="text-sm">
                      Le préfixe sera utilisé pour générer le nom de l'instance sous la forme: <span className="font-semibold">{`https://{prefix}${getSuffixeServer({ subscriptionType })}`}</span>
                    </p>
                    <div className="w-full flex flex-col gap-4 ">
                      <div className="w-full lg:w-3/5 ">
                        <label
                          htmlFor="company"
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-3"
                        >
                          Préfixe de l'URL
                        </label>
                        <input
                          type="text"
                          id="company"
                          name="company"
                          // placeholder="ex: Nom de votre entreprise"
                          value={company ?? ''}
                          onChange={e => setCompany(e.target.value)}
                          className="appearance-none block w-full h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                          required
                        />
                      </div>

                      <div className="flex-grow ">
                        <label
                          htmlFor="url"
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-3"
                        >
                          URL
                        </label>

                        <input
                          type="text"
                          id="url"
                          value={url}
                          onChange={handleChange}
                          placeholder={`https://{prefix}${getSuffixeServer({ subscriptionType })}`}
                          className={clsx(
                            "appearance-none block w-full h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white",
                            { "border-red-500": userInteracted && (!isValidUrl(url) || !isUrlValid) }
                          )}
                          required
                        />
                        {isCheckingUrl && <p className="text-sm text-blue-500">Vérification de l'URL en cours...</p>}
                        {userInteracted && !isUrlValid && !isCheckingUrl && url && <p className="text-sm text-red-500">L'URL est déjà utilisée ou invalide.</p>}
                      </div>
                    </div>
                  </div>
                </div>

                {/*footer*/}
                <div className="flex items-center justify-between p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    onClick={() => setShowModal(false)}
                    type="button"
                    className=" py-3  text-red-400 text-base text-center font-semibold"
                  >
                    Fermer
                  </button>

                  <button
                    onClick={handleSave}
                    className={
                      clsx("px-8  py-3 text-white  rounded-lg text-base text-center font-semibold",
                        {
                          "bg-primary-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300": isUrlValid && !isCheckingUrl
                        },
                        {
                          "bg-primary-500 opacity-50": !isUrlValid || isCheckingUrl
                        },
                      )
                    }
                    disabled={!isUrlValid || isCheckingUrl}
                  >
                    Enregistrer
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
};

export default ModalUrlConfForm;
