import React, { ReactNode } from 'react';

interface ModalProps {
    isOpen: boolean;
    children: ReactNode
}

const ModalDialog: React.FC<ModalProps> = ({ isOpen, children }) => {
    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 overflow-y-auto overflow-x-hidden"
            aria-hidden="true"
        >
            <div className="relative p-4 w-full max-w-4xl max-h-full">
                {children}
            </div>
        </div>
    );
};

export default ModalDialog;