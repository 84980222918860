// src/repositories/routeRepository.ts

import { CategorizedProducts } from "../types/model/product";
import {  RouteLink, routes } from "../types/model/route";
import { createSlug } from "../utils/createSlug";
import productRepository from "./productRepository";

const routeRepository = {
    getRoute: async (): Promise<RouteLink[]> => {
        try {
            const categorizedProducts = await productRepository.fetchProductsGroupedByCategory();
            return generateRouteLinks(Object.values(categorizedProducts));
        } catch (error) {
            return routes;
        }
    },
};

export default routeRepository;


export function generateRouteLinks(categorizedProducts: CategorizedProducts[]): RouteLink[] {
    return [
      { name: "Accueil", path: "/" },
      {
        name: "Produits",
        path: "/products",
        subLink: categorizedProducts.map(category => ({
          title: `${category.categorie.nom.toLowerCase() !== "consulting"
          ? "Offres "
          : ""
      } ${category.categorie.nom}`,
          routes: category.products.map(product => ({
            name: product.nom,
            path: `/products/${createSlug(product.nom)}`
          }))
        }))
      },
      {
        name: "Tarifs",
        path: "/ ",
        isPricing: true,
        subLink: [
          {
            routes: [
              { name: "CloudApps Odoo", path: `/subscribe/${getProduct(categorizedProducts,"odoo")}` },
              { name: "CloudApps GLPI", path: `/subscribe/${getProduct(categorizedProducts,"glpi")}` },
            ],
          },
        ],
      },
      // { name: "Blog", path: "/blog" },
      { name: "À propos de nous", path: "/about" }
    ];
  }


  const getProduct =(categories:CategorizedProducts[], name:string)=>{
  const cate= categories.find(categorie=>categorie.categorie.slug==="saas")
   const product= cate?.products.find(e=>e.nom.toLowerCase().includes(name))

  return product?.codeProduitService.toLowerCase()??"";
}