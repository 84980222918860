import { configureStore } from '@reduxjs/toolkit';
import subscriptionReducer from './subscriptionSlice';
import authReducer from './authSlice';
import cartSlice from './cartSlice'
import orderSlice from './orderSlice';

const store = configureStore({
  reducer: {
    subscription: subscriptionReducer,
    auth: authReducer,
    cart: cartSlice,
    order: orderSlice
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
