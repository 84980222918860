import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  initialLocation,
  maxAssets,
} from "../../../constant/constant";
import { convertToValidUrl, getIdsByNames, getPlanIdByName, handleGetImplementation, handleGetMaintenance, handleGetMaintenances } from "../../../constant/functions_subscription";
import { useFormContext } from "../../../context/formContext";
import { AppDispatch, RootState } from "../../../store";
import { addCartItem } from "../../../store/cartSlice";
import {
  cleanMaintenanceType,
  cleanProduct,
  resetState,
  setHostingType,
  setIsAnnual,
  setMaintenance,
  setMaintenanceType,
  setNumberOfEmployees,
  setNumUsers,
  setSelectedProducts,
  setSupportImplementation,
  setTypePlan,
  setWebsite,
} from "../../../store/subscriptionSlice";
import { styles } from "../../../styles";
import { CartItemParams } from "../../../types/model/cart";
import { SubscriptionFormProps } from "../../../types/model/subscription_props";
import { H2 } from "../../commons/h_component";
import InputNumber from "../../input_number_component";
import CompanyName from "../../name_company_component";
import InputUrlServer from "../../input_url_component.";
import LoadingSpinner3 from "../../loading/loading_spinner3";
import ModalSubscription from "../../modal/modalSubscriptionOdoo";
import Modal from "../../modal/modal_subscribe";
import ModalDialog from "../../modal/modal_t";
import ToggleButton from "../../toggle_button";
import ModalFormGlpiLocal from "../glpi/modal_glpi_conf_local_form";
import { PricingGLPITable } from "../glpi/price_card_glpi";
import ModalFormOdooLocal from "./modal_odoo_conf_local_form";
import { PricingTable } from "./price_card_odoo";
import ServiceQuestions from "./service_component";




const SubscriptionForm: React.FC<SubscriptionFormProps> = ({ onNext, dataSubscription, subscriptionType }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { loadingAddItem, errorAddItem, } = useSelector(
    (state: RootState) => state.cart
  );

  const { user, } = useSelector(
    (state: RootState) => state.auth
  );
  const [modalConfigLocal, setModalConfigLocal] = useState<boolean>(false);
  const [errorUrl, setErrorUrl] = useState<boolean>(false);

  const [modal, setModal] = useState<boolean>(false);
  const { setFormData } = useFormContext();
  const {
    numUsers,
    hostingType,
    typePlan,
    isAnnual,
    website,
    numberOfItem,
    selectedProducts,
    maintenance,
    location,
    typeMaintenance,
    supportImplementation, totalPrice
  } = useSelector((state: RootState) => state.subscription);
  const [disableCheck, setDisableCheck] = useState<boolean>(
    numberOfItem > 20
  );

  useEffect(() => {
    const getPlanType = () => {
      if (subscriptionType === 'odoo') {
        if (numUsers > 19) return "Premium";
        if ((numUsers > 5 || website) && numUsers < 20) return "standard";
      } else {
        if (numUsers > 19 || numberOfItem > 99) return "Premium";
        if ((numUsers > 5 && numUsers < 20) || (numberOfItem > 49 && numberOfItem < 100)) return "standard";
      }
      return "starter";
    };

    const typePlan = getPlanType();
    dispatch(setTypePlan(typePlan));
  }, [numUsers, subscriptionType, numberOfItem, website, dispatch]);
  const handleNumUsersChange = (value: number) => {
    dispatch(setNumUsers(value));
  };

  const handleIsAnnualChange = (newChecked: boolean) => {
    dispatch(setIsAnnual(newChecked));
  };

  const handleWebsiteChange = (website: boolean) => {
    dispatch(setWebsite(website));
  };

  const handleMaintenanceTypeChange = (newChecked: string) => {
    dispatch(setMaintenanceType(newChecked));
  };

  const handleProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    dispatch(setSelectedProducts({ value, checked }));
  };

  const handleMaintenanceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    dispatch(setMaintenance(value === "yes"));
    if (value !== "yes") {
      dispatch(cleanMaintenanceType());
    }
  };

  const handleSupportChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    dispatch(setSupportImplementation(value === "yes"));
    if (value !== "yes") {
      dispatch(cleanProduct());
    }
  };

  const toggleModalLocal = useCallback(() => {
    setModalConfigLocal((prev) => !prev);
  }, []);

  const toggleModal2 = useCallback(() => {
    setModal((prev) => !prev);
  }, []);
  const setSupportImplementationCallback = useCallback(
    (newChecked: boolean) => {
      dispatch(setSupportImplementation(newChecked));
    },
    [dispatch]
  );

  useEffect(() => {
    const threshold = subscriptionType === 'glpi' ? 100 : 20;

    if (numberOfItem > threshold) {
      setSupportImplementationCallback(true);
      setDisableCheck(true);
    } else {
      setDisableCheck(false);
    }
  }, [numberOfItem, subscriptionType, setSupportImplementationCallback]);

  const handleNextClick = async () => {
    if (numberOfItem === 0 || numUsers === 0 || totalPrice === 0.00 || urlServer == null || urlServer === '') {
      if (urlServer == null || urlServer === '') {
        setErrorUrl(true)
      }
      alert("Veuillez remplir tous les champs svp !!");
    } else {
      setErrorUrl(false)
      await handleAddItem();
    }
  };
  const [hasAttemptedToAddItem, setHasAttemptedToAddItem] = useState(false);
  const miseOeuvreService = useMemo(() => handleGetImplementation({ type: subscriptionType, implementationServices: dataSubscription.supportImplementations }), [dataSubscription.supportImplementations, subscriptionType])
  const maintenanceServices = useMemo(() => handleGetMaintenances({ maintenances: dataSubscription.maintenances }), [dataSubscription.maintenances])

  useEffect(() => {
    // On vérifie si l'ajout a été tenté, que l'ajout est terminé, et qu'il n'y a pas d'erreur
    if (hasAttemptedToAddItem && !loadingAddItem && !errorAddItem) {
      onNext({ city: location ?? initialLocation });

      setFormData((prevData) => ({
        ...prevData,
        city: location ?? initialLocation,  // Set city or fallback to initial
      }));

    }
    // Ajout de `hasAttemptedToAddItem` aux dépendances pour suivre son état
  }, [loadingAddItem, errorAddItem, hasAttemptedToAddItem, onNext, location, setFormData]);


  const handleEmployeesChange = useCallback(
    (value: number) => {
      dispatch(setNumberOfEmployees(value < maxAssets ? value : maxAssets));
    },
    [dispatch]
  );


  const { urlServer } = useSelector(
    (state: RootState) => state.subscription
  );

  const url = useMemo(
    () =>
      urlServer ?? ""
    ,
    [urlServer]
  );

  const [inputUrl, setInputUrl] = useState<string>(url);

  useEffect(() => {
    if (!urlServer) {
      setInputUrl(url);
    } else {
      setInputUrl(urlServer);
    }
  }, [url, urlServer]);



  const handleAddItem = async () => {
    try {

      const item: CartItemParams = {
        idProduitService: dataSubscription.product.idProduitService,
        idSupportMaintenance: typeMaintenance
          ? handleGetMaintenance({ maintenances: dataSubscription.maintenances, code: typeMaintenance })
          : null,
        idImplementationService: getIdsByNames(dataSubscription.supportImplementations, selectedProducts),  // Example list of IDs
        idPeriodeSouscription: isAnnual ? 2 : 1,
        idPlanSouscription: getPlanIdByName(dataSubscription.plans, `${typePlan.toLowerCase()}`) ?? 1,
        siteWeb: website,
        nombreUtilisateurs: numUsers,
        nombreItems: numberOfItem,
        typeHebergement: hostingType,
        quantite: 1,
        urlInstance: convertToValidUrl(url),
        prixTotal: totalPrice,
      }

      // Indique que l'ajout a été lancé
      setHasAttemptedToAddItem(true);

      // Dispatch l'action pour ajouter l'item au panier
      await dispatch(addCartItem(item, dataSubscription.product, user?.codeUtilisateur ?? null));
      dispatch(resetState())
    } catch (error) {
      console.error("Failed to add item:", error);
    }
  };

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="w-full flex flex-col gap-24 items-center justify-center">
        <H2
          classNames={clsx(
            styles.serviceStyle["title"],
            "text-center md:text-start"
          )}
          Component={
            <p className="capitalize">
              {subscriptionType}{" "}
              <span
                className={clsx(styles.serviceStyle["title-pro"], "capitalize")}
              >
                {hostingType === "cloud" ? typePlan : hostingType}
              </span>
            </p>
          }
        />

        <div className="w-full h-full flex flex-col lg:flex-row  xl:gap-16">
          <div className="w-full lg:w-7/12 h-full flex flex-col gap-8 text-md xl:text-lg leading-8">
            <div className="w-full flex flex-col">

              <CompanyName
                subscriptionType={subscriptionType}
              />
              <InputNumber
                name="userNumber"
                title=" Nombre d'utilisateurs"
                setNumber={handleNumUsersChange}
                error={numUsers === 0}
                numberOfItems={numUsers}
              />

              <InputNumber
                name="numberOfEmployees"
                title={subscriptionType === 'odoo' ? "Nombre d'employes" : " Nombre de materiels"}
                setNumber={handleEmployeesChange}
                error={numberOfItem === 0}
                numberOfItems={numberOfItem}
              />
              <InputUrlServer
                value={inputUrl}
                error={errorUrl}
                subscriptionType={subscriptionType}
              />
            </div>
            {subscriptionType === 'odoo' && <ServiceQuestions onServiceChange={handleWebsiteChange} />}

            <div className="mb-4  flex flex-col ">
              <h3 className="text-xl font-semibold mb-4 xl:mb-8">
                Type d'hébergement:
              </h3>
              <div>
                <label className="block mb-2 ">
                  <input
                    type="radio"
                    name="hostingType"
                    value="on-premises"
                    checked={hostingType === "on-premises"}
                    onChange={(e) => dispatch(setHostingType(e.target.value))}
                    className="mr-2"
                  />{/* */}
                  On-premises
                </label>
                <div
                  className={clsx(
                    "bg-blue-100 mb-2 ml-8 md:w-4/5 rounded-lg flex flex-col gap-4 p-4 text-md leading-6 text-justify",
                    "transition transition-all duration-300 ease-in-out",
                    {
                      hidden: hostingType !== "on-premises",
                    }
                  )}
                >
                  <p>Optez pour un hébergement local de votre solution {subscriptionType}.</p>

                  <div className="w-full flex justify-end text-white">
                    <button
                      onClick={toggleModalLocal}
                      className="px-8 py-4 bg-primary-500 rounded"
                    >
                      <FontAwesomeIcon icon={faCog} className="size-5 mr-2" />
                      <span>Configurer</span>
                    </button>
                    <Modal
                      isOpen={modalConfigLocal}
                      toggleModal={toggleModalLocal}
                      title="Estimateur de projet"
                    >
                      {" "}
                      {subscriptionType === 'odoo' ? <ModalFormOdooLocal onClose={toggleModalLocal} /> : <ModalFormGlpiLocal onClose={toggleModalLocal} />}
                    </Modal>
                  </div>
                </div>
              </div>
              <div className="w-full relative">
                <label className="block mb-2">
                  <input
                    type="radio"
                    name="hostingType"
                    value="cloud"
                    checked={hostingType === "cloud"}
                    onChange={(e) => dispatch(setHostingType(e.target.value))}
                    className="mr-2"
                  />{/* */}
                  Cloud optimisé et securisé de CloudApps
                </label>
                <div
                  className={clsx(
                    "bg-blue-100 ml-8  md:w-4/5 rounded-lg flex flex-col gap-4 p-4 text-md leading-6 text-justify",
                    "transition transition-all duration-300 ease-in-out",
                    {
                      hidden: hostingType !== "cloud",
                    }
                  )}
                >
                  <p>
                    Profitez des atouts du cloud optimiser et sécuriser de
                    CloudApps. Grâce à l'hébergement cloud, bénéficiez d'une
                    plateforme ultra-performante, hautement disponible et dotée
                    de fonctions de sécurité de pointe. Concentrez-vous sur
                    votre cœur de métier.
                  </p>
                </div>
              </div>
            </div>

            {/* Service de mise en œuvre */}
            {dataSubscription.supportImplementations && <div className="mb-4">
              <h3 className="text-xl font-semibold mb-4">
                Support d'accompagnement à la mise en œuvre:
              </h3>
              <p className="text-gray-800 mb-4">
                Veuillez sélectionner les applications {subscriptionType} pour lesquelles vous
                souhaitez bénéficier d'un accompagnement.
              </p>
              <div>
                {" "}
                <label className="block mb-2">
                  <input
                    type="radio"
                    name="supportImplementation"
                    value="yes"
                    checked={supportImplementation}
                    onChange={handleSupportChange}
                    className="mr-2"
                  />{/* */}
                  Oui
                </label>
                <div
                  className={clsx(
                    "bg-blue-100 pt-4 mb-2 ml-4  rounded-lg flex flex-col gap-4 p-4 text-md leading-6 text-justify",
                    "transition transition-all duration-300 ease-in-out",
                    {
                      hidden: !supportImplementation,
                    }
                  )}
                >
                  <div className="w-full grid md:grid-cols-2  gap-x-8">
                    {miseOeuvreService.map((product) => (
                      <div
                        key={product.name}
                        className="block my-2 flex items-start"
                      >
                        <input
                          type="checkbox"
                          id={product.name}
                          value={product.name}
                          checked={selectedProducts.includes(product.name)}
                          onChange={handleProductChange}
                          className="h-5 w-5 min-w-5 min-h-5 mt-1 text-indigo-600 border-gray-300 rounded"
                        />
                        <label
                          htmlFor={product.name}
                          className="ml-4 block text-gray-800"
                        >
                          <b className="font-bold">{product.name}</b>
                          <div className="flex text-sm text-gray-700 w-full text-start break-words ">
                            {product.products.join(", ")}
                          </div>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <label className="block">
                <input
                  type="radio"
                  name="supportImplementation"
                  value="no"
                  disabled={disableCheck}
                  checked={!supportImplementation}
                  onChange={handleSupportChange}
                  className="mr-2"
                />{/* */}
                Non
              </label>
            </div>}
            {dataSubscription.maintenances && <div className="py-4">
              <h3 className="font-semibold text-xl mb-4">
                Service de maintenance
              </h3>

              <div>
                <div className="flex items-center mt-4">
                  <input
                    type="radio"
                    id="maintenanceYes"
                    name="maintenance"
                    value="yes"
                    checked={maintenance}
                    onChange={handleMaintenanceChange}
                    className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="maintenanceYes"
                    className="ml-3 block text-gray-700"
                  >
                    Oui
                  </label>
                </div>
                <div
                  className={clsx(
                    " ",
                    "pt-4  mb-2 ml-4  rounded-lg flex flex-col gap-4 px-4 text-md leading-6 text-justify",
                    "transition transition-all duration-300 ease-in-out",
                    {
                      hidden: !maintenance,
                    }
                  )}
                >
                  <span>
                    (*) Un ticket est un problème ou une demande formulée par le
                    client, qui devra être traité par cloudApps et par la suite
                    validé par le client avant d'être considéré comme résolu.
                  </span>
                  <div className="w-full grid grid-cols-1 gap-x-8">
                    {maintenanceServices.map((product) => (
                      <div
                        key={product.type}
                        className="block my-2 flex items-start"
                      >
                        <input
                          type="checkbox"
                          id={product.type}
                          // value={product.value}
                          checked={typeMaintenance === product.type}
                          onChange={(e) =>
                            handleMaintenanceTypeChange(product.type)
                          }
                          className="h-5 w-5 min-w-5 min-h-5 mt-1 text-indigo-600 border-gray-300 rounded"
                        />
                        <label
                          htmlFor={product.type}
                          className="ml-4 block text-gray-800"
                        >
                          <span className="">
                            {product.value}{" "}
                            <span className="text-base font-medium capitalise">
                              ({product.price} FcFa)
                            </span>
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex items-center mt-2">
                <input
                  type="radio"
                  id="maintenanceNo"
                  name="maintenance"
                  value="no"
                  defaultChecked
                  onChange={handleMaintenanceChange}
                  className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                />
                <label
                  htmlFor="maintenanceNo"
                  className="ml-3 block text-gray-700"
                >
                  Pas Interesser pour le moment
                </label>
              </div>
            </div>}
          </div>
          <div className="w-full lg:w-5/12 h-full flex flex-col pt-16 md:pt-0 items-center gap-4 ">
            <ToggleButton
              initialChecked={isAnnual}
              onCheckedChange={handleIsAnnualChange}
            />
            <div className="w-full">
              {subscriptionType === 'odoo' ? <PricingTable maintenances={maintenanceServices} /> : <PricingGLPITable maintenances={maintenanceServices} />}
              <div className="w-full gap-8 flex xl:pt-8 flex-col xl:flex-row">
                <div className="w-full mx-auto flex items-center justify-center">
                  <button
                    onClick={(e) => setModal(true)}
                    className="h-14 w-full shadow bg-blue-500 py-2 text-lg transition ease-in duration-300 hover:ease-out hover:bg-blue-800  focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded-lg"
                  >
                    Voir detail
                  </button>

                  <ModalSubscription
                    isOpen={modal}
                    toggleModal={toggleModal2}
                    maintenances={maintenanceServices}
                  />
                </div>
                <div className="w-full xl:flex-grow mx-auto  flex items-center justify-center">
                  <button
                    onClick={handleNextClick}
                    className="h-14 w-full shadow bg-primary-500 py-2 text-lg transition ease-in duration-300 hover:ease-out hover:bg-blue-500 hover:md:scale-y-110 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded-lg"
                  >
                    Ajouter au Panier
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalDialog isOpen={loadingAddItem} >
        <div className='mx-auto container px-4 xl:px-16 2xl:px-0'>
          <LoadingSpinner3 />
        </div>
      </ModalDialog>
    </div>
  );
};

export default SubscriptionForm;
