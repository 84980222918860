import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Footer from './components/footer';
import Navbar from './components/navbar';
import { AdresseProvider } from './context/AdresseContext';
import { FormProvider } from './context/formContext';
import AppRoutes from './routes';
import { AppDispatch, RootState } from './store';
import { fetchCart } from './store/cartSlice';
import { fetchCurrentOrder } from './store/orderSlice';

const App: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  // Accéder aux données du store
  const { cart } = useSelector((state: RootState) => state.cart);
  const { user } = useSelector((state: RootState) => state.auth);


  // Utilisation de useMemo pour éviter de recalculer la condition
  const shouldFetchCart = useMemo(() => user && cart == null, [user, cart]);

  // Utilisation de useCallback pour mémoriser la fonction
  const fetchCartIfNecessary = useCallback(() => {
    dispatch(fetchCart(user?.codeUtilisateur ?? null));
    if (shouldFetchCart) {

      dispatch(fetchCurrentOrder(user!.codeUtilisateur));
    }
  }, [dispatch, shouldFetchCart, user]);

  useEffect(() => {
    fetchCartIfNecessary();
  }, [fetchCartIfNecessary]);

  return (
    <Router>
      <AdresseProvider> {/* AdresseProvider must wrap components needing the context */}
        <div className="pt-20 overflow-x-hidden"> {/* To prevent content from being hidden behind the fixed navbar */}
          <Navbar />
          <div className='min-h-screen bg-gray-50'>
            <FormProvider>
              <AppRoutes />
            </FormProvider>
          </div>
          <Footer />
        </div>
      </AdresseProvider>
    </Router>
  );
}

export default App;
