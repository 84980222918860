import { User } from "./../types/model/user_model";
// repositories/AuthRepository.ts
import authApi from "../services/path/auth_api";

const authService = {
    login: async (email: string, password: string): Promise<string> => {
        const response = await authApi.post(`/auth/login`, {
            email: email,
            password: password,
        });
        return response.data["access_token"];
    },
    register: async (
        nom: string,
        prenom: string,
        email: string,
        password: string,
        telephone: string
    ): Promise<void> => {
        await authApi.post(`/auth/register`, {
            email,
            password,
            prenom,
            telephone,
            nom,
        });
    },

    updateUser: async (
        nom: string,
        prenom: string,
        telephone: string,
        codeUtilisateur: string,
    ): Promise<User> => {
        const response = await authApi.put<User>(`/users/${codeUtilisateur}`, {
            prenom,
            telephone,
            nom,
        });

        return response.data;
    },

    updatePass: async (
        new_password: string,
        codeUtilisateur: string,
    ): Promise<void> => {
        try {
            await authApi.put(`/auth/update-pass/${codeUtilisateur}`, {
                new_password,
            });


        } catch (error) {
            console.log(error)
        }
    },

    getMe: async (token: string): Promise<User> => {
        const response = await authApi.get<User>(`/users/get-me`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },
    logout: async (token: string): Promise<void> => {
        try {
            await authApi.post(`/auth/logout`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } catch (error) {
        }
    }
};
export default authService;
