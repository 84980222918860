// src/hooks/useRoutes.ts
import { useState, useEffect } from 'react';
import routeRepository from '../repositories/routeRepository';
import { RouteLink } from '../types/model/route';

const useRoutes = () => {
  const [navRoutes, setNavRoutes] = useState<RouteLink[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await routeRepository.getRoute();
        setNavRoutes(result);
      } catch (error) {
        setError("Failed to fetch routes");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { navRoutes, loading, error };
};

export default useRoutes;
