import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUrlServer, isValidUrl } from "../constant/functions";
import coreApi from "../services/path/core_api";
import { AppDispatch, RootState } from "../store";
import { setErrorUrl, setUrlServer } from "../store/subscriptionSlice";

// Custom hook for debouncing
const useDebounce = (value: string, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};

interface Params {
    subscriptionType: "glpi" | "odoo"; // Type de souscription
}

const CompanyName = ({ subscriptionType }: Params) => {
    const dispatch = useDispatch<AppDispatch>();
    const { codeItem } = useParams<{ codeItem?: string }>();

    const { urlServer } = useSelector((state: RootState) => state.subscription);
    const [url, setUrl] = useState<string>(urlServer ?? getUrlServer({ company: "", subscriptionType }));
    const [company, setCompany] = useState<string>("");
    const [isUrlValid, setIsUrlValid] = useState<boolean>(true);

    const debouncedUrl = useDebounce(url, 500);

    // Check if the URL is valid and exists
    const checkUrlExists = useCallback(async (url: string) => {
        const coreUrl = codeItem
            ? `/check-url/?codeItem=${codeItem}&urlInstance=${url}`
            : `/check-url/?urlInstance=${url}`;

        try {
            const response = await coreApi.get(coreUrl);
            setIsUrlValid(!response.data.exists);
        } catch (error) {
            console.error("Error checking URL:", error);
            setIsUrlValid(false);
        } finally {
        }
    }, [codeItem]);

    // Update the store with the URL
    const updateUrlInStore = useCallback((url: string) => {
        dispatch(setUrlServer(url));
        dispatch(setErrorUrl(!isUrlValid));
    }, [dispatch, isUrlValid]);

    // Check URL validity when the debounced URL changes
    useEffect(() => {
        if (isValidUrl(debouncedUrl)) {
            checkUrlExists(debouncedUrl);
        }
    }, [debouncedUrl, checkUrlExists]);

    // Update URL in store whenever it changes
    useEffect(() => {
        if (debouncedUrl) {
            updateUrlInStore(debouncedUrl);
        }
    }, [debouncedUrl, updateUrlInStore]);

    // Update URL when the company name changes
    useEffect(() => {
        if (company) {
            const newUrl = getUrlServer({ company, subscriptionType });
            setUrl(newUrl);
        }
    }, [company, subscriptionType]);

    // Initialize URL on component mount if `urlServer` is available
    useEffect(() => {
        if (urlServer) {
            setUrl(urlServer);
        }
    }, [urlServer]);

    // Input classes (static, no need for useMemo)
    const inputClasses = clsx(
        "appearance-none flex-grow h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
    );

    return (
        <div className="flex w-full flex-wrap gap-4 xl:gap-8 md:w-3/4 xl:w-11/12 2xl:w-3/4 items-center mb-4">
            <label
                htmlFor="company"
                className="w-64 block tracking-wide text-xl font-semibold"
            >
                Nom de l'entreprise
            </label>
            <input
                type="text"
                name="company"
                id="company"
                className={inputClasses}
                value={company}
                onChange={(e) => setCompany(e.target.value)}
            />
        </div>
    );
};

export default CompanyName;
