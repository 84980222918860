import { PanierItem } from "./cart";

export enum StatutAbonnement {
    PENDING = 'PEN', // En attente
    ACTIVATED = 'ACT', // Succès
    CANCEL = 'CAN',   // Annulé
    ERROR = 'ERR',   // Annulé
    EXPIRED = 'EPX'
}

export interface Abonnement {
    idAbonnement: number;
    item: PanierItem;
    codeAbonnement: string;
    dateDebut: string;
    dateExpiration: string | null,
    statut: string;
}
