import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { OrdersTableSkeleton as TableLoadingSkeleton } from '../../components/loading/skeleton';
import EmptyTableComponent from '../../components/me/empty_table_component';
import Pagination from '../../components/orders/pagination';
import { TransactionsTable } from '../../components/transactions/transactionTables';
import useTransaction from '../../hooks/useTransaction';
import { RootState } from '../../store';
import { StatutPaiement } from '../../types/model/transaction';
import { ErrorPage } from '../error';

export const Transactions = () => {
    const { user } = useSelector((state: RootState) => state.auth);

    const { transactions, loading, error, cancelTransaction, loadingCancel, errorCancel } = useTransaction()


    // État pour le tri
    const [selectedTransactionType, setSelectedTransactionType] = useState<string | null>(
        null
    );
    const [selectedDuration, setSelectedDuration] = useState<string | null>(null);

    // Fonction pour gérer le changement de type de commande
    const handleTransactionTypeChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setSelectedTransactionType(event.target.value);
    };

    // Fonction pour gérer le changement de durée
    const handleDurationChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setSelectedDuration(event.target.value);
    };

    // Utilisation de useMemo pour optimiser le filtrage et tri des commandes
    const filteredTransactions = useMemo(() => {
        let filtered = transactions;

        // Filtrer par type de commande (statut)
        if (selectedTransactionType && selectedTransactionType !== "All orders") {
            filtered = filtered.filter(
                (order) => order.statut === selectedTransactionType
            );
        }

        // Filtrer par durée
        if (selectedDuration) {
            const now = new Date();
            filtered = filtered.filter((order) => {
                const orderDate = new Date(order.dateCreation).getTime(); // Utilisation de getTime pour éviter les problèmes de comparaison
                switch (selectedDuration) {
                    case "this week":
                        return orderDate >= now.getTime() - 7 * 24 * 60 * 60 * 1000; // Semaine dernière
                    case "this month":
                        return orderDate >= now.getTime() - 30 * 24 * 60 * 60 * 1000; // Mois dernier
                    case "last 3 months":
                        return orderDate >= now.getTime() - 3 * 30 * 24 * 60 * 60 * 1000; // 3 derniers mois
                    case "last 6 months":
                        return orderDate >= now.getTime() - 6 * 30 * 24 * 60 * 60 * 1000; // 6 derniers mois
                    case "this year":
                        return orderDate >= now.getTime() - 365 * 24 * 60 * 60 * 1000; // Année dernière
                    default:
                        return true;
                }
            });
        }

        return filtered;
    }, [transactions, selectedTransactionType, selectedDuration]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;

    // Calcul du nombre total de pages
    const totalPages = useMemo(
        () => Math.ceil(filteredTransactions.length / itemsPerPage),
        [filteredTransactions]
    );

    const paginatedTransactions = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return filteredTransactions.slice(startIndex, endIndex);
    }, [filteredTransactions, currentPage]);

    const handlePageChange = (newPage: number) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleCancel = useCallback(
        (itemId: string) => {
            if (user) {
                cancelTransaction(itemId);
            }
        },
        [user, cancelTransaction]
    );

    const showToast = useCallback(
        () => {
            if (!loadingCancel) {
                if (errorCancel) {
                    toast.error(errorCancel || 'Erreur inattendue');
                }

            }
        },
        [loadingCancel, errorCancel],
    )

    useEffect(() => {
        showToast()
    }, [errorCancel, loadingCancel, showToast])




    if (loading) return <section className="bg-white py-8 antialiased md:py-16 h-full">
        <div className="mx-auto max-w-6xl flex items-center  h-full w-full"><TableLoadingSkeleton /> </div>
    </section>;
    if (error) return <section className="bg-white py-8 antialiased md:py-16 h-full">
        <div className="mx-auto max-w-6xl flex items-center  h-full"><ErrorPage /></div>
    </section>;

    if (transactions && transactions.length === 0) {
        return <section className=" px-2 py-8 antialiased md:py-16">
            < EmptyTableComponent message="Aucune transaction pour le moment!" />
        </section>
    }
    return (
        <section className=" px-2 py-8 antialiased md:py-16">
            <div className="mx-auto max-w-6xl">
                <div className="gap-4 sm:flex sm:items-center sm:justify-between">
                    <h2 className="text-xl font-semibold text-gray-900  sm:text-2xl">
                        Mes Transactions
                    </h2>
                    <div className="mt-6 gap-4 space-y-4 sm:mt-0 sm:flex sm:items-center sm:justify-end sm:space-y-0">
                        <div>
                            <label
                                htmlFor="order-type"
                                className="sr-only mb-2 block text-sm font-medium text-gray-900"
                            >
                                Sélectionnez le statut
                            </label>
                            <select
                                id="order-type"
                                className="block w-full min-w-[8rem] rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
                                onChange={handleTransactionTypeChange}
                            >
                                <option value="All orders">Tous</option>
                                <option value={StatutPaiement.PENDING}>En cours</option>
                                <option value={StatutPaiement.SUCCESS}>Valider</option>
                                <option value={StatutPaiement.CANCEL}>Annuler</option>

                                <option value={StatutPaiement.FAILURE}>Echouer</option>
                            </select>
                        </div>
                        <span className="inline-block text-gray-500 dark:text-gray-400">
                            {" "}
                            Depuis{" "}
                        </span>
                        <div>
                            <label
                                htmlFor="duration"
                                className="sr-only mb-2 block text-sm font-medium text-gray-900"
                            >
                                Sélectionnez la durée
                            </label>
                            <select
                                id="duration"
                                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
                                onChange={handleDurationChange}
                            >
                                <option value="this week">Cette semaine</option>
                                <option value="this month">Ce Mois</option>
                                <option value="last 3 months">Les 3 derniers mois</option>
                                <option value="last 6 months">Les 3 derniers mois</option>
                                <option value="this year">Cette année</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="min-h-96 h-full">
                    <TransactionsTable transactions={paginatedTransactions} cancelTransaction={handleCancel} />
                </div>
                <div className="mt-8 flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                    <div className=" hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                        <div>
                            <p className="text-sm text-gray-700">
                                Affichage de <span className="font-medium">1</span> à{" "}
                                <span className="font-medium">{paginatedTransactions.length}</span> sur{" "}
                                <span className="font-medium">{filteredTransactions.length}</span> résultats
                            </p>
                        </div>
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            handlePageChange={handlePageChange} />
                    </div>
                </div>
            </div></section>
    )
}
