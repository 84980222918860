import coreApi from './path/core_api';
import { Categorie } from '../types/model/product';

const productCategorie = {
  getCategories: async (): Promise<Categorie[]> => {
    const response = await coreApi.get<Categorie[]>('/categories');
    return response.data;
  },
};

export default productCategorie;
