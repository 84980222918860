import clsx from 'clsx'
import React from 'react'
import { H1, H2 } from '../../components/commons/h_component'
import MotionWrap from '../../components/commons/motionWrapper'
import ImageMotion from '../../components/commons/wrappers/imageMotion'
import OdooService from '../../components/odoo'
import { ProductPaths } from '../../constant'
import { whileInViewImage } from '../../constant/constant'
import imagesApp from '../../constant/images'
import { styles } from '../../styles'
import { AppConstant } from '../../types/constants/app_constant'



export default function OdooPage({ codeItem }: { codeItem: string }) {

    return (
        <div className="flex flex-col items-center bg-white relative items-center lg:h-full">
            <section id="text" className='bg-gray-50 w-full flex justify-center pt-20 lg:pt-40 pb-16 '>
                <div className='container w-full h-full flex flex-col items-center  px-4 xl:px-16 2xl:px-0 gap-16 lg:gap-24 '>
                    <ImageMotion
                        classNames=""
                        Component={
                            <H1 classNames={clsx(styles.serviceStyle['title'])} Component={
                                <span >CloudApps Odoo</span>
                            } />

                        }
                    />


                    <div className='flex flex-col  gap-8 xl:gap-24 items-center justify-center '>
                        <MotionWrap classNames='w-full  lg:w-4/5 h-full' Component={
                            <div className='w-full h-full text-base text-lg leading-8 flex flex-col gap-8 text-justify'>
                                <p className=' '><span className='font-semibold'>Odoo</span>{AppConstant.odooDecription1}</p>
                                <p>{AppConstant.odooDescription2}&nbsp;</p>
                            </div>} />

                        <div className='w-full lg:w-3/5 h-full '>

                            <ImageMotion
                                classNames="w-full h-full"
                                whileInView={whileInViewImage}
                                Component={
                                    <img src={ProductPaths.odoo} alt="products" className='object-fit w-full' />

                                }
                            />  </div>

                    </div>



                </div>
            </section>

            <section className='min-h-screen w-full flex justify-center py-24 '>
                <div className=' container px-4 xl:px-16 2xl:px-0 gap-16 w-full h-full flex flex-col   items-center gap-16 lg:gap-24'>
                    <ImageMotion
                        classNames=""
                        Component={
                            <H2 classNames={clsx(styles.serviceStyle['title-prod'], "mb-8 text-center md:text-start font-semibold")} Component={"Solutions de Gestion Intégrée d'Entreprise"} />

                        }
                    />
                    <div className='flex flex-col md:flex-row gap-16 lg:gap-24 items-center w-full' >

                        <div className='w-full  h-full'>
                            <MotionWrap classNames='w-full  h-full' Component={<OdooService />} />
                        </div>
                    </div>

                </div>
            </section>

            <section className="text-center pt-24 pb-24 h-full bg-gray-50 w-full">

                <MotionWrap classNames='w-full  h-full' Component={
                    <div className="container mx-auto px-4 h-full flex flex-col gap-8 ">
                        <H2 classNames={clsx(styles.serviceStyle["title-prod"], " flex flex-col gap-6 ")} Component={
                            <p>
                                <p><span className="font-semibold">Décuplez</span><br /> </p>
                                <p> vos<span className="text-gray-800 font-medium leading-8 text-primary-300">  perspectives de croissance  <span className='font-semibold text-primary-500'>avec CloudApps Odoo</span></span></p>
                            </p>
                        } />
                        <div className='rotate-180'>
                            <img src={imagesApp.arrow} className=" mx-auto mb-2 animate-bounce" alt="Arrow" loading="lazy" />

                        </div>
                        <a href={`/subscribe/${codeItem}`} className="bg-primary-500 text-white py-4 px-8 rounded-lg hover:shadow-xl  mb-3 hover:bg-primary-700 text-lg font-bold mx-auto transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 ...">
                            Souscrivez dès maintenant  !
                        </a>
                        <img src={imagesApp.arrow} className="mx-auto mb-2 animate-bounce " alt="Arrow" loading="lazy" />
                    </div>
                }
                />



            </section >


        </div >
    )
}
