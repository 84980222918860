// src/components/ProductList.tsx
import {
    faCloud,
    faNetworkWired,
    faServer,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import productRepository from "../repositories/productRepository";
import { styles } from "../styles";
import { ProductService } from "../types/constants/products/service";
import { CategorizedProducts } from "../types/model/product";
import { createSlug } from "../utils/createSlug";
import 'react-loading-skeleton/dist/skeleton.css'
import { ErrorPage } from "../pages/error";
import { H2 } from "./commons/h_component";
const icons = [faCloud, faServer, faNetworkWired];

const colors = [
    "#06768d",
    "#ffc107",
    "#6f42c1",
    "#007bff",
    "#6610f2",
    "#17a2b8",
    "#b5a2f8",
    "#007bff",
];
const ProductList: React.FC = () => {
    const [categorizedProducts, setCategorizedProducts] = useState<
        CategorizedProducts[]
    >([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await productRepository.fetchProductsGroupedByCategory();
                setCategorizedProducts(result);
            } catch (error) {
                setError("Failed to fetch products");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    if (loading) return (
        <div className="mb-24  container w-full h-full px-4 xl:px-16 2xl:px-0 ">
            <div className="select-none mt-24 w-full h-full grid grid-cols-1   gap-y-24">

                {icons.map(e => (<div key={`skeleton_${e.iconName}`} className="w-full px-4 h-full flex flex-col gap-14">
                    <Skeleton borderRadius={0} containerClassName={"w-full max-w-[380px] h-[35px]"} />
                    <div className="w-full h-full  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4  gap-x-8 gap-y-8">
                        {icons.map(e => (<ProductSkeleton key={e.iconName} />))}
                    </div>
                </div>))}

            </div>
        </div>
    )
    if (error) return <section className='w-full py-24 pt-8 flex items-center'>

        <div className='mx-auto container px-4 xl:px-16 2xl:px-0'>
            <ErrorPage />
        </div>
    </section>;
    return (
        <div className="mb-24  container w-full h-full px-4 xl:px-16 2xl:px-0 ">
            <div className="mx-auto text-center mb-16">

                <H2 classNames={clsx(styles.productStyle["title"])} Component={
                    <>  Solutions cloud pour tous vos besoins</>
                } />
            </div>
            <div className=" mt-24 w-full h-full grid grid-cols-1   gap-y-24">
                {categorizedProducts.map((category, index) => (
                    <div
                        key={`${category.categorie.codeProduitCategorie}`}
                        className="w-full h-full flex flex-col gap-14"
                    >
                        <motion.div
                            whileInView={{ opacity: [0, 1] }}
                            transition={{ duration: 1, delayChildren: 0.5 }}
                        >
                            <h4 className="font-medium text-primary-700 text-2xl lg:text-4xl space-x-4 md:space-x-8 font-semibold flex flex-row items-center">
                                <FontAwesomeIcon icon={icons[index]} className="block text-4xl" />
                                <span className="uppercase">{category.categorie.nom}</span>
                            </h4>{" "}
                        </motion.div>
                        <div className=" w-full h-full grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 2xl:grid-cols-4  gap-x-8 gap-y-8">
                            {category.products.map((product, i) => {
                                const product1: ProductService = {
                                    name: product.nom,
                                    description: product.description,
                                    icon: icons[index],
                                    color: colors[i], // Light blue
                                };
                                return (
                                    <motion.div
                                        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                                        transition={{ duration: 0.5 }}
                                        key={`${product.codeProduitService}`}
                                    >
                                        <ProductView product={product1} />
                                    </motion.div>
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductList;

interface SubRouteProps {
    product: ProductService;
}

const ProductView: React.FC<SubRouteProps> = ({ product }) => {
    const [isHovered, setIsHovered] = useState(false);

    const iconColor = isHovered ? "white" : product.color;

    return (
        <button
            className={clsx(
                styles.productStyle["group"],
                "group w-full h-full text-center px-8 py-6 relative z-1 rounded-md mb-30 shadow-none border-[2px] border-dotted transition-all duration-500 hover:border-none hover:shadow-xl",
                "gap-4",
                "flex flex-col items-center justify-between",
                {
                    "text-white": isHovered,
                }
            )}
            style={{
                transition: "background-color 0.5s",
                backgroundColor: isHovered ? product.color : "transparent",
                borderColor: isHovered ? "transparent" : product.color,
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onFocus={() => setIsHovered(true)}
            onBlur={() => setIsHovered(false)}
        >
            <div
                className={clsx(
                    styles.productStyle["icon"],
                    "border-[1.5px] border-dashed border-gray-800"
                )}
                style={{ color: isHovered ? "white" : product.color }}
            >
                <FontAwesomeIcon icon={product.icon} color={iconColor} />
            </div>
            <p className="text-xl font-semibold  mt-1 transition transition-all duration-200">
                {product.name}
            </p>
            <p className="text-base font-base transition transition-all duration-500">
                {product.description}
            </p>

            <Link
                to={`/products/${createSlug(product.name)}`}
                className={clsx(
                    styles.productStyle["default-btn-one"],
                    "group-hover:border-white hover:border-none mt-2 w-full py-6 border-2 border-primary-300 bg-transparent hover:bg-white hover:text-gray-800 font-semibold transition-colors duration-200 rounded-full h-8 flex items-center justify-center"
                )}
            >
                Voir plus
            </Link>
        </button>
    );
};


const ProductSkeleton = () => {

    return (
        <div
            className={clsx(
                styles.productStyle["group"],
                "group w-full h-full text-center px-8 py-6 relative z-1 rounded-md mb-30 shadow-none border-[2px] border-dotted transition-all duration-500 hover:border-none hover:shadow-xl",
                "gap-4",
                "flex flex-col items-center justify-between",

            )}

        >


            <Skeleton height={70} width={70} circle duration={2} />

            <Skeleton containerClassName="w-4/5 h-[25px]" duration={2} />


            <Skeleton count={6} borderRadius={0} containerClassName="w-full " duration={2} />




            <Skeleton height={50} containerClassName="w-full " duration={2} borderRadius={"8rem"} />

        </div>
    );
};