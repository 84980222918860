import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

// Ce composant vérifie si l'utilisateur est connecté avant de rendre la route
const PrivateRoute: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const location = useLocation();
  localStorage.setItem('next-route', location.pathname);
  // Si l'utilisateur n'est pas connecté, on le redirige vers la page de connexion
  return user ? <Outlet /> : <Navigate to="/signin" state={{ from: location }} />;
};

export default PrivateRoute;
