import { Abonnement } from "./../types/model/abonnement";
import { ImplementationService, PanierItem } from "./../types/model/cart";
import {
    PeriodeSouscription,
    PlanSouscription,
    SupportMaintenance,
} from "../types/model/cart";
import { SubscriptionData } from "../types/model/subscribe_data_model";
import coreApi from "./path/core_api"; // Assurez-vous d'importer votre instance d'API
import { Product } from "../types/model/product";

const subscriptionService = {
    getSubscriptionData: async (
        code: string,
        codeItem: string | null
    ): Promise<SubscriptionData> => {
        try {
            const [
                periodesResponse,
                plansResponse,
                maintenancesResponse,
                implementations,
                product,
            ] = await Promise.all([
                coreApi.get<PeriodeSouscription[]>("/periodes"),
                coreApi.get<PlanSouscription[]>("/plans"),
                coreApi.get<SupportMaintenance[]>("/supports-maintenance"),
                coreApi.get<ImplementationService[]>("/implementation-services/"),
                coreApi.get<Product>(`/products/search?codeProduit=${code}`),
            ]);
            let item = null;

            if (codeItem) {
                const t = await coreApi.get<PanierItem>(
                    `/panier-items/search?codePanierItem=${codeItem}`
                );
                item = t.data;
            }

            const subscriptionData: SubscriptionData = {
                periodes: periodesResponse.data,
                plans: plansResponse.data,
                maintenances: maintenancesResponse.data,
                supportImplementations: implementations.data,
                product: product.data,
                item: item,
            };
            return subscriptionData;
        } catch (error) {
            console.error(
                "Erreur lors de la récupération des données de souscription",
                error
            );
            throw error; // Propagation de l'erreur pour gestion dans l'appelant
        }
    },

    getSubscriptions: async (): Promise<Abonnement[]> => {
        try {
            const abonnements = await coreApi.get<Abonnement[]>("/abonnements");

            return abonnements.data;
        } catch (error) {
            console.error(
                "Erreur lors de la récupération des données de souscription",
                error
            );
            throw error; // Propagation de l'erreur pour gestion dans l'appelant
        }
    },

    cancelSubscription: async (
        code: string,

    ): Promise<void> => {
        try {

            await coreApi.put(`/abonnements/cancel/?codeAbonnement=${code}`);
        } catch (error) {
            console.error(
                "Erreur lors de l'annulation de la souscription",
                error
            );
            throw error; // Propagation de l'erreur pour gestion dans l'appelant
        }
    },
};

export default subscriptionService;
