const authUrl: string = (process.env.REACT_APP_AUTH_API??"https://cloudapps-auth-dev.ic-cloudapps.com") + "/api/v1";

const googleAuth = authUrl +"/google/login";

const urls = {
  authUrl,
  googleAuth,
};

export default urls;
