

import React from 'react'
import { Images } from '../../constant'
import urls from '../../constant/urls';

export const GoogleButton = () => {
    const handleGoogleLogin = () => {
        window.location.href = urls.googleAuth;
    };

    return (
        <button
            onClick={handleGoogleLogin}
            className="flex items-center justify-center w-full px-4 py-2 space-x-3 text-sm text-center text-gray-600 transition-colors duration-300 transform border rounded-lg  hover:bg-gray-100"
        >

            <img loading={'lazy'} src={Images.google} alt="google" className="w-5 h-5 fill-current" />
            <span className="text-sm text-gray-800 ">
                Se connecter avec Google
            </span>
        </button>
    )
}
