import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingSpinner3 from "../components/loading/loading_spinner3";
import { Images } from "../constant";
import productRepository from "../repositories/productRepository";
import { Product } from "../types/model/product";
import { InternalError } from "./InternalError";

import { GlpiDetailPage, OdooDetailPage } from "./products";

export const ProductDetail = () => {
  const { serviceSlug } = useParams<{ serviceSlug: string }>();

  const [product, setProduct] = useState<
    Product
  >();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await productRepository.getProductByName(serviceSlug?.replaceAll("-", " ") ?? "");
        setProduct(result);
      } catch (error) {
        setError("Failed to fetch product");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [serviceSlug]);

  const codeItem = useMemo(() => product?.codeProduitService ?? "", [product])



  if ((loading || !product) && !error) {
    return (
      <div className="w-full h-screen flex items-center">
        <div className="mx-auto container px-4 xl:px-16 2xl:px-0">
          <LoadingSpinner3 />
        </div>
      </div>
    );
  }

  if (error) return <section className='w-full h-screen flex items-center'>

    <div className='mx-auto container px-4 xl:px-16 2xl:px-0'>
      <InternalError />
    </div>
  </section>;



  return (
    <div>
      {serviceSlug?.includes("odoo") ? <OdooDetailPage codeItem={codeItem} /> : serviceSlug?.includes("glpi") ? <GlpiDetailPage codeItem={codeItem} /> : <ComingSoon productName="kdd" />}
    </div>
  );



};

interface ComingSoonProps {
  productName: string;
}

const ComingSoon: React.FC<ComingSoonProps> = ({ productName }) => {
  return (
    <div className="min-h-screen w-full flex items-center justify-center">
      <div className='container w-full h-full px-4 xl:px-16 2xl:px-0 md:flex '>
        <div className="flex items-center justify-center w-full py-10  md:w-1/2">
          <div className="max-w-2xl ">
            <div className="text-4xl xl:text-[60px] text-center sm:text-start font-black text-gray-800 md:text-8xl">Bientôt disponible !</div>
            <div className="w-16 h-1 my-3 bg-primary md:my-6" />
            <p className="text-xl font-light leading-normal text-gray-600 md:text-2xl">
              Restez à l'écoute, nous travaillons pour rendre ce disponible prochainement.{" "}
            </p>
            <p className="py-6 font-light leading-normal text-lg text-gray-400 md:text-xl ">Désolé pour ça ! Veuillez visiter notre page d'accueil pour d'autres services.</p>
            <a href="/">
              <button className="px-4 py-2 mt-4 text-lg text-gray-600 transition-colors duration-300 transform border rounded-lg hover:bg-gray-100 focus:outline-none">
                Aller à l'accueil
              </button>
            </a>
          </div>
        </div>
        <div className="relative w-full pb-full md:flex md:pb-0 md:min-h-screen md:w-1/2">
          <div className="flex items-center w-full h-full max-w-4xl mx-auto">
            <img
              className="object-cover w-full"
              src={Images.unavailable2}
              alt="Error"
            />
          </div>
        </div>
      </div>
    </div>
  );
};