import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const ThankYouPage: React.FC = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100 p-8">

            <div className='rounded-full h-36 bg-primary-500 w-36 flex items-center justify-center mb-6'>
                <FontAwesomeIcon icon={faThumbsUp} className='text-white size-16' />

            </div>


            <h1 className="text-4xl text-center font-bold text-primary-500 mb-6">Merci pour votre confiance !</h1>
            <p className="lg:text-lg  text-center text-gray-700">
                Nous vous remercions pour votre commande et nous espérons que nos services vous satisferont pleinement.
            </p>
            <p className="lg:text-lg  text-center text-gray-700 mt-4">
                Si vous avez des questions, n'hésitez pas à nous contacter à tout moment.
            </p>

        </div>
    );
};

export default ThankYouPage;
