import { initialLocation } from "../constant/constant";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormBaseState } from "../types/model/state_form_subscribe";

const initialState: FormBaseState = {
  numUsers: 1,
  hostingType: "cloud",
  typePlan: "starter",
  isAnnual: true,
  website: false,
  typeMaintenance: null,
  numberOfItem: 1,
  selectedProducts: [],
  cost: 0,
  maintenance: false,
  supportImplementation: false,
  totalPrice: 0,
  numberInstallationDays: 3,
  location: initialLocation,
  urlServer:undefined,
  errorUrl:false
};

const odooSubscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setNumUsers: (state, action: PayloadAction<number>) => {
      state.numUsers = action.payload;
    },
    setHostingType: (state, action: PayloadAction<string>) => {
      state.hostingType = action.payload;
    },
    setTypePlan: (state, action: PayloadAction<string>) => {
      state.typePlan = action.payload;
    },
    setIsAnnual: (state, action: PayloadAction<boolean>) => {
      state.isAnnual = action.payload;
    },
    setWebsite: (state, action: PayloadAction<boolean>) => {
      state.website = action.payload;
    },

    setTotalPrice: (state, action: PayloadAction<number>) => {
      state.totalPrice = action.payload;
    },
    setErrorUrl: (state, action: PayloadAction<boolean>) => {
      state.errorUrl = action.payload;
    },
    setSelectedProducts: (
      state,
      action: PayloadAction<{ value: string; checked: boolean }>
    ) => {
      const { value, checked } = action.payload;
      state.selectedProducts = checked
        ? [...state.selectedProducts, value]
        : state.selectedProducts.filter((product) => product !== value);
    },

    setMaintenance: (state, action: PayloadAction<boolean>) => {
      state.maintenance = action.payload;
    },
    setNumberOfEmployees: (state, action: PayloadAction<number>) => {
      state.numberOfItem = action.payload;
    },
    setSupportImplementation: (state, action: PayloadAction<boolean>) => {
      state.supportImplementation = action.payload;
    },

    setLocation: (state, action: PayloadAction<string>) => {
      state.location = action.payload;
    },

    setUrlServer: (state, action: PayloadAction<string>) => {
      state.urlServer = action.payload;
    },

    setMaintenanceType: (state, action: PayloadAction<string>) => {
      state.typeMaintenance = action.payload;
    },

    setNumberInstallationDays: (state, action: PayloadAction<number>) => {
      state.numberInstallationDays = action.payload;
    },

    setInitData: (state, action: PayloadAction<FormBaseState>) => {
      return { ...state, ...action.payload };
    },

    cleanProduct: (state) => {
      state.selectedProducts = [];
    },

    cleanUrlServer: (state) => {
      state.urlServer = undefined;
    },

    cleanMaintenanceType: (state) => {
      state.typeMaintenance = null;
    },

    // New reducer to reset the state to the initial state
    resetState: () => initialState,
  },
});

export const {
  setNumUsers,
  setHostingType,
  setTypePlan,
  setIsAnnual,
  setWebsite,
  setTotalPrice,
  setSelectedProducts,
  setMaintenance,
  setSupportImplementation,
  setNumberOfEmployees,
  setLocation,
  setNumberInstallationDays,
  setMaintenanceType,
  cleanProduct,
  cleanUrlServer,
  setUrlServer,
  setInitData,
  cleanMaintenanceType,
  resetState, // Export the resetState action
setErrorUrl
} = odooSubscriptionSlice.actions;

export default odooSubscriptionSlice.reducer;
