// store/authSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import authRepository from "../repositories/authRepository";
import { User } from "../types/model/user_model";

interface AuthState {
  user: User | null;
  error: string | null;
  loading: boolean;
  loadingRegister: boolean;
  updatePassLoading : boolean,
  userUpdateloading : boolean,
  errorRegister: string | null;
}

const initialState: AuthState = {
  user: JSON.parse(localStorage.getItem("user") ?? "null"),
  error: null,
  loading: false,
  loadingRegister: false,
  updatePassLoading:false,
  userUpdateloading:false,
  errorRegister: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginRequest(state) {
      state.loading = true;
      state.error = null;
    },
    loginSuccess(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.loading = false;
    },
    loginFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
    logout(state) {
      state.user = null;
      authRepository.logout();
      localStorage.clear();
    },
    registerRequest(state) {
      state.loadingRegister = true;
      state.errorRegister = null;
    },
    registerSuccess(state) {
      state.loadingRegister = false;
    },
    registerFailure(state, action: PayloadAction<string>) {
      state.errorRegister = action.payload;
      state.loadingRegister = false;
    },

    updateRequest(state) {
      state.userUpdateloading = true;
      state.error = null;
    },
    updateSuccess(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.userUpdateloading = false;
    },
    updateFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.userUpdateloading = false;
    },


    updatePassRequest(state) {
      state.updatePassLoading = true;
      state.error = null;
    },
    updatePassSuccess(state) {
      state.updatePassLoading = false;
    },
    updatePassFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.updatePassLoading = false;
    },
  },
});

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  logout,
  registerRequest,
  registerSuccess,
  registerFailure,
  updateFailure,
  updateRequest,
  updateSuccess,
  updatePassFailure, updatePassRequest, updatePassSuccess
} = authSlice.actions;

export const login =
  (email: string, password: string) => async (dispatch: any) => {
    dispatch(loginRequest());
    try {
      await authRepository.login(email, password);
      const user = await authRepository.getMe();
      if (user) {
        dispatch(loginSuccess(user));
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {

        const message =
          error.response?.data?.detail || "Une erreur est survenue";
        dispatch(loginFailure(message));
      }
    }
  };

export const updateUser =
  (codeUtilisateur: string, telephone: string, nom: string, prenom: string) => async (dispatch: any) => {
    dispatch(updateRequest());
    try {

      const new_user = await authRepository.updateUser(codeUtilisateur, telephone, nom, prenom);
      if (new_user) {
        dispatch(updateSuccess(new_user));
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {

        const message =
          error.response?.data?.detail || "Une erreur est survenue";
        dispatch(updateFailure(message));
      }
    }
  };


  export const updatePass =
  (codeUtilisateur: string, new_password: string) => async (dispatch: any) => {
    dispatch(updatePassRequest());
    try {

      await authRepository.updatePass(codeUtilisateur, new_password);
     
        dispatch(updatePassSuccess());
      
    } catch (error) {
      if (axios.isAxiosError(error)) {

        const message =
          error.response?.data?.detail || "Une erreur est survenue";
        dispatch(updatePassFailure(message));
      }
    }
  };


export const getByToken = (token: string) => async (dispatch: any) => {
  dispatch(loginRequest());
  try {
    await authRepository.getMeByToken(token);
    const user = await authRepository.getMe();
    if (user) {
      dispatch(loginSuccess(user));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        const message =
          error.response?.data?.detail || "Une erreur est survenue";
        dispatch(loginFailure(message));
      }
    }
  }
};
export const register =
  (
    nom: string,
    prenom: string,
    email: string,
    password: string,
    telephone: string
  ) =>
    async (dispatch: any) => {
      dispatch(registerRequest());
      try {
        await authRepository.register(nom, prenom, email, password, telephone);
        dispatch(registerSuccess());
      } catch (error) {
        if (axios.isAxiosError(error)) {
         
            const message =
              error.response?.data?.detail || "Une erreur est survenue";
            dispatch(registerFailure(message));
        }
      }
    };

export default authSlice.reducer;
