// repositories/AuthRepository.ts
import authService from "../services/authService";
import { User } from "../types/model/user_model";

const authRepository = {
  login: async (email: string, password: string): Promise<boolean> => {
    try {
      const token = await authService.login(email, password);
      const user = await authService.getMe(token);

      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      return true;
    } catch (error) {
      console.error("Erreur lors de la connexion", error);
      throw error;
    }
  },

  updateUser: async (codeUtilisateur: string,telephone: string, nom: string,prenom:string): Promise<User> => {
    try {
      const user = await authService.updateUser(nom,prenom,telephone,codeUtilisateur);

      localStorage.setItem("user", JSON.stringify(user));
      return user;
    } catch (error) {
      console.error("Erreur lors de la modification ", error);
      throw error;
    }
  },

  updatePass: async (codeUtilisateur: string,new_pass:string): Promise<void> => {
    try {
       await authService.updatePass(new_pass,codeUtilisateur);
    } catch (error) {
      console.error("Erreur lors de la modification ", error);
      throw error;
    }
  }
,
  getMeByToken: async (token: string): Promise<boolean> => {
    try {
      const user = await authService.getMe(token);

      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      return true;
    } catch (error) {
      console.error("Erreur lors de la connexion", error);
      throw error;
    }
  },

  register: async (
    nom: string,
    prenom: string,
    email: string,
    password: string,
    telephone: string
  ): Promise<void> => {
    try {
      await authService.register(nom, prenom, email, password, telephone);
    } catch (error) {
      console.error("Erreur lors de l'inscription", error);
      throw error;
    }
  },
  getMe: async (): Promise<User | null> => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");

    // Renvoyer les données de l'utilisateur si elles existent
    if (token && user) {
      return JSON.parse(user) as User;
    } else {
      // Sinon, on renvoie null pour indiquer qu'il n'y a pas d'utilisateur connecté
      return null;
    }
  },
  logout: async () => {
    const token = localStorage.getItem("token");

    if (token) {
      await authService.logout(token);
    }
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("userSession");

    // Autres actions à effectuer lors de la déconnexion (comme rediriger)
    window.location.reload();
  },
};
export default authRepository;
