export enum StatutPaiement {
    PENDING = 'PEN', // En attente
    SUCCESS = 'SUC', // Succès
    FAILURE = 'FAI', // Échec
    CANCEL = 'CAN'   // Annulé
}

export interface Transaction {
    idPaiement: number;
    codePaiement: string;
    dateCreation: string; // Utilisez Date si vous gérez les dates en tant qu'objets JavaScript
    statut: 'PEN' | 'SUC' | 'FAI' | 'CAN'; // Utilisation d'un type littéral pour les statuts
    montant: number; // Utilisez un nombre pour DECIMAL
    idCommande: number;
    idMethodePaiement: number;
    idMoyenPaiement?: number; // Optionnel
    idUtilisateur: number;
    stripeUrl?: string; // Optionnel
    dateUpdate: string|null; // Optionnel
}