import { Images } from "../../constant";

const EmptyCart = () => {
    return (
        <div className='py-16 text-center'>
            <h3 className='text-3xl font-semibold mb-8'>Votre panier est vide!!</h3>

            <div className='w-full flex items-center justify-center py-8'>
                <div className=" bg-white  rounded mb-3">
                    <div className='max-w-2xl w-full p-8 flex flex-col text-lg text-gray-600 font-light text-center'>
                        <img
                            src={Images.emptyCart}
                            alt="cart is empty"
                            className=""
                        />
                        <h4 className="text-2xl font-semibold text-blue-500 my-8">Oups !! Votre panier est vide !</h4>
                        <p className="mb-1">On dirait que vous n'avez pas encore fait votre choix...</p>
                        <p>Changeons cela.</p>
                        <div className='mt-6 justify-center flex items-center'>
                            <a href='/products' className='py-3 px-8 rounded-md bg-blue-500 hover:bg-primary-300 transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300 text-white font-semibold'>
                                Voir nos produits
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmptyCart;