import { useLocation, Link } from 'react-router-dom';
import clsx from 'clsx';

interface Breadcrumb {
    label: string;
    href: string;
}

export default function Breadcrumbs() {
    const location = useLocation();

    // Map URLs to breadcrumb labels
    const breadcrumbLabels: { [key: string]: string } = {
        'me': 'Mon Compte',
        'profil': 'Profil',
        'orders': 'Commandes',
        'paiements': 'Paiements',
    };

    // Split the pathname into segments
    const pathnames = location.pathname.split('/').filter(Boolean);

    // Build the breadcrumbs array dynamically
    const breadcrumbs: Breadcrumb[] = pathnames.map((segment, index) => {
        const href = `/${pathnames.slice(0, index + 1).join('/')}`;
        const label = breadcrumbLabels[segment] || segment; // Use predefined label or fallback to the segment

        return { label, href };
    });

    return (
        <nav aria-label="Breadcrumb" className="block">
            <ol className={clsx('flex text-xl md:text-2xl')}>
                {breadcrumbs.map((breadcrumb, index) => {
                    const isActive = index === breadcrumbs.length - 1;

                    return (
                        <li
                            key={breadcrumb.href}
                            aria-current={isActive ? 'page' : undefined}
                            className={clsx(isActive ? 'text-gray-900' : 'text-gray-500')}
                        >
                            {isActive ? (
                                breadcrumb.label
                            ) : (
                                <Link to={breadcrumb.href}>{breadcrumb.label}</Link>
                            )}
                            {index < breadcrumbs.length - 1 && (
                                <span className="mx-3 inline-block">/</span>
                            )}
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
}
