import React from 'react'
import { Link } from 'react-router-dom'

export const Breadcrumbs = () => {
    return (
        <div className='mt-8 md:mt-6 w-full bg-gray-100 h-full'>
            <div
                id="page-title"
                className="py-4 md:py-8 flex flex-col gap-8 md:gap-1 justify-center"
            >
                <div className="mx-auto container px-4 xl:px-16 2xl:px-0">
                    <div className="page-title-inner">
                        <div className="text-[32px] font-semibold">
                            <h1 style={{ color: "#323243FF" }}> Panier</h1>
                        </div>
                    </div>
                </div>
                <div className="mx-auto container px-4 xl:px-16 2xl:px-0 text-base">
                    <div className="w-full flex items-center gap-4">
                        <span className='text-gray-600'>
                            <Link to="/" itemProp="url">
                                <span itemProp="title">Accueil</span>
                            </Link>
                        </span>{" "}
                        <span className="text-sm text-gray-600">
                            /
                        </span>{" "}
                        <span className="font-semibold">Panier</span>
                    </div>
                </div>
            </div>

        </div>
    )
}
