import clsx from "clsx";
import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Step configuration
const steps = [
    { label: "Vérifier la commande", path: "/shop/address" },
    { label: "Livraison", path: "/shop/checkout" },
    { label: "Paiement", path: "/shop/payment" },
];

export const Stepper: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Determine the current step index based on the current location
    const currentStepIndex = useMemo(() => {
        return steps.findIndex(step => location.pathname.startsWith(step.path));
    }, [location.pathname]);

    // Function to handle navigation when a step is clicked
    const handleStepClick = (index: number) => {
        // Prevent navigation to future steps
        if (index <= currentStepIndex) {
            navigate(steps[index].path);
        }
    };

    return (
        <div className="w-full max-w-2xl">
            <ol className="lg:flex items-center w-full space-y-4 lg:space-y-0 lg:space-x-4">
                {steps.map((step, index) => {
                    const isActive = index === currentStepIndex;
                    const isCompleted = index < currentStepIndex;
                    const isDisabled = index > currentStepIndex;

                    return (
                        <li key={index} className="relative">
                            <button
                                onClick={() => handleStepClick(index)}
                                disabled={isDisabled}
                                className={clsx(
                                    "flex items-center font-medium w-full",
                                    {
                                        // Active step style
                                        "text-gray-800 font-semibold": isActive,
                                        // Completed step style
                                        "text-blue-900": isCompleted,
                                        // Upcoming step (disabled)
                                        "text-gray-400 cursor-not-allowed": isDisabled,
                                    }
                                )}
                            >
                                {/* Step circle */}
                                <span
                                    className={clsx(
                                        "w-6 h-6 border rounded-full flex justify-center items-center mr-3 text-sm lg:w-8 lg:h-8",
                                        {
                                            "bg-blue-900 text-white border-transparent": isCompleted,
                                            "bg-gray-50 border-gray-200": !isCompleted,
                                        }
                                    )}
                                >
                                    {index + 1}
                                </span>
                                {/* Step label */}
                                <div className="block">
                                    <h4
                                        className={clsx("text-base", {
                                            "text-blue-900": isCompleted,
                                            "text-gray-900": isActive,
                                            "text-gray-400": isDisabled,
                                        })}
                                    >
                                        {step.label}
                                    </h4>
                                </div>
                                {/* Arrow between steps (except for the last one) */}
                                {index < steps.length - 1 && (
                                    <svg
                                        className={clsx("w-5 h-5 ml-2", {
                                            "stroke-blue-900": isCompleted,
                                            "stroke-gray-900": isActive,
                                            "stroke-gray-400": isDisabled,
                                        })}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5 18L9.67462 13.0607C10.1478 12.5607 10.3844 12.3107 10.3844 12C10.3844 11.6893 10.1478 11.4393 9.67462 10.9393L5 6M12.6608 18L17.3354 13.0607C17.8086 12.5607 18.0452 12.3107 18.0452 12C18.0452 11.6893 17.8086 11.4393 17.3354 10.9393L12.6608 6"
                                            stroke="currentColor"
                                            strokeWidth="1.6"
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                )}
                            </button>
                        </li>
                    );
                })}
            </ol>
        </div>
    );
};
