import axios from "axios";


const baseUrl = process.env.REACT_APP_CORE_API ?? "https://cloudapps-core-dev.ic-cloudapps.com/api/v1/"
const coreApi = axios.create({
  baseURL: baseUrl, // URL définie dans le fichier .env
  headers: {
    "Content-Type": "application/json",
  },
});

// Ajouter un intercepteur à votre instance coreApi
coreApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token")
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return  Promise.reject(`error ${error}`);
  }
);
export default coreApi;
