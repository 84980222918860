// src/services/productService.ts
import coreApi from './path/core_api';
import { Product } from '../types/model/product';

const productService = {
  getServices: async (): Promise<Product[]> => {
    const response = await coreApi.get<Product[]>('/products');
    
    return response.data;
  },
};

export default productService;
