import { useState, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { faClock, faTags } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Memoize the links to avoid re-creation on each render
const useLinks = () => useMemo(() => [
    { name: 'Profil', href: '/me/profil', icon: faUser },
    { name: 'Mes abonnements', href: '/me/subscriptions', icon: faTags },
    {
        name: 'Historique', href: '/me/orders', icon: faClock, subMenu: [

            { name: 'Commandes', href: '/me/orders' },
            { name: 'Paiements', href: '/me/paiements' },
        ]
    },
], []);

function NavLinks() {
    const location = useLocation();
    const navigate = useNavigate();
    const { pathname } = location;

    const links = useLinks();  // Use memoized links
    const [expandedMenu, setExpandedMenu] = useState<string | null>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Memoize the click handlers
    const handleMenuClick = useCallback((event: any) => {
        event.preventDefault()
        const href = event.currentTarget.getAttribute('href');
        const link = links.find(linkd => linkd.href === href) ?? links[0];

        if (link?.subMenu !== undefined) {
            setExpandedMenu(link.name);

            if (link.name === expandedMenu) {
                setIsMenuOpen(!isMenuOpen);
            } else {
                setIsMenuOpen(true);
            }
        } else {
            navigate(link.href);
            setIsMenuOpen(false);  // Close the menu on mobile
        }
    }, [links, expandedMenu, isMenuOpen, navigate]);

    const handleLinkClick = useCallback((href: string) => {
        setIsMenuOpen(false);
        navigate(href);
    }, [navigate]);

    return (
        <>
            {links.map((link) => (
                <div key={link.name} className="relative">
                    <Link
                        to={link.href}
                        className={`flex h-[58px] grow items-center gap-2 rounded-md bg-gray-50 p-3 text-sm font-bold text-gray-600 hover:bg-sky-50 hover:text-primary-300 ${link.subMenu ? 'justify-between' : "justify-center md:flex-none md:justify-start"} md:p-2 md:px-3 ${pathname === link.href ? 'bg-sky-50 text-primary-300 shadow' : ''}`}
                        onClick={handleMenuClick}
                    >
                        <div className='flex gap-3 flex-row justify-start items-center'>
                            <FontAwesomeIcon icon={link.icon} className="size-6" />
                            <p className="hidden md:block lg:text-[16px]">{link.name}</p>
                        </div>
                        {link.subMenu && (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                className={`h-5 w-5 transition-transform duration-300 ${expandedMenu === link.name && isMenuOpen ? "rotate-180" : ""}`}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>
                        )}
                    </Link>
                    {link.subMenu && expandedMenu === link.name && isMenuOpen && (
                        <div className="pl-4 w-full">
                            {link.subMenu.map((subLink) => (
                                <button
                                    key={subLink.name}
                                    onClick={() => handleLinkClick(subLink.href)}
                                    className={clsx(
                                        'my-2 w-full flex h-[58px] grow items-center justify-center gap-2 rounded-md bg-gray-50 p-3 text-gray-600 hover:bg-sky-50 hover:text-primary-300 md:flex-none md:justify-start md:p-2 md:px-3',
                                        { 'bg-sky-50 text-primary-300 font-semibold': pathname === subLink.href }
                                    )}
                                >
                                    {subLink.name}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </>
    );
}

export default function SideNav() {
    return (
        <div className="flex h-full rounded-l-lg flex-col px-3 md:px-2">
            <div
                className="mb-2   flex lg:h-20 items-end justify-start rounded-md p-4 md:h-40"
            >
            </div>
            <div className="pb-8 flex grow flex-row justify-between space-x-2 md:flex-col md:space-x-0 md:space-y-2">
                <NavLinks />
                <div className="hidden h-auto w-full grow rounded-md md:block"></div>
            </div>
        </div>
    );
}
