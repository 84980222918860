import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../store";
import { updateUser, updatePass } from "../../store/authSlice";
import { z } from "zod";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// 1. Créer un schéma de validation pour le formulaire de profil
const profileFormSchema = z.object({
    nom: z.string().min(1, { message: "Nom invalide" }),
    prenom: z.string().min(1, { message: "Prénom invalide" }),
    telephone: z
        .string()
        .min(9, { message: "Le téléphone doit contenir au moins 10 caractères" })
        .max(15, { message: "Le téléphone ne doit pas dépasser 15 caractères" }),
});

type ProfileFormData = z.infer<typeof profileFormSchema>;

// 2. Créer un schéma de validation pour le formulaire de mot de passe
const passFormSchema = z
    .object({
        password: z
            .string()
            .min(6, {
                message: "Le mot de passe doit contenir au moins 6 caractères",
            }),
        confirmPass: z.string(),
    })
    .refine((data) => data.password === data.confirmPass, {
        message: "Les mots de passe ne correspondent pas",
        path: ["confirmPass"],
    });

type PassFormData = z.infer<typeof passFormSchema>;

export const ProfilPage = () => {
    return (
        <div className="py-10">
            <div className="mt-6 md:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-xl font-medium text-gray-900">
                                {" "}
                                Informations sur le profil{" "}
                            </h3>{" "}
                            <p className="mt-1 text-gray-600">
                                {" "}
                                Mettre à jour les informations de profil .{" "}
                            </p>
                        </div>
                    </div>{" "}
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <FormProfil />
                    </div>
                </div>{" "}
                <div className="hidden sm:block">
                    <div className="py-8">
                        <div className="border-t border-gray-100" />
                    </div>
                </div>
            </div>{" "}
            <div className="mt-6 md:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-xl font-medium text-gray-900 ">
                                {" "}
                                Modifier le mot de passe{" "}
                            </h3>{" "}
                            <p className="mt-1 text-gray-600">
                                {" "}
                                Assurez-vous que votre compte utilise un mot de passe long et
                                aléatoire pour rester sécurisé.
                            </p>
                        </div>
                    </div>{" "}
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <PassForm />
                    </div>
                </div>
            </div>
        </div>
    );
};

const FormProfil = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { user, userUpdateloading, error } = useSelector(
        (state: RootState) => state.auth
    );
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);

    const [formData, setFormData] = useState<ProfileFormData>({
        nom: user?.nom || "",
        prenom: user?.prenom || "",
        telephone: user?.telephone || "",
    });

    const [errors, setErrors] = useState<z.ZodIssue[]>([]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        // 2. Valider les données du formulaire avec Zod
        const result = profileFormSchema.safeParse(formData);

        if (!result.success) {
            setErrors(result.error.issues);
            return;
        }

        if (user) {
            dispatch(
                updateUser(
                    user.codeUtilisateur,
                    formData.telephone,
                    formData.nom,
                    formData.prenom
                )
            );
            setVisible(true);
        } else {
            navigate("/signin");
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const showToast = useCallback(() => {
        if (!userUpdateloading && visible) {
            if (error) {
                toast.error(error || "Erreur inattendue");
            } else if (user) {
                toast.success("Votre profil a été mis à jour !!");
                setVisible(false);
            }
        }
    }, [error, userUpdateloading, user, visible]);

    useEffect(() => {
        showToast();
    }, [dispatch, error, userUpdateloading, user, showToast, visible]);

    return (
        <form onSubmit={handleSubmit} method="POST">
            <div className="px-4 py-5 bg-white border border-b-0 border-gray-100 sm:p-6 rounded-tl-md rounded-tr-md">
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-4">
                        <label
                            htmlFor="nom"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Nom
                        </label>
                        <input
                            id="nom"
                            type="text"
                            name="nom"
                            value={formData.nom}
                            onChange={handleChange}
                            required
                            className="w-full h-12 px-4 text-gray-700 bg-white border border-gray-200 rounded-lg focus:border-primary-500 focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-20 mt-2"
                        />
                        {errors.find((err) => err.path.includes("nom")) && (
                            <span className="text-red-600">
                                {errors.find((err) => err.path.includes("nom"))?.message}
                            </span>
                        )}
                    </div>

                    <div className="col-span-6 sm:col-span-4">
                        <label
                            htmlFor="prenom"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Prénom
                        </label>
                        <input
                            id="prenom"
                            type="text"
                            name="prenom"
                            value={formData.prenom}
                            onChange={handleChange}
                            required
                            className="w-full h-12 px-4 text-gray-700 bg-white border border-gray-200 rounded-lg focus:border-primary-500 focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-20 mt-2"
                        />
                        {errors.find((err) => err.path.includes("prenom")) && (
                            <span className="text-red-600">
                                {errors.find((err) => err.path.includes("prenom"))?.message}
                            </span>
                        )}
                    </div>

                    <div className="col-span-6 sm:col-span-4">
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Email
                        </label>
                        <input
                            type="email"
                            name="email"
                            id="email" // Ajoutez un ID correspondant à l'attribut htmlFor
                            value={user?.email || ""}
                            disabled
                            className="w-full h-12 bg-gray-100 px-4 text-gray-500 border border-gray-200 rounded-lg focus:border-primary-500 focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-20 mt-2"
                        />
                    </div>

                    <div className="col-span-6 sm:col-span-4">
                        <label
                            htmlFor="phone"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Téléphone
                        </label>
                        <input
                            id="phone"
                            type="text"
                            name="telephone"
                            value={formData.telephone}
                            onChange={handleChange}
                            required
                            className="w-full h-12 px-4 text-gray-700 bg-white border border-gray-200 rounded-lg focus:border-primary-500 focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-20 mt-2"
                        />
                        {errors.find((err) => err.path.includes("telephone")) && (
                            <span className="text-red-600">
                                {errors.find((err) => err.path.includes("telephone"))?.message}
                            </span>
                        )}
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-end px-4 py-3 text-right border border-t-0 border-gray-100 bg-gray-50 sm:px-6 rounded-bl-md rounded-br-md">
                <button
                    type="submit"
                    className={`inline-flex items-center px-4 py-2 text-xs font-semibold tracking-widest text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md ${userUpdateloading
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-primary-500 hover:bg-primary-500/70"
                        } focus:outline-none`}
                    disabled={userUpdateloading}
                >
                    {userUpdateloading ? "En cours..." : "Sauvegarder"}
                </button>
            </div>
        </form>
    );
};

const PassForm = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { user, updatePassLoading, error } = useSelector(
        (state: RootState) => state.auth
    );
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);

    const [formData, setFormData] = useState<PassFormData>({
        password: "",
        confirmPass: "",
    });
    const [errors, setErrors] = useState<z.ZodIssue[]>([]);

    // 2. Méthode de gestion du changement de mot de passe
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        // 3. Valider les données du formulaire
        const result = passFormSchema.safeParse(formData);

        if (!result.success) {
            setErrors(result.error.issues);
        } else if (user) {
            dispatch(updatePass(user.codeUtilisateur, formData.password));
            setVisible(true);
        } else {
            navigate("/signin");
        }
    };

    // Gestion des changements de champ
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const showToast = useCallback(() => {
        if (!updatePassLoading && visible) {
            if (error) {
                toast.error(error || "Erreur inattendue");
            } else if (user) {
                toast.success("Votre mot de passe a été mis à jour !!");

                setVisible(false);
            }
        }
    }, [error, updatePassLoading, user, visible]);

    useEffect(() => {
        showToast();
    }, [dispatch, error, updatePassLoading, user, showToast, visible]);

    return (
        <form method="POST" onSubmit={handleSubmit}>
            <div className="px-4 py-5 bg-white border border-b-0 border-gray-100 sm:p-6 rounded-tl-md rounded-tr-md">
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-4">
                        <label htmlFor="pass" className="block text-sm font-medium text-gray-700">
                            Mot de Passe
                        </label>
                        <input
                            id="pass"
                            type="password"
                            name="password"
                            placeholder="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                            className="w-full h-12 px-4 text-gray-700 bg-white border border-gray-200 rounded-lg focus:border-primary-500 focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-20 mt-2"
                        />
                        {errors.find((err) => err.path.includes("password")) && (
                            <span className="text-red-600">
                                {errors.find((err) => err.path.includes("password"))?.message}
                            </span>
                        )}
                    </div>

                    <div className="col-span-6 sm:col-span-4">
                        <label htmlFor="confirmPass" className="block text-sm font-medium text-gray-700">
                            Confirmer le mot de passe
                        </label>
                        <input
                            id="confirmPass"
                            type="password"
                            name="confirmPass"
                            placeholder="Confirm Password"
                            value={formData.confirmPass}
                            onChange={handleChange}
                            required
                            className="w-full h-12 px-4 text-gray-700 bg-white border border-gray-200 rounded-lg focus:border-primary-500 focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-20 mt-2"
                        />
                        {errors.find((err) => err.path.includes("confirmPass")) && (
                            <span className="text-red-600">
                                {
                                    errors.find((err) => err.path.includes("confirmPass"))
                                        ?.message
                                }
                            </span>
                        )}
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-end px-4 py-3 text-right border border-t-0 border-gray-100 bg-gray-50 sm:px-6 rounded-bl-md rounded-br-md">
                <button
                    type="submit"
                    className={`inline-flex items-center px-4 py-2 text-xs font-semibold tracking-widest text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md ${updatePassLoading
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-primary-500 hover:bg-primary-500/70"
                        } focus:outline-none`}
                    disabled={updatePassLoading}
                >
                    {updatePassLoading ? "En cours..." : "Sauvegarder"}
                </button>
            </div>
        </form>
    );
};
