// src/slices/orderSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import commandeRepository from "../repositories/orderRepository";
import { Order } from "../types/model/order";

interface OrderState {
  loadingCreate: boolean;
  loadingCancel: boolean;
  loadingGet: boolean;
  loadingGetAll:boolean,
  errorCreate: string | null;
  errorCancel: string | null;
  errorGet: string | null;
  errorGetAll: string | null;
  orders: Order[];
  currentOrder: Order | null;
}

const initialState: OrderState = {
  loadingCreate: false,
  loadingCancel: false,
  loadingGet: false,
  loadingGetAll: false,
  errorCreate: null,
  errorCancel: null,
  errorGet: null,
  errorGetAll: null,
  orders: [],
  currentOrder: null,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    // Create order
    createOrderRequest(state) {
      state.loadingCreate = true;
      state.errorCreate = null;
    },
    createOrderSuccess(state, action: PayloadAction<Order>) {
      state.loadingCreate = false;
      state.currentOrder = action.payload;
    },
    createOrderFailure(state, action: PayloadAction<string>) {
      state.errorCreate = action.payload;
      state.loadingCreate = false;
    },

    // Cancel order
    cancelOrderRequest(state) {
      state.loadingCancel = true;
      state.errorCancel = null;
    },
    cancelOrderSuccess(state, action: PayloadAction<string>) {
      state.loadingCancel = false;
      const canceledOrderId = action.payload;
      state.orders = state.orders.map(order =>
        order.codeCommande === canceledOrderId ? { ...order, statut: "CAN" } : order
      );
    },
    cancelOrderFailure(state, action: PayloadAction<string>) {
      state.errorCancel = action.payload;
      state.loadingCancel = false;
    },

    // Fetch orders (get user orders)
    fetchOrdersRequest(state) {
      state.loadingGetAll = true;
      state.errorGetAll = null;
      state.orders = [];
    },
    fetchOrdersSuccess(state, action: PayloadAction<Order[]>) {
      state.orders = action.payload;
      state.loadingGetAll = false;
    },
    fetchOrdersFailure(state, action: PayloadAction<string>) {
      state.errorGet = action.payload;
      state.loadingGetAll = false;
    },

    // Fetch orders (get user orders)
    fetchOrderRequest(state) {
      state.loadingGet = true;
      state.errorGet = null;
      state.currentOrder = null;
    },
    fetchOrderSuccess(state, action: PayloadAction<Order>) {
      state.currentOrder = action.payload;
      state.loadingGet = false;
    },
    fetchOrderFailure(state, action: PayloadAction<string>) {
      state.errorGet = action.payload;
      state.loadingGet = false;
    },
  },
});

export const {
  createOrderRequest,
  createOrderSuccess,
  createOrderFailure,
  cancelOrderRequest,
  cancelOrderSuccess,
  cancelOrderFailure,
  fetchOrdersRequest,
  fetchOrdersSuccess,
  fetchOrdersFailure,

  fetchOrderRequest,
  fetchOrderSuccess,
  fetchOrderFailure,
} = orderSlice.actions;

// Thunks for async operations

// Create Order Thunk
export const createOrder = (codePanier: string, codeAdresse: string) => async (dispatch: any) => {
  dispatch(createOrderRequest());
  try {
    const response = await commandeRepository.createOrder(codePanier, codeAdresse);
    dispatch(createOrderSuccess(response));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const message = error.response?.data?.detail || "Failed to create order";
      dispatch(createOrderFailure(message));
    }
  }
};


export const updateOrder = (codeCommande:string,codeAdresse: string) => async (dispatch: any) => {
  dispatch(createOrderRequest());
  try {
    const response = await commandeRepository.updateOrder(codeCommande, codeAdresse);
    dispatch(createOrderSuccess(response));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const message = error.response?.data?.detail || "Failed to update order";
      dispatch(createOrderFailure(message));
    }
  }
};

// Cancel Order Thunk
export const cancelOrder = (codeUtilisateur: string,orderId: string) => async (dispatch: any) => {
  dispatch(cancelOrderRequest());
  try {
    // Call the repository to cancel the order
    await commandeRepository.cancelOrder(codeUtilisateur,orderId);
    dispatch(cancelOrderSuccess(orderId));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const message = error.response?.data?.detail || "Failed to cancel order";
      dispatch(cancelOrderFailure(message));
    }
  }
};

// Fetch Orders Thunk (get orders for a user)
export const fetchOrders = (codeUtilisateur: string) => async (dispatch: any) => {
  dispatch(fetchOrdersRequest());
  try {
    const response = await commandeRepository.getOrders(codeUtilisateur);
    dispatch(fetchOrdersSuccess(response));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const message = error.response?.data?.detail || "Failed to fetch orders";
      dispatch(fetchOrdersFailure(message));
    }
  }
};

// Fetch Current Order Thunk
export const fetchCurrentOrder = (codeUtilisateur: string) => async (dispatch:any) => {
  dispatch(fetchOrderRequest());
  try {
    const response = await commandeRepository.getOrder(codeUtilisateur);
    dispatch(fetchOrderSuccess(response));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const message = error.response?.data?.detail || "Failed to get order";
      dispatch(fetchOrderFailure(message));
    }
  }
};

export default orderSlice.reducer;
