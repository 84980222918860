import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../store';
import { getByToken } from '../store/authSlice';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GoogleAuthSuccess = () => {
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const { error, loading, user } = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get("token");

        if (token) {
            // Dispatch the Redux action to store the user
            dispatch(getByToken(token));
        }
    }, [dispatch, location]);

    const showToast = useCallback(
        () => {
            if (user && !loading) {
                // Show success toast and delay the redirection
                toast.success("Vous êtes connecté !");
                setTimeout(() => {
                    const next = localStorage.getItem('next-route') || "/";
                    navigate(next)
                }, 2000);

            }
            else if (error) {
                // Show error toast
                toast.error(error || 'Erreur inattendue');
            }
        },
        [error, loading, user, navigate],
    )

    useEffect(() => {
        showToast()
    }, [dispatch, showToast, user, loading, error]);

    if (error) {
        return (
            <main className="grid min-h-full place-items-center  px-6 py-24 sm:py-48 lg:px-8">
                <div className="text-center">
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Nous avons rencontré une erreur !!!</h1>
                    <p className="mt-6 text-lg leading-7 text-gray-600">Redirection en cours...</p>
                </div>
            </main>
        );
    }

    if (loading) {
        return (
            <main className="grid min-h-full place-items-center  px-6 py-24 sm:py-48 lg:px-8">
                <div className="text-center">
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Veuillez patienter !!!</h1>
                    <p className="mt-6 text-lg leading-7 text-gray-600">Chargement en cours ...</p>
                </div>
            </main>
        );
    }
    return (
        <main className="grid min-h-full place-items-center  px-6 py-24 sm:py-48 lg:px-8">
            <div className="text-center">
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Authentification réussie</h1>
                <p className="mt-6 text-lg leading-7 text-gray-600">Redirection en cours...</p>
            </div>
        </main>
    );
};

export default GoogleAuthSuccess;
