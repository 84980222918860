import { ServicesByCategory } from "../..";
import { ProductIcons, ProductPaths } from "../../../constant";

export const odooCategories: ServicesByCategory[] = [
  {
    name: "Boostez vos Ventes",
    products: [
      {
        name: "CRM",
        src: ProductIcons.crm,
        description:
          "Le CRM d'Odoo est conçu pour optimiser la gestion des relations clients. Il centralise les informations client, suit les interactions et aide à coordonner les efforts de vente, permettant une meilleure compréhension des besoins et des comportements des clients.",
        exImageUrl: ProductPaths.crm,
      },
      {
        name: "Ventes",
        src: ProductIcons.ventes,
        description:
          "Le module Ventes d'Odoo est conçu pour gérer efficacement le cycle de vente. Il permet de suivre les opportunités, de gérer les devis et d'analyser les performances, facilitant la gestion des relations commerciales et l'augmentation des ventes.",
        exImageUrl: ProductPaths.ventes,
      },
      {
        name: "Point de Vente",
        src: ProductIcons.pointVente,
        description:
          "Le module Point de Vente d'Odoo est une solution complète pour les commerces. Il offre une interface intuitive pour gérer les ventes, les paiements et l'inventaire en temps réel, optimisant l'expérience client en magasin.",
        exImageUrl: ProductPaths.pointVente,
      },
    ],
  },
  {
    name: "Simplifiez vos operations",
    products: [
      {
        name: "Inventaire",
        src: ProductIcons.inventaire,
        description:
          "Le module Inventaire d'Odoo offre une gestion précise du stock. Il permet de suivre les niveaux de stock, de gérer les mouvements et d'optimiser les opérations d'entreposage, réduisant les erreurs et améliorant l'efficacité logistique.",
        exImageUrl: ProductPaths.inventaire,
      },
      {
        name: "Achats",
        src: ProductIcons.achat,
        description:
          "Le module Achats d'Odoo optimise le processus d'approvisionnement. Il facilite la gestion des commandes, le suivi des fournisseurs et l'optimisation des coûts, assurant une gestion efficace des achats au sein de l'entreprise.",
        exImageUrl: ProductPaths.achat,
      },
      {
        name: "PLM",
        src: ProductIcons.plm,
        description:
          "Odoo PLM (Product Lifecycle Management) est une suite logicielle complète de gestion du cycle de vie des produits qui aide les entreprises à gérer l'ensemble du cycle de vie d'un produit, de sa conception à sa commercialisation et au-delà. Il offre une plateforme centralisée pour la collaboration entre les différents départements impliqués dans le processus de développement produit, tels que l'ingénierie, la conception, la fabrication et le marketing.",
        exImageUrl: ProductPaths.plm,
      },
    ],
  },
  {
    name: "Gerez vos finances",
    products: [
      {
        name: "Facturation",
        src: ProductIcons.facturation,
        description:
          "Odoo Facturation est un module de facturation complet intégré à la suite ERP Odoo. Il vous permet de créer, envoyer et suivre vos factures facilement, tout en automatisant de nombreuses tâches liées à la facturation",
        exImageUrl: ProductPaths.facturation,
      },
      {
        name: "Comptabilité",
        src: ProductIcons.icon,
        description:
          "Le module de Comptabilité d'Odoo permet une gestion précise et efficace des finances de votre entreprise. Il offre des fonctionnalités pour le suivi des comptes, la gestion des paiements et la génération de rapports financiers, assurant la conformité avec les normes comptables.",
        exImageUrl: ProductPaths.comptabilite,
      },
    ],
  },
  {
    name: "Contruisez de magnifiques sites web",
    products: [
      {
        name: "Site Web",
        src: ProductIcons.siteWeb,
        description:
          "Le module Site Web d'Odoo permet de créer et gérer des sites web de manière intuitive. Il offre des outils pour concevoir des pages attrayantes, gérer le contenu et optimiser la visibilité en ligne, sans nécessiter de compétences techniques avancées.",
        exImageUrl: ProductPaths.siteWeb,
      },
      {
        name: "eCommerce",
        src: ProductIcons.ecommerce, description:
          "Il est excellent, facile à configurer et étonnamment complet. Concevez des pages produits attrayantes avec l'un des meilleurs constructeurs de pages du marché. Ensuite, du marketing aux opérateurs, profitez de la simplicité d'une plateforme unifiée.",
        exImageUrl: ProductPaths.ecommerce,
      },
    ],
  },

  {
    name: "Site internet",
    products: [
      {
        name: "Blog",
        src: ProductIcons.blog, description:
          "Odoo Blog est une plateforme de blog entièrement intégrée qui fait partie de la suite d'applications d'Odoo. Elle permet aux entreprises et aux organisations de créer, publier et gérer facilement un blog sur leur site web. ",
        exImageUrl: ProductPaths.blog,
      },
      {
        name: "Forums",
        src: ProductIcons.forums, description:
          "Odoo Forums est une plateforme communautaire en ligne où les utilisateurs d'Odoo peuvent se connecter, partager leurs connaissances et obtenir de l'aide. C'est une ressource précieuse pour quiconque utilise Odoo, des débutants aux développeurs expérimentés.",
        exImageUrl: ProductPaths.forums,
      },
      {
        name: "eLearning",
        src: ProductIcons.elearning, description:
          "Odoo eLearning est un système de gestion de l'apprentissage (LMS) intégré à la suite ERP Odoo. Il permet aux entreprises et aux organisations de créer, gérer et diffuser facilement des formations en ligne à leurs employés, clients ou partenaires.",
        exImageUrl: ProductPaths.elearning,
      },
      {
        name: "Live Chat",
        src: ProductIcons.live_chat, description:
          "Odoo Live Chat est une application de chat en direct intégrée à la suite ERP Odoo. Elle vous permet de communiquer instantanément avec les visiteurs de votre site web, de répondre à leurs questions, de résoudre leurs problèmes et de générer des leads.",
        exImageUrl: ProductPaths.live_chat,
      },
    ],
  },

  {
    name: "Ventes",
    products: [
      {
        name: "Abonnements",
        src: ProductIcons.ventes,
        description:
          "Le module Abonnements d'Odoo facilite la gestion des services récurrents. Il automatise les processus de facturation et de renouvellement, assure le suivi des abonnements et offre une vue d'ensemble des revenus récurrents de l'entreprise.",
        exImageUrl: ProductPaths.abonnement,
      },
      {
        name: "Location",
        src: ProductIcons.location,
        description:
          "Le module Location d'Odoo permet une gestion efficace des contrats de location. Il suit les réservations, gère les paiements et optimise l'utilisation des ressources disponibles, facilitant ainsi les opérations de location pour les entreprises.",
        exImageUrl: ProductPaths.location,
      },
    ],
  },
  {
    name: "Finance",
    products: [
      {
        name: "Notes de frais",
        src: ProductIcons.frais, description:
          "Odoo Notes de Frais est une application de gestion de notes de frais intégrée à la suite ERP Odoo. Elle permet aux employés de soumettre leurs notes de frais en ligne, aux gestionnaires de les approuver ou les rejeter, et au service comptabilité de les traiter et de rembourser les employés.",
        exImageUrl: ProductPaths.noteFrais,
      },
      {
        name: "Documents",
        src: ProductIcons.document,
        description:
          "Le module Documents d'Odoo permet la gestion et le partage de fichiers au sein de l'entreprise. Il offre un espace sécurisé pour stocker, organiser et accéder facilement aux documents importants, améliorant l'efficacité du travail collaboratif.",
        exImageUrl: ProductPaths.document,
      },
      {
        name: "Feuille de calcul",
        src: ProductIcons.feuille_calcul, description:
          "Odoo Feuille de Calcul est un tableur complet intégré à la suite ERP Odoo. Il offre une large gamme de fonctionnalités pour l'analyse de données, la visualisation et la collaboration, ce qui en fait un outil précieux pour les entreprises de toutes tailles.                ",
        exImageUrl: ProductPaths.feuille_calcul,
      },
      {
        name: "Signature",
        src: ProductIcons.signature,
        description:
          "Le module Signature d'Odoo permet la gestion des signatures électroniques. Il facilite la validation des documents en ligne, assurant une sécurité renforcée et une réduction des délais de traitement grâce à l'automatisation des processus de signature.",
        exImageUrl: ProductPaths.signature,
      },
    ],
  },
  {
    name: "Inventaire & Fabrication",
    products: [
      {
        name: "Fabrication",
        src: ProductIcons.fabrication,
        description:
          "Le module Fabrication d'Odoo permet de gérer le processus de production. Il inclut des fonctionnalités pour planifier, suivre et analyser la fabrication, assurant une production optimisée et une gestion efficace des ressources.",
        exImageUrl: ProductPaths.fabrication,
      },
      {
        name: "Maintenance",
        src: ProductIcons.maintenance,
        description:
          "Odoo Maintenance est un module de gestion de maintenance complet intégré à Odoo ERP qui vous permet de:\nGérer les demandes de maintenance\nCentraliser les informations sur les équipementsOptimiser la gestion des centres de travail",
        exImageUrl: ProductPaths.maintenance,
      },
      {
        name: "Qualite",
        src: ProductIcons.qualite,
        description:
          "Odoo Qualité est le meilleur moyen de s'attaquer aux causes principales des défauts coûteux et de garantir la qualité tout au long du cycle de vie du produit.",
        exImageUrl: ProductPaths.qualite,
      },
    ],
  },
  {
    name: "Ressources humaines",
    products: [
      {
        name: "Employes",
        src: ProductIcons.employe,
        description:
          "L'application Employés d'Odoo est bien plus qu'un simple régistre du personnel. C'est le point central d'une suite complète de fonctionnalités RH.",
        exImageUrl: ProductPaths.employe,
      },
      {
        name: "Recrutement",
        src: ProductIcons.recruitment,
        description:
          "Odoo Recrutement est un module RH complet qui vous permet de rationaliser l'ensemble de votre processus de recrutement, de la publication d'offres d'emploi à l'intégration des nouveaux collaborateurs. ",
        exImageUrl: ProductPaths.recruitment,
      },
      {
        name: "Conges",
        src: ProductIcons.conges,
        description:
          "Odoo Congés est une application de gestion des congés complète et intuitive qui vous permet de simplifier la gestion des demandes de congés, le suivi des soldes de congés et la communication avec vos employés.",
        exImageUrl: ProductPaths.conges,
      },
      {
        name: "Evaluations",
        src: ProductIcons.evaluation,
        description:
          "Odoo Évaluation permet de fournir de façon régulière du feedback à vos employés et de les laisser s'exprimer en retour",
        exImageUrl: ProductPaths.evaluation,
      },
      {
        name: "Referral",
        src: ProductIcons.referral,
        description:
          "Le programme de parrainage d'Odoo est une fonctionnalité intégrée qui vous permet de récompenser vos clients existants pour avoir recommandé vos produits ou services à leurs amis et collègues. C'est un excellent moyen d'acquérir de nouveaux clients, d'augmenter vos ventes et de fidéliser votre clientèle actuelle.",
        exImageUrl: ProductPaths.referral,
      },
      {
        name: "Parc automobile",
        src: ProductIcons.parc,
        description:
          "Odoo Fleet est une solution complète de gestion de parc automobile qui vous permet de gérer efficacement vos véhicules, conducteurs et opérations de maintenance",
        exImageUrl: ProductPaths.parc,
      },
    ],
  },
  {
    name: "Marketing",
    products: [
      {
        name: "Automatisation marketing",
        src: ProductIcons.marketingAutomatisation,
        description:
          "L'automatisation marketing est l'utilisation de logiciels pour automatiser des tâches marketing répétitives et chronophages, telles que l'envoi d'e-mails, la publication sur les réseaux sociaux et la gestion des leads. Cela permet aux spécialistes du marketing de se concentrer sur des tâches plus stratégiques et créatives, tout en augmentant l'efficacité et la rentabilité de leurs campagnes marketing.",
        exImageUrl: ProductPaths.marketingAutomatisation,
      },
      {
        name: "Email Marketing",
        src: ProductIcons.email,
        description:
          "Le module Email Marketing d'Odoo est conçu pour gérer les campagnes d'emailing. Il offre des outils pour créer, envoyer et suivre les performances des emails, aidant à engager les clients et à générer des leads.",
        exImageUrl: ProductPaths.email_marketing,
      },
      {
        name: "Marketing SMS",
        src: ProductIcons.sms,
        description:
          "Le marketing par SMS est une stratégie marketing puissante qui permet d'envoyer des messages texte courts et percutants directement aux téléphones mobiles de vos clients. Cette approche directe et immédiate offre de nombreux avantages pour les entreprises de toutes tailles.                ",
        exImageUrl: ProductPaths.sms,
      },
      {
        name: "Social Marketing",
        src: ProductIcons.social,
        description:
          "Le module Social Marketing d'Odoo aide à gérer les campagnes sur les réseaux sociaux. Il permet de planifier, publier et analyser les performances des publications, facilitant la gestion de la présence en ligne de l'entreprise.",
        exImageUrl: ProductPaths.social,
      },
      {
        name: "Event",
        src: ProductIcons.events,
        description:
          "Le module Evénements est une solution complète de gestion d'événements qui vous accompagne de A à Z, de la planification à l'analyse post-événement, pour vous aider à organiser des événements professionnels ou personnels mémorables.",
        exImageUrl: ProductPaths.events,
      },
      {
        name: "Sondage ",
        src: ProductIcons.sondages,
        description:
          "Capturez les retours de vos clients et améliorez votre entreprise                Créez des enquêtes captivantes, recueillez des données précieuses et prenez des décisions éclairées pour propulser votre entreprise vers de nouveaux sommets avec Odoo Sondages.",
        exImageUrl: ProductPaths.sondages,
      },
    ],
  },
  {
    name: "Services",
    products: [
      {
        name: "Project",
        src: ProductIcons.projet,
        description:
          "Le module Project d'Odoo aide à la gestion des projets de bout en bout. Il permet de planifier, suivre les tâches, attribuer des responsabilités et analyser les performances, garantissant la livraison des projets dans les délais et selon les spécifications.",
        exImageUrl: ProductPaths.project,
      },
      {
        name: "Feuille de temps ",
        src: ProductIcons.feuille_temps,
        description:
          "Le module Feuille de temps d'Odoo vous permet de suivre et de gérer le temps de travail de vos employés de manière simple et efficace. Il offre un large éventail de fonctionnalités pour répondre aux besoins des entreprises de toutes tailles",
        exImageUrl: ProductPaths.feuille_temps,
      },
      {
        name: "Service sur site ",
        src: ProductIcons.service_site,
        description:
          "Le module Services sur site d'Odoo offre une solution complète pour gérer vos interventions sur le terrain, de la planification des tâches à la facturation des clients. ",
        exImageUrl: ProductPaths.service_site,
      },
      {
        name: "Assistance",
        src: ProductIcons.assistance,
        description:
          "Le module Assistance d'Odoo est dédié à la gestion du support client. Il permet de suivre les tickets, de prioriser les demandes et d'automatiser les processus de service client, assurant une réponse rapide et efficace aux besoins des clients.",
        exImageUrl: ProductPaths.assistance,
      },
      {
        name: "Planification ",
        src: ProductIcons.planification,
        description:
          "Le module Planification vous permet de gérer efficacement vos ressources et de planifier vos activités, en vous offrant une vue d'ensemble centralisée de vos opérations.",
        exImageUrl: ProductPaths.planification,
      },
      {
        name: "Rendez-vous ",
        src: ProductIcons.rendez_vous,
        description:
          "Le module Rendez-vous vous permet de planifier, gérer et suivre vos rendez-vous directement depuis votre plateforme Odoo. Offrez à vos clients une expérience de réservation en ligne fluide et optimisez la gestion de votre agenda.",
        exImageUrl: ProductPaths.rendez_vous,
      },
    ],
  },
  {
    name: "Productivité",
    products: [
      {
        name: "Discussion",
        src: ProductIcons.discussion,
        description:
          "Le module Discussion d'Odoo facilite la communication interne. Il centralise les messages, les discussions de groupe et les notifications, améliorant la collaboration entre les membres de l'équipe et la gestion des projets.",
        exImageUrl: ProductPaths.discussion,
      },
      {
        name: "Validations ",
        src: ProductIcons.validation,
        description:
          "Le module Validations vous permet de mettre en place des workflows d'approbation automatiques pour vos processus métier, garantissant un contrôle et une visibilité accrus sur vos opérations.",
        exImageUrl: ProductPaths.validation,
      },
      {
        name: "Internet des Objets ",
        src: ProductIcons.iot,
        description:
          "Le module IoT vous permet de connecter et de gérer vos appareils intelligents directement depuis votre plateforme Odoo. Collectez des données en temps réel, surveillez l'état de vos équipements, automatisez des actions et optimisez vos processus opérationnels.                    ",
        exImageUrl: ProductPaths.iot,
      },
      {
        name: "VOID ",
        src: ProductIcons.voip,
        description:
          "Le module VoIP vous permet de passer et de recevoir des appels téléphoniques directement depuis votre interface Odoo, éliminant le besoin d'un système téléphonique distinct. Intégré à des applications telles que la CRM, les ventes, le support et la facturation, Odoo VoIP rationalise vos flux de travail et vous évite des tâches manuelles fastidieuses.",
        exImageUrl: ProductPaths.voip,
      },
      {
        name: "Connaissances",
        src: ProductIcons.connaissance,
        description:
          "Le module Connaissances d'Odoo centralise les informations clés de l'entreprise, facilitant le partage et l'accès au savoir. Il améliore la collaboration interne en permettant aux équipes de créer, stocker et partager des documents et des procédures.",
        exImageUrl: ProductPaths.connaissance,
      },
    ],
  },
  {
    name: "Personnalisation",
    products: [
      {
        name: "Studio",
        src: ProductIcons.studio,
        description:
          "Le module Studio permet aux utilisateurs de personnaliser les applications Odoo sans nécessiter de compétences en programmation. Il offre une interface intuitive pour modifier les formulaires, ajouter des champs et ajuster les workflows selon les besoins spécifiques de l'entreprise.",
        exImageUrl: ProductPaths.studio,
      },
    ],
  },
];
