import { Transaction } from "../types/model/transaction";
import payApi from "./path/pay_api";

const transactionService = {
  createTransaction: async (codeCommande: string): Promise<void> => {
    try {
      const response = await payApi.post(`/paiements/create-stripe-payment?codeCommande=${codeCommande}`)//await payApi.post(`/paiements/create-payment/?codeCommande=${codeCommande}`)
      window.open(response.data.url, "_blank")
      return response.data
    } catch (error) {
      console.error("create-payment:", error);
      throw error;
    }
  },

  cancelTransaction: async (codeTransaction: string): Promise<void> => {
    try {
      await payApi.post<Transaction>(`/paiements/cancel-payment/?codePaiement=${codeTransaction}`,)

      // throw new Error("UnImplemented");
    } catch (error) {
      console.error("cancel-Transaction:", error);
      throw error;
    }
  },

  getTransactions: async (): Promise<Transaction[]> => {
    try {
      const response = await payApi.get<Transaction[]>('/paiements/');
      const transactions: Transaction[] = response.data;
      return transactions;
    } catch (error) {
      console.error("GET-Transaction:", error);
      throw error;
    }
  },
};

export default transactionService;
