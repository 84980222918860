import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useAdresseContext } from "../../context/AdresseContext";
import { InternalError } from "../InternalError";
import { AppDispatch, RootState } from "../../store";
import { createOrder, fetchCurrentOrder, updateOrder } from "../../store/orderSlice";
import LoadingSpinner3 from "../../components/loading/loading_spinner3";
import ModalDialog from "../../components/modal/modal_t";


import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const CheckoutPage = () => {
    const { user } = useSelector((state: RootState) => state.auth);
    const { adresses, loading, error, fetchAdresses } = useAdresseContext();
    const navigate = useNavigate()
    const { currentOrder, } = useSelector((state: RootState) => state.order);

    const { loadingCreate, errorCreate } = useSelector((state: RootState) => state.order);
    const { cart } = useSelector((state: RootState) => state.cart);
    const dispatch = useDispatch<AppDispatch>();

    const [checkedAdresse, setCheckedAdresse] = useState<string>("");
    const [total, setTotal] = useState<number>(0);
    const [hasAttemptedToCreateOrder, setHasAttemptedToCreateOrder] = useState(false);

    // Fetch current order when user changes
    useEffect(() => {
        if (user) {
            dispatch(fetchCurrentOrder(user.codeUtilisateur));
        }
    }, [dispatch, user]);

    // Toast notification
    const showToast = useCallback(() => {
        if (!loadingCreate && errorCreate) {
            toast.error(errorCreate || "Erreur inattendue");
            setHasAttemptedToCreateOrder(false);
        }
    }, [loadingCreate, errorCreate]);

    // Set default address when addresses are available
    useEffect(() => {
        if (adresses.length > 0) {
            if (currentOrder) {
                setCheckedAdresse(currentOrder.details.codeAdresse)
                return;
            }
            const defaultAddress = adresses.find((ad) => ad.parDefaut);
            if (defaultAddress) {
                setCheckedAdresse(defaultAddress.codeAdresse)
                return
            };
        } else {
            fetchAdresses(user!.codeUtilisateur)
        }
    }, [adresses, user, fetchAdresses, currentOrder]);

    // Set total when the cart is available
    useEffect(() => {
        if (cart) {
            setTotal(parseInt(cart.montantTotal ?? "0", 10));
        }
    }, [cart]);


    useEffect(() => {
        showToast();
    }, [loadingCreate, errorCreate, showToast]);

    // Memoize the order creation function
    const handleCreateOrder = useCallback(async () => {
        if (cart && checkedAdresse) {
            setHasAttemptedToCreateOrder(true);
            try {
                await dispatch(createOrder(cart.codePanier, checkedAdresse));
            } catch (error) {
                console.error("Failed to create Order:", error);
            }
        }
    }, [cart, checkedAdresse, dispatch]);


    const handleUpdateOrder = useCallback(async () => {
        if (checkedAdresse && currentOrder) {
            setHasAttemptedToCreateOrder(true);
            try {
                await dispatch(updateOrder(currentOrder.codeCommande, checkedAdresse));
            } catch (error) {
                console.error("Failed to update Order:", error);
            }
        }
    }, [currentOrder, checkedAdresse, dispatch]);


    // Memoize the submit function
    const handleSubmit = useCallback(() => {
        if (currentOrder) {
            handleUpdateOrder()
        } else {
            handleCreateOrder();
        }
    }, [handleCreateOrder, handleUpdateOrder, currentOrder]);

    useEffect(() => {
        // On vérifie si l'ajout a été tenté, que l'ajout est terminé, et qu'il n'y a pas d'erreur
        if (hasAttemptedToCreateOrder && !loadingCreate && !errorCreate && currentOrder) {
            navigate(`/shop/payment`);
        }
    }, [loadingCreate, errorCreate, hasAttemptedToCreateOrder, currentOrder, navigate]);


    // Loading and error conditions
    if (loading) {
        return (
            <div className="w-full h-screen flex items-center">
                <div className="mx-auto container px-4 xl:px-16 2xl:px-0">
                    <LoadingSpinner3 />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <section className="w-full h-screen flex items-center">
                <div className="mx-auto container px-4 xl:px-16 2xl:px-0">
                    <InternalError />
                </div>
            </section>
        );
    }

    return (
        <div className={clsx("flex flex-col items-center relative lg:h-full")}>
            <div className="w-full container mx-auto px-4 xl:px-16 2xl:px-0">
                <div className="w-full flex flex-col md:flex-row gap-8 lg:gap-16  h-full py-8">
                    <div className="w-full md:w-1/2 lg:w-7/12 2xl:w-2/3 h-full flex flex-col gap-6">

                        <div className="w-full flex flex-col gap-4">
                            <h3 className="text-2xl font-medium">Adresse de facturation</h3>

                            <div className="grid lg:grid-cols-2 xl:grid-cols-3 xl:max-w-3xl gap-8">
                                {adresses.map((ad) => (
                                    <button
                                        key={ad.codeAdresse}
                                        onClick={() => setCheckedAdresse(ad.codeAdresse)}
                                        className={clsx(
                                            "border border-gray-300 rounded-lg p-4 flex flex-col text-start w-full",
                                            {
                                                "border-primary-500/80 border-2 bg-gray-100": checkedAdresse === ad.codeAdresse,
                                                "bg-white": checkedAdresse !== ad.codeAdresse,
                                            }
                                        )}
                                    >
                                        <div className="flex flex-col justify-between h-full">
                                            <span className="font-medium text-base break-words capitalize">
                                                {ad.entreprise}, {ad.nom}, {ad.prenom}
                                            </span>
                                            <div className="flex flex-col text-sm break-words capitalize">
                                                <span>{ad.adresse}</span>
                                                <span>
                                                    {ad.codePostal} {ad.ville}
                                                </span>
                                                <span>{ad.pays}</span>
                                            </div>
                                            <div className="pt-2 flex items-center justify-start">
                                                <Link
                                                    to={`/shop/address/${ad.codeAdresse}`}
                                                    title="Modifier cette adresse"
                                                    className="text-sm font-bold text-primary-500 flex items-center gap-1"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                        className="size-4"
                                                    >
                                                        <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
                                                    </svg>
                                                    <span>Modifier</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </button>
                                ))}
                            </div>

                            <div className="pt-8">
                                <Link
                                    to="/shop/address"
                                    className="px-8 py-2 w-fit border border-gray-300 rounded-lg flex items-center justify-center gap-2 hover:bg-primary-500/80 hover:text-white"
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                    <span className="font-medium text-base break-words capitalize">
                                        Ajouter une adresse
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="mt-6 max-w-sm w-full space-y-6 lg:mt-0">
                            <div className="space-y-8 rounded-lg border bg-white p-4 shadow-sm sm:p-6">
                                <p className="text-xl font-semibold text-gray-900">Récapitulatif de la commande</p>
                                <div className="space-y-4">
                                    <dl className="flex items-center justify-between gap-4">
                                        <dt className="text-base font-normal text-gray-500">Prix ​​d'origine</dt>
                                        <dd className="text-base font-medium text-gray-900">{total} FCFA</dd>
                                    </dl>
                                    <dl className="flex items-center justify-between gap-4 border-t pt-2">
                                        <dt className="text-base font-bold text-gray-900">Total</dt>
                                        <dd className="text-base font-bold text-gray-900">{total} FCFA</dd>
                                    </dl>
                                </div>

                                <div className="block w-full flex flex-col gap-6">
                                    <h4 className="font-bold text-xl">Conditions de ventes</h4>
                                    <div>
                                        <span className="uppercase">Ventes Finales</span> - Veuillez noter que toutes les ventes d'abonnements Cloudapps
                                        Enterprise et des services connexes sont définitives et non remboursables.
                                    </div>
                                </div>

                                <button
                                    disabled={!checkedAdresse}
                                    onClick={handleSubmit}
                                    className={clsx(
                                        "flex w-full items-center justify-center rounded-lg bg-primary-500 px-5 py-2.5 font-bold text-white transition-all duration-300 ease-out",
                                        {
                                            "opacity-100 hover:bg-blue-500 focus:outline-none focus:ring-4 focus:ring-primary-300":
                                                checkedAdresse,
                                        }
                                    )}
                                >
                                    Confirmer
                                </button>

                                <div className="flex items-center justify-center gap-2">
                                    <span className="text-sm font-normal text-gray-500">ou</span>
                                    <a
                                        href="/cart"
                                        title="Retour au panier"
                                        className="inline-flex items-center gap-2 text-sm font-medium text-primary-700 underline hover:no-underline"
                                    >
                                        Retour au panier
                                        <svg
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M19 12H5m14 0-4 4m4-4-4-4"
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalDialog isOpen={loadingCreate && hasAttemptedToCreateOrder}>
                <div className="mx-auto container px-4 xl:px-16 2xl:px-0">
                    <LoadingSpinner3 />
                </div>
            </ModalDialog>
        </div>
    );
};
