import { useCallback, useEffect, useState } from 'react';
import subscriptionRepository from '../repositories/subscriptionRepository';
import { Abonnement } from './../types/model/abonnement';
// src/hooks/useSubscription.ts

const useSubscriptions = () => {
  const [abonnements, setAbonnements] = useState<Abonnement[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);


  const [loadingCancel, setLoadingCancel] = useState<boolean>(false);
  const [errorCancel, setErrorCancel] = useState<string | null>(null);


  // Fetch Abonneent
  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await subscriptionRepository.getSubscriptions();
      setAbonnements(result);
    } catch (error) {
      setError("Failed to fetch subscriptions");
    } finally {
      setLoading(false);
    }
  }, []);



  // Method to cancel a transaction
  const cancelAbonnement = useCallback(
    async (codeAbonnement: string) => {
      setLoadingCancel(true);
      setErrorCancel(null);
      try {
        await subscriptionRepository.cancelSubscription(codeAbonnement);
        await fetchData(); // Refresh transactions after cancellation
      } catch (error) {
        setErrorCancel("Failed to cancel transaction");
      } finally {
        setLoadingCancel(false);
      }
    },
    [fetchData]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    abonnements, loading, error, loadingCancel,
    errorCancel,
    cancelAbonnement
  };
};

export default useSubscriptions;
