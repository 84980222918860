import { useEffect, useState, useCallback } from 'react';
import transactionRepository from '../repositories/transactionRepository';
import { Transaction } from '../types/model/transaction';

const useTransaction = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [loadingCreation, setLoadingCreation] = useState<boolean>(false);
  const [errorCreation, setErrorCreation] = useState<string | null>(null);

  const [loadingCancel, setLoadingCancel] = useState<boolean>(false);
  const [errorCancel, setErrorCancel] = useState<string | null>(null);

  // Fetch transactions
  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await transactionRepository.getTransactions();
      setTransactions(result);
    } catch (error) {
      setError("Failed to fetch transactions");
    } finally {
      setLoading(false);
    }
  }, []);

  // Method to create a transaction
  const createTransaction = useCallback(
    async (codeCommande: string) => {
      setLoadingCreation(true);
      setErrorCreation(null);
      try {
        await transactionRepository.createTransaction(codeCommande);
        await fetchData(); // Refresh transactions after creation
      } catch (error) {
        setErrorCreation("Failed to create transaction");
      } finally {
        setLoadingCreation(false);
      }
    },
    [fetchData]
  );

  // Method to cancel a transaction
  const cancelTransaction = useCallback(
    async (codeTransaction: string) => {
      setLoadingCancel(true);
      setErrorCancel(null);
      try {
        await transactionRepository.cancelTransaction(codeTransaction);
        await fetchData(); // Refresh transactions after cancellation
      } catch (error) {
        setErrorCancel("Failed to cancel transaction");
      } finally {
        setLoadingCancel(false);
      }
    },
    [fetchData]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    transactions,
    loading,
    error,
    createTransaction,
    cancelTransaction,
    loadingCreation,
    errorCreation,
    loadingCancel,
    errorCancel,
  };
};

export default useTransaction;
