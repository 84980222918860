import axios from "axios";


const baseUrl = process.env.REACT_APP_PAY_API ?? "https://cloudapps-pay-dev.ic-cloudapps.com/api/v1"
const payApi = axios.create({
  baseURL: baseUrl, // URL définie dans le fichier .env
  headers: {
    "Content-Type": "application/json",
  },
});

// Ajouter un intercepteur à votre instance payApi
payApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token")
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(`error ${error}`);
  }
);
export default payApi;
