import clsx from "clsx";
import { useCallback, useEffect, useMemo, useState } from "react";

interface ToggleButtonProps {
    initialChecked: boolean;
    onCheckedChange: (checked: boolean) => void;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
    initialChecked,
    onCheckedChange,
}) => {
    const [checked, setChecked] = useState<boolean>(initialChecked);
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);

    // Synchronise checked avec initialChecked quand ce dernier change
    useEffect(() => {
        setChecked(initialChecked);
    }, [initialChecked]);

    // Garde une trace de la première initialisation
    useEffect(() => {
        if (hasInitialized) {
            onCheckedChange(checked);
        } else {
            setHasInitialized(true);
        }
    }, [checked, onCheckedChange, hasInitialized]);

    const toggleChecked = useCallback(() => {
        setChecked((prevChecked) => !prevChecked);
    }, []);

    const checkedClassName = useMemo(
        () => clsx("ml-3", { "text-gray-900": checked }),
        [checked]
    );

    const uncheckedClassName = useMemo(
        () => clsx("mr-3", { "text-gray-800": checked }),
        [checked]
    );

    return (
        <div className="flex items-center text-xl leading-10 font-semibold text-gray-700">
            <span className={uncheckedClassName}>Mensuel</span>
            <label htmlFor="toogleButton" className="relative inline-flex items-center cursor-pointer">
                <input
                    id="toogleButton"
                    type="checkbox"
                    className="sr-only peer"
                    checked={checked}
                    onChange={toggleChecked}
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
            <span className={checkedClassName}>Annuel</span>
        </div>
    );
};


export default ToggleButton;