import { CartItemParams, PanierItem } from './../types/model/cart';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import cartRepository from "../repositories/cartRepository";
import { Panier } from "../types/model/cart";
import axios from "axios";
import { Product } from '../types/model/product';

interface CartState {
  cart: Panier | null;
  error: string | null;
  loading: boolean;
  loadingAddItem: boolean;
  errorAddItem: string | null;
  loadingRemoveItem: boolean;
  errorRemoveItem: string | null;
  deleteItemId: number[];
}

const initialState: CartState = {
  cart: null,
  error: null,
  loading: false,
  loadingAddItem: false,
  errorAddItem: null,
  loadingRemoveItem: false,
  errorRemoveItem: null,
  deleteItemId: []
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // Fetch Cart
    fetchCartRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchCartSuccess(state, action: PayloadAction<Panier>) {
      state.cart = action.payload;
      state.loading = false;
    },
    fetchCartFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },

    // Add Cart Item
    addItemRequest(state) {
      state.loadingAddItem = true;
      state.errorAddItem = null;
    },
    addItemSuccess(state,) {
      state.loadingAddItem = false;
    },
    addItemFailure(state, action: PayloadAction<string>) {
      state.errorAddItem = action.payload;
      state.loadingAddItem = false;
    },

    // Remove Cart Item
    removeItemRequest(state, action: PayloadAction<number>) {
      state.loadingRemoveItem = true;
      state.errorRemoveItem = null;
      state.deleteItemId.push(action.payload);
    },
    removeItemSuccess(state, action: PayloadAction<Panier>) {
      state.cart = action.payload;
      state.loadingRemoveItem = false;
      const ids = state.cart?.items.map(e => e.idPanierItem) ?? [];
      const temp = filterList(state.deleteItemId, ids);
      state.deleteItemId = temp;
    },
    removeItemFailure(state, action: PayloadAction<string>) {
      state.errorRemoveItem = action.payload;
      state.loadingRemoveItem = false;

    },
  },
});

// Action creators
export const {
  fetchCartRequest,
  fetchCartSuccess,
  fetchCartFailure,
  addItemRequest,
  addItemSuccess,
  addItemFailure,
  removeItemRequest,
  removeItemSuccess,
  removeItemFailure,
} = cartSlice.actions;

// Thunks for async operations

const saveCartToLocal = (cart: Panier) => {
  localStorage.setItem('cart', JSON.stringify(cart));
};

const saveCartItemToLocal = (cart: CartItemParams[]) => {
  localStorage.setItem('cartItem', JSON.stringify(cart));
};

const getCartFromLocal = (): Panier | null => {
  const cart = localStorage.getItem('cart');
  return cart ? JSON.parse(cart) : null;
};

const getCartItemFromLocal = (): CartItemParams[] | null => {
  const cart = localStorage.getItem('cartItem');
  return cart ? JSON.parse(cart) : null;
};

const clearLocalCart = () => {
  localStorage.removeItem('cart');
  localStorage.removeItem('cartItem');
};


// Fetch Cart
export const fetchCart = (codeUtilisateur: string | null) => async (dispatch: any) => {
  dispatch(fetchCartRequest());
  if (!codeUtilisateur) {
    // User is not logged in, get the cart from localStorage
    const localCart = getCartFromLocal();
    if (localCart) {
      dispatch(fetchCartSuccess(localCart));
    }
    return;
  }


  try {

    // Check if there's a local cart to sync
    const localCart = getCartItemFromLocal();
    if (localCart) {
      // Sync local cart with the server cart
      // Optionally, merge the items from local cart to the server cart
      localCart.forEach(async (item) => {
        await cartRepository.addCartItem(item); // Add each item to the server
      });
      clearLocalCart(); // Clear the local cart after syncing

    }
    const cart = await cartRepository.getCart(codeUtilisateur);

    dispatch(fetchCartSuccess(cart));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const message = error.response?.data?.detail || "Failed to fetch cart";
      dispatch(fetchCartFailure(message));
    }
  }
};

function filterList<T>(idsToRemove: T[], list2: T[]): T[] {
  return idsToRemove.filter(item => list2.includes(item));
}

// Fonction pour convertir CartItemParams en PanierItem
const convertCartItemParamsToPanierItem = (
  panierItemParams: CartItemParams,
  produitService: Product // Vous devrez obtenir ce produit par une requête ou le passer en paramètre
): PanierItem => {
  return {
    idPanierItem: Date.now(), // Utilisez une valeur unique temporaire
    produitService: produitService,
    supportMaintenance: null, // Vous devrez gérer l'ajout des données réelles si nécessaire
    implementationServices: [], // Idem, gérez les services d'implémentation
    periodeSouscription: {
      idPeriodeSouscription: panierItemParams.idPeriodeSouscription,
      codePeriode: '', // Remplissez avec les données appropriées
      description: '',
      nom: '',
      periode: 0,
    },
    planSouscription: {
      idPlanSouscription: panierItemParams.idPlanSouscription,
      codePlanSouscription: '',
      nom: '',
      prix: panierItemParams.prixTotal, // Utilisez une valeur par défaut pour l'instant
      description: '',
      dateCreation: new Date().toISOString(),
      dateUpdate: new Date().toISOString(),
    },
    codePanierItem: '', // Vous pouvez générer un code ici
    nombreUtilisateurs: panierItemParams.nombreUtilisateurs,
    nombreItems: panierItemParams.nombreItems,
    typeHebergement: panierItemParams.typeHebergement,
    quantite: panierItemParams.quantite,
    siteWeb: panierItemParams.siteWeb,
    prixTotal: panierItemParams.prixTotal,
    dateAjout: new Date().toISOString(),
    urlInstance: panierItemParams.urlInstance
  };
};

// Modifiez votre thunk pour l'ajout d'un item
export const addCartItem = (
  panierItem: CartItemParams,
  produitService: Product, // Vous devez passer le produit ici
  codeUtilisateur: string | null
) => async (dispatch: any) => {
  dispatch(addItemRequest());
  try {
    if (!codeUtilisateur) {
      // Utilisateur non connecté, enregistrer dans localStorage
      let localCart: Panier = getCartFromLocal() || {
        idPanier: Date.now(), // Générer un ID temporaire
        codePanier: "temp_cart", // Générer un code temporaire
        dateCreation: new Date().toISOString(),
        dateUpdate: new Date().toISOString(),
        statut: "PEN", // Statut temporaire
        montantTotal: "0", // Valeur par défaut pour le montant
        items: [], // Initialiser un tableau vide pour les items
      };

      let localCartItem: CartItemParams[] = getCartItemFromLocal() || []

      // Convertir CartItemParams en PanierItem
      const panierItemConverted = convertCartItemParamsToPanierItem(panierItem, produitService);


      localCart.items = localCart.items.filter(item => item.produitService.nom !== panierItemConverted.produitService.nom);
      localCartItem = localCartItem.filter(item => item.idProduitService !== panierItemConverted.produitService.idProduitService);


      localCart.items.push(panierItemConverted);

      localCart.montantTotal = localCart.items.reduce((total, item) => {
        return total + (item.prixTotal) || 0; // Assurez-vous de convertir le prix en nombre
      }, 0).toFixed(2);

      localCartItem.push(panierItem)
      saveCartToLocal(localCart);
      saveCartItemToLocal(localCartItem)
      dispatch(addItemSuccess());
    } else {
      // Utilisateur connecté, ajouter via l'API
      await cartRepository.addCartItem(panierItem);
      dispatch(addItemSuccess());
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const message =
        error.response?.data?.detail || "Failed to add item to cart";
      dispatch(addItemFailure(message));
    }
  }
};

// Remove Cart Item
export const removeCartItem = (
  codeUtilisateur: string | null,
  itemId: number
) => async (dispatch: any) => {
  dispatch(removeItemRequest(itemId));

  try {
    if (!codeUtilisateur) {
      // Utilisateur non connecté, supprimer l'élément du panier local
      let localCart: Panier | null = getCartFromLocal();
      let localCartItem: CartItemParams[] | null = getCartItemFromLocal();

      if (localCart && localCartItem) {
        // Filtrer les éléments du panier pour retirer celui avec l'ID correspondant
        localCart.items = localCart.items.filter(item => item.idPanierItem !== itemId);
        localCartItem = localCartItem.filter(item => item.idProduitService !== itemId);


        localCart.montantTotal = localCart.items.reduce((total, item) => {
          return total + (item.prixTotal) || 0; // Assurez-vous de convertir le prix en nombre
        }, 0).toFixed(2);

        // Sauvegarder les mises à jour dans localStorage
        saveCartToLocal(localCart);
        saveCartItemToLocal(localCartItem);

        dispatch(removeItemSuccess(localCart));
      }
    } else {
      // Utilisateur connecté, supprimer l'élément du panier via l'API
      const updatedCart = await cartRepository.removeItem(codeUtilisateur, itemId);
      dispatch(removeItemSuccess(updatedCart));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const message = error.response?.data?.detail || "Failed to remove item from cart";
      dispatch(removeItemFailure(message));
    }
  }
};


export default cartSlice.reducer;
