// src/repositories/productRepository.ts
import {  CategorizedProducts, Product } from '../types/model/product';
import productService from '../services/products';

const productRepository = {
  fetchProductsGroupedByCategory: async (): Promise<CategorizedProducts[]> => {
    const products = await productService.getProducts();
    
    const categorizedProductsMap: { [key: number]: CategorizedProducts } = {};

    products.filter(product =>!product.visible).forEach((product) => {
      const categoryId = product.categorie.idCategorieProduit;
      if (!categorizedProductsMap[categoryId]) {
        categorizedProductsMap[categoryId] = {
          categorie: product.categorie,
          products: [],
        };
      }

      categorizedProductsMap[categoryId].products.push(product);
    });

    return Object.values(categorizedProductsMap);
  },
  getProductByName: async (name:string): Promise<Product> => {
    try {
      const response = await productService.getProductByName(name);
    return response;
    } catch (error) {
      console.error('get product', error)
      throw error
    }
  },

};

export default productRepository;





