import React from "react";
import { Outlet } from "react-router-dom";
import Breadcrumbs from "./breadcrumbs";
import SideNav from "./sidebar";

export default function MeLayout() {
    //px-4 lg:px-16 xl:px-48
    return (
        <div className="bg-white flex flex-col items-center justify-center w-full h-full " >
            <div className="pt-8 sm:pt-0 md:pt-0 lg:pt-16 xl:pt-4  container px-4  xl:px-16 2xl:px-0  w-full">
                <div className="h-36 px-4 w-full bg-gray-50 flex items-center">
                    <Breadcrumbs></Breadcrumbs>
                </div>
                <div className="flex min-h-screen flex-col md:flex-row md:divide-x">
                    <div className="w-full flex-none md:w-64  rounded-l-lg lg:pr-4">
                        <SideNav />
                    </div>
                    <div className=" lg:pl-8 flex flex-grow ">

                        <div className="h-full w-full ">
                            <Outlet />
                        </div>

                    </div>
                </div>

            </div>        </div>
    );
}
