"use client";

import React, { useEffect, useState } from "react";

import { motion } from "framer-motion";
import { FlowComponent, FlowSkeletonComponent } from "./offer";
import ImageMotion from "../../commons/wrappers/imageMotion";
import { H2 } from "../../commons/h_component";
import { Service } from "../../../types/model/product";
import serviceRepository from "../../../repositories/serviceRepository";
import { Images } from "../../../constant";
import { DataModel } from "../../../types/model/data";


const skeletons = ["1", "2", "ss", "dmd"]

const flowList: DataModel[] = [
  {
    title: "CloudApps Odoo",
    image: Images.odoo,
    description: "CloudApps Solutions vous fournit une ou plusieurs instances Odoo à la demande (SaaS), vous permettant de gérer efficacement tous les aspects de votre entreprise.\nNos instances Odoo vous permettront également de bénéficier de la totalité des modules communautaires d'Odoo (CRM, Site Web, Ventes ... ). ",
  },
  {
    title: "CloudApps Glpi",
    image: Images.glpi,
    description: "Application open source pour la gestion du matériel, des logiciels et des centres de données.\nElle facilite également l'organisation de votre support/maintenance, la gestion des incidents/demandes, la création de formulaires, la définition des accords de niveau de service et la fourniture de la meilleure expérience à vos clients ",
  },
  {
    title: "CloudApps Consulting",
    image: Images.cloudConsulting,
    description: "Bénéficiez des conseils et de l'expertise d'une équipe hautement qualifiée dans les domaines de l'architecture de la sécurité des systèmes d'informations, du DevOps/DevSecOps, ou de l'adoption de la démarche Agile à l'échelle. Nous vous proposons également une démarche et un plan d'action pour la maturation de votre DSI, quelle que soit la taille de votre organisation. Pour vos projets de modernisation et de migration de vos applications et charges de travail vers le Cloud, les experts de CloudApps se tiennent à vôtre disposition pour propulser votre entreprise vers plus de productivité.  ",
  }
];


export const ListFlowComponent: React.FC = () => {
  const [productServices, setProductServices] = useState<Service[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await serviceRepository.getServices();
        setProductServices(result);
      } catch (error) {
        setError("Failed to fetch products");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) return <section
    id="section-offres"
    className=" md:pt-10  select-none relative h-full w-full flex flex-col h-full w-full items-center justify-center my-4  md:my-16 gap-16 md:gap-8 "
  >
    <ImageMotion
      classNames=""
      Component={<H2 classNames="" Component={"Nos Produits"} />}
    />
    <div className="container relative w-full h-full flex flex-col items-center justify-around  md:px-0 xl:px-16  2xl:px-0 gap-y-0 lg:gap-y-16 lg:gap-8 ">
      {skeletons.map((service, index) => (
        <motion.div
          whileInView={{ opacity: [0, 1] }}
          transition={{ duration: 1, delayChildren: 0.5 }}
          key={`${service}`}
          className="w-full"
        >
          <FlowSkeletonComponent
            alignLeft={index % 2 === 1}
          />
        </motion.div>
      ))}
    </div>
  </section>;
  if (error || productServices.length === 0) return <section
    id="section-offres"
    className=" md:pt-10  relative h-full w-full flex flex-col h-full w-full items-center justify-center my-4  md:my-16 gap-16 md:gap-8 "
  >
    <ImageMotion
      classNames=""
      Component={<H2 classNames="" Component={"Nos Produits"} />}
    />
    <div className="container  relative w-full h-full flex flex-col items-center justify-around  md:px-0 xl:px-16  2xl:px-0 gap-y-0 lg:gap-y-16 lg:gap-8 ">
      {(
        flowList.map((service, index) => (
          <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 1, delayChildren: 0.5 }}
            key={service.title}
          >
            <FlowComponent
              title={service.title}
              description={service.description}
              image={service.image}
              alignLeft={index % 2 === 1}
            />
          </motion.div>
        ))
      )}

    </div>
  </section>;

  return (
    <section
      id="section-offres"
      className=" md:pt-10  relative h-full w-full flex flex-col h-full w-full items-center justify-center my-4  md:my-16 gap-16 md:gap-8 "
    >
      <ImageMotion
        classNames=""
        Component={<H2 classNames="" Component={"Nos Produits"} />}
      />
      <div className="container  relative w-full h-full flex flex-col items-center justify-around  md:px-0 xl:px-16  2xl:px-0 gap-y-0 lg:gap-y-16 lg:gap-8 ">
        {!Array.isArray(productServices) ? (
          <p>No products available</p>
        ) : (
          productServices.map((service, index) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 1, delayChildren: 0.5 }}
              key={service.codeProduitService}
            >
              <FlowComponent
                title={service.nom}
                description={service.description}
                image={service.images[0]?.url as string ?? getImage(service.nom)}
                alignLeft={index % 2 === 1}
              />
            </motion.div>
          ))
        )}

      </div>
    </section>
  );
};



function getImage(name: string) {
  return flowList.filter(cate => cate.title.toLowerCase() === name.toLowerCase())[0].image ?? flowList[0].image
}