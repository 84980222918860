// src/services/productService.ts
import coreApi from './path/core_api';
import { Product } from '../types/model/product';

const productService = {
  getProducts: async (): Promise<Product[]> => {
    const response = await coreApi.get<Product[]>('/products');
    return response.data;
  },

  getProductByName: async (name:string): Promise<Product> => {
    const response = await coreApi.get<Product[]>(`/products/search?nom=${name}`);
    return response.data[0];
  },

};

export default productService;
