import React from 'react'

export const ErrorPage = () => {
    return (
        <div className="w-full h-full flex flex-col lg:flex-row items-center justify-center space-y-16 lg:space-y-0 space-x-8 2xl:space-x-0">
            <div className="w-full lg:w-1/2 flex flex-col items-center justify-center lg:px-2 xl:px-0 text-center">
                <p className="text-7xl md:text-8xl lg:text-9xl font-bold tracking-wider text-gray-300">
                    Oups
                </p>
                <p className="py-4 text-3xl md:text-4xl lg:text-5xl font-bold tracking-wider text-gray-300 mt-2">
                    Nous rencontrons un léger problème.
                </p>
                <p className="text-lg md:text-xl lg:text-xl text-gray-500 my-12">
                    Pourriez-vous rafraîchir la page ? Si le souci persiste, n'hésitez pas à nous contacter. Merci de votre compréhension !
                </p>
            </div>

        </div>

    )
}
