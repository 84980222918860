import clsx from "clsx";
import { styles } from "../styles";
import ProductList from "../components/productList";


const Products = () => {

    return (
        <section className="w-full bg-white h-full flex flex-col items-center justify-center gap-24">
            <div className={clsx(styles.productStyle['page-title-area'], "min-h-64 h-64 md:h-96 w-full bg-gray-100 flex items-center justify-center")}>
                <h3 className="text-4xl lg:text-6xl text-white  font-semibold">Produits</h3>
            </div>

            <ProductList />

        </section>
    );
};

export default Products;

