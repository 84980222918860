import { useEffect, useState } from "react";
import subscriptionRepository from "../repositories/subscriptionRepository"; // Chemin d'importation approprié
import { SubscriptionData } from "../types/model/subscribe_data_model";

const useSubscriptionData = (codeProduit:string,codeItem:string|null) => {
    const [data, setData] = useState<SubscriptionData | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null); // Réinitialiser l'erreur avant de faire l'appel

            try {
                const subscriptionData = await subscriptionRepository.getSubscriptionData(codeProduit.toUpperCase(),codeItem?.toUpperCase()??null);
                setData(subscriptionData);
            } catch (err) {
                setError("Erreur lors de la récupération des données de souscription");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [codeProduit,codeItem]); // L'effet ne s'exécute qu'une fois lors du montage

    return { data, error, loading };
};

export default useSubscriptionData;