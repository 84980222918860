import clsx from "clsx";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getSuffixeServer } from "../constant/functions";
import { RootState } from "../store";
import ModalUrlConfForm from "./subscription/odoo/modal_url_form";

interface Params {
    error: boolean;
    value: string;
    subscriptionType: 'glpi' | 'odoo';  // Type de souscription

}

const InputUrlServer = ({ error, value, subscriptionType }: Params) => {

    const { errorUrl } = useSelector(
        (state: RootState) => state.subscription
    );
    const inputClasses = useMemo(
        () =>
            clsx("appearance-none block flex-grow h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white", {
                "border-red-500": error || errorUrl,
            }),
        [error, errorUrl]
    );

    return (
        <div className="space-y-4 pt-2 md:w-3/4 xl:w-11/12 2xl:w-3/4">
            <label
                htmlFor="urlServer"
                className="block  text-xl font-semibold"
            >
                Url de l'instance {subscriptionType.toLowerCase()} associée
            </label>
            <div className="w-full flex flex-row md:h-12 gap-2 md:gap-4">
                <input
                    type="text"
                    id="urlServer"
                    name="urlServer"
                    value={value}
                    placeholder={`https://{nom_entreprise}${getSuffixeServer({ subscriptionType })}`}

                    disabled
                    className={inputClasses}
                    required
                />


                <ModalUrlConfForm subscriptionType={subscriptionType} />

            </div>
            {errorUrl && <p className="text-sm text-red-500">L'URL est déjà utilisée ou invalide.</p>}

        </div>
    );
};

export default InputUrlServer;
