import { motion } from 'framer-motion';

import React from 'react'
import { BannerAboutComponent } from '../components/about/banner_component';
import { H2 } from '../components/commons/h_component';
import MotionWrap from '../components/commons/motionWrapper';
import ListEmployeComponent from '../components/home/employes/list_employe_component';
import { Images } from '../constant';

const scaleVariants = {
    whileInView: {
        scale: [0.85, 1],
        opacity: [0.5, 1],
        transition: {
            duration: 0.5,
            ease: 'easeInOut'
        }
    }
}



const serviceList = [
    {
        title: "SAAS",
        image: Images.saas,
        description: "Profitez de nos applications cloud robustes, telles que CloudApps Odoo et CloudApps GLPI, conçues pour optimiser vos opérations."
    },
    {
        title: "PAAS",
        image: Images.paas, description: "Développez et déployez vos applications sans souci grâce à nos services tels qu'A WX Server as a Service et Postgres Database as a Service."
    },
    // {
    //     title: "Training",
    //     image: Images.bg2, description: ""
    // },
    {
        title: "Consulting",
        image: Images.consulting1, description: "Bénéficiez de l'expertise de notre équipe dans des domaines clés comme la conception d'architecture cloud, la cybersécurité, et les pratiques DevOps/DevSecOps"
    },
];

export const AboutUs = () => {
    return (
        <div className=' flex flex-col items-center bg-white relative items-center lg:h-full px-4 lg:px-auto '>
            <motion.div

                whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.5 }}
                className=' pt-24 container w-full  h-full flex flex-col px-4  xl:px-16 2xl:px-0 text-center'>

                <section className="relative ">
                    <div className="container w-full px-4  xl:px-16 2xl:px-0 flex flex-col gap-24">
                        {/* <H2 classNames='w-full text-3xl lg:text-5xl font-semibold leading-normal' Component={"Notre Mission"} /> */}
                        <div className="w-full justify-start items-center gap-8 xl:px-24  flex lg:flex-row flex-col">

                            <div className="w-full lg:flex-grow  flex-col justify-start lg:items-start items-center gap-10 inline-flex">
                                <div className="w-full flex-col justify-start lg:items-start items-center gap-16 flex">
                                    <h2 className="text-gray-900 text-3xl xl:text-5xl 2xl:pr-16 font-bold font-manrope leading-normal lg:text-start text-center">Notion Mission</h2>
                                    <p className="text-lg text-base font-normal leading-relaxed lg:text-start text-center">Aider les PME à prospérer grâce à notre expertise et des solutions numériques innovantes.</p>
                                </div>

                            </div>
                            <div className='w-full lg:flex-grow'>
                                <img className="lg:mx-0 mx-auto h-full rounded-3xl object-cover" src={"https://img.freepik.com/photos-gratuite/travail-equipe-soutien-geste-amitie_1262-20464.jpg?ga=GA1.1.1837181882.1709727885&semt=ais_hybrid"} alt="about Us" />
                            </div>
                        </div>
                    </div>
                </section>

            </motion.div>
            <motion.div

                whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.5 }}
                className=' py-24 container w-full  h-full flex flex-col px-4  xl:px-16 2xl:px-0 text-center'>

                <section className="relative ">
                    <div className="container w-full px-4  xl:px-16 2xl:px-0 flex flex-col gap-24">
                        {/* <H2 classNames='w-full text-3xl lg:text-5xl font-semibold leading-normal' Component={"Notre Mission"} /> */}
                        <div className="w-full justify-start items-center gap-8 xl:px-24  flex lg:flex-row flex-col">
                            <div className='w-full lg:flex-grow'>
                                <img className="lg:mx-0 mx-auto h-full rounded-3xl object-cover" src="https://img.freepik.com/vecteurs-libre/illustration-concept-declaration-vision_114360-7576.jpg?t=st=1728227593~exp=1728231193~hmac=fd7ba4bd333a2b6b6c2869b0ebea08ac43cb84148afbe9a187abd28f03cfcb40&w=826" alt="about Us" />
                            </div>
                            <div className="w-full lg:flex-grow  flex-col justify-start lg:items-start items-center gap-10 inline-flex">
                                <div className="w-full flex-col justify-start lg:items-start items-center gap-16 flex">
                                    <h2 className="text-gray-900 text-3xl xl:text-5xl 2xl:pr-16 font-bold font-manrope leading-10 lg:text-start text-center">Quelle est notre <span >vision</span> chez cloudApps ? </h2>
                                    <p className="text-lg text-base font-normal leading-relaxed lg:text-start text-center">Notre vision est d'accompagner les PME dans leur évolution numérique en leur proposant des offres de services Cloud (PaaS/SaaS), et de consulting afin d'optimiser leurs opérations et moderniser leurs applications. Grâce à notre expertise nous offrons des conseils personnalisés et un support de qualité, gages de la satisfaction de nos clients, tout en plaçant l'innovation au cœur de chaque projet.</p>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>

            </motion.div>

            <motion.div
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 1 }}
                className=''
            >
                <BannerAboutComponent />
            </motion.div>
            <motion.div

                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 0.5, delayChildren: 0.5 }}

                className='container  px-4  xl:px-16 2xl:px-0  my-24 h-full  w-full' >
                <div className='relative flex flex-col w-full h-full gap-16 justify-center items-start '>

                    <div className="w-full py-4 flex items-center justify-center">
                        <H2 classNames='' Component={'Nos Services'} />
                    </div>
                    <div className='flex'>
                        <div className='hidden sm:flex w-1/12'></div>
                        <div className=" w-full h-full grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 2xl:grid-cols-3  gap-8 2xl:gap-x-24 gap-y-8">
                            {serviceList.map((product, i) => {

                                return (
                                    <motion.div
                                        whileInView={{ opacity: [0, 1] }}
                                        transition={{ duration: 0.5 }}
                                        key={`${product.title}i`}
                                    >
                                        <ServiceComponent image={product.image} title={product.title} description={product.description} />
                                    </motion.div>
                                );
                            })}
                        </div>
                        <div className='w-1/12  hidden sm:flex'></div>
                    </div>
                </div>
            </motion.div>

            <MotionWrap
                classNames="h-full w-full pt-8 sm:pt-0"
                Component={<ListEmployeComponent />}
            />
            <div className='py-24'></div>
        </div>
    )
}





const ServiceComponent = ({ title, description, image, styles }: {
    title: string,
    image: string,
    description: string, styles?: string
}) => {
    return (
        <motion.div
            whileInView={scaleVariants.whileInView}
            transition={{
                ease: "linear",
                duration: 0.5,
                x: { duration: 0.5 }
            }}
            className={`${'shadow hover:shadow-lg rounded-t-lg hover:bg-gray-50 rounded  flex flex-col   gap-8 '}`}>
            <div className='w-full h-56 rounded-lg bg-gray-100 flex items-center justify-center'>
                <img src={image} alt={title} className='object-contain h-full' />
            </div>
            <div className='p-4 flex flex-col justify-center items-center gap-4'>
                <p className="font-semibold text-2xl md:text-4xl pr-8 leading-10">
                    {title}
                </p>
                <p className="text-base font-base text-center transition transition-all duration-500">
                    {description}
                </p>   </div>
        </motion.div >
    )
}

