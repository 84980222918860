import React, { useCallback } from "react";
import { cameroonCities } from "../../constant/constant";
import { useFormContext } from "../../context/formContext";


interface FormSubscriptionProps {
  onNext: any;
  onReset: any;
  onPrevious: any;
}

const FormSubscription: React.FC<FormSubscriptionProps> = ({
  onNext,
  onReset,
  onPrevious,

}) => {
  const { formData, setFormData } = useFormContext();


  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    },
    [setFormData]
  );


  const handleChangeCity = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    },
    [setFormData]
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onNext(formData);
  };



  const handleReset = (e: any) => {
    onReset(e);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="container flex flex-col gap-0 lg:gap-6 w-full"
    >
      <button
        onClick={onPrevious}
        className="w-36 text-gray-700 pb-4 font-bold text-start underline underline-offset-4"
      >
        Précédent
      </button>
      <div className="flex flex-wrap -mx-3  transition duration-300 ease-in-out">

        <div className="w-full md:w-1/2 px-3 mb-6 lg:mb-0">
          <label
            htmlFor="lastName"
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-3"
          >
            Nom de famille
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="appearance-none block w-full h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            required
          />
        </div>
        <div className="w-full md:w-1/2 px-3 mb-6 lg:mb-0">
          <label
            htmlFor="firstName"
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-3"
          >
            Prénom
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="appearance-none block w-full h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white "
            required
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 transition duration-300 ease-in-out">
        <div className="w-full md:w-1/2 px-3 mb-6 lg:mb-0">
          <label
            htmlFor="company"
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-3"
          >
            Entreprise
          </label>
          <input
            type="text"
            id="company"
            name="company"
            value={formData.company}
            onChange={handleChange}
            className="appearance-none block w-full h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            required
          />
        </div>
        <div className="w-full md:w-1/2 px-3 mb-6 lg:mb-0">
          <label
            htmlFor="email"
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-3"
          >
            E-mail
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="appearance-none block w-full h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            required
          />
        </div>
      </div>

      <div className="flex flex-wrap -mx-3  transition duration-300 ease-in-out">

        <div className="w-full md:w-2/5 px-3 mb-6 lg:mb-0">
          <label
            htmlFor="country"
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-3"
          >
            Pays
          </label>
          <input
            type="text"
            id="country"
            name="country"
            disabled
            value={formData.country}
            onChange={handleChange}
            className="appearance-none bg-gray-50  w-full h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white  transition ease-in-out duration-500"
            required
          />
        </div>
        <div className="w-full md:w-3/5 px-3 mb-6 lg:mb-0">
          <label
            htmlFor="city-select"
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-3"
          >
            Ville
          </label>

          <select id="city-select"
            value={formData.city}
            onChange={handleChangeCity}
            name="city"
            className="appearance-none block w-full h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none bg-white "
          >
            {cameroonCities.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>
      </div>


      <div className="flex flex-wrap -mx-3  transition duration-300 ease-in-out">
        <div className="w-full md:w-1/2 px-3 mb-6 lg:mb-0">
          <label
            htmlFor="postalCode"
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-3"
          >
            Code Postal
          </label>
          <input
            type="text"
            id="postalCode"
            name="postalCode"
            value={formData.postalCode}
            onChange={handleChange}
            className="appearance-none block w-full h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white "
            required
          />
        </div>
        <div className="w-full md:w-1/2 px-3 mb-6 lg:mb-0">
          <label
            htmlFor="address"
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-3"
          >
            Adresse
          </label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="appearance-none block w-full h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            required
          />
        </div>
      </div>

      <div className="w-full text-md  md:text-lg  relative  pt-6 flex gap-6  items-center justify-between">
        <button
          onClick={handleReset}
          className="h-14 w-36  shadow bg-gray-500  sm:py-2  focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded-lg"
        >
          Reset
        </button>
        <button
          type="submit"
          className="h-14  w-48 shadow bg-primary-500  sm:py-2   transition ease-in duration-300 hover:ease-out hover:bg-blue-500  focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded-lg"
        >
          Suivant
        </button>
      </div>
    </form>
  );
};

export default FormSubscription;
