import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faBan, faCheck, faCloud, faHourglassEnd, faNetworkWired, faServer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import React, { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { PanierItem } from '../../types/model/cart';
import { Order, Status } from '../../types/model/order';


export const OrdersTable = ({ orders, cancelOrder }: { orders: Order[], cancelOrder: (code: string) => void }) => {
    return (
        <div className="mt-6 flow-root sm:mt-8">
            <div className="divide-y divide-gray-200">
                {orders.map((order, index) => (
                    <OrderComponent key={order.codeCommande + order.statut} order={order} cancelOrder={cancelOrder} />
                ))}
            </div>
        </div>
    );
};


export const OrderComponent = ({ order, cancelOrder }: { order: Order, cancelOrder: (code: string) => void }) => {
    const statut = useMemo(() => order.statut, [order])
    // Utilisation de useCallback pour éviter la recréation de la fonction
    const orderDate = useCallback((dateString: string): string => {
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    }, []);

    // Utilisation de useMemo pour mémoriser les valeurs calculées
    const { statusLabel, icon, statusColorClass } = useMemo(() => {
        let icon = faCheck;
        let statusLabel = 'Valider';
        let statusColorClass = 'bg-green-100 text-green-800';

        switch (statut) {
            case Status.CANCELLED:
                icon = faBan;
                statusLabel = 'Annuler';
                statusColorClass = 'bg-red-100 text-red-800';
                break;
            case Status.PENDING:
                icon = faClock;
                statusLabel = 'En attente';
                statusColorClass = 'bg-yellow-100 text-yellow-800';
                break;
            case Status.EXPIRED:
                icon = faHourglassEnd;
                statusLabel = 'Expirer';
                statusColorClass = 'bg-gray-100 text-gray-500';
                break;

            default:
                break;
        }

        return { statusLabel, icon, statusColorClass };
    }, [statut]);

    const handleChange = useCallback(
        () => {
            cancelOrder(order.codeCommande)
        },
        [cancelOrder, order]
    )

    return (
        <div className="flex flex-wrap items-center gap-y-4 py-6">
            <dl className="w-1/2 sm:w-1/4 lg:w-auto lg:flex-1">
                <dt className="text-base font-medium text-gray-500 ">ID Commande:</dt>
                <dd className="mt-1.5 text-base font-semibold text-gray-900 ">
                    <span className="hover:underline">
                        #{order.codeCommande}
                    </span>
                </dd>
            </dl>
            <dl className="w-1/2 sm:w-1/4 lg:w-auto lg:flex-1">
                <dt className="text-base font-medium text-gray-500 ">Date:</dt>
                <dd className="mt-1.5 text-base font-semibold text-gray-900 ">
                    {orderDate(order.dateCommande)}
                </dd>
            </dl>
            <dl className="w-1/2 sm:w-1/4 lg:w-auto lg:flex-1">
                <dt className="text-base font-medium text-gray-500 ">Prix:</dt>
                <dd className="mt-1.5 text-base font-semibold text-gray-900 ">
                    {order.montant} CFA
                </dd>
            </dl>
            <dl className="w-1/2 sm:w-1/4 lg:w-auto lg:flex-1">
                <dt className="text-base font-medium text-gray-500 ">Statut:</dt>
                <dd className={clsx("me-2 mt-1.5 inline-flex items-center rounded px-2.5 py-0.5 text-xs font-medium", statusColorClass)}>
                    <FontAwesomeIcon icon={icon} className="size-3 mr-1" />
                    {statusLabel}
                </dd>
            </dl>
            {(statut === Status.VALIDATED || statut === Status.PENDING) && <div className="w-full grid sm:grid-cols-2 lg:flex lg:w-64 lg:items-center lg:justify-end gap-4">
                {order.statut === Status.PENDING && <CancelModal cancelOrder={handleChange} />}
                <OrderDetail order={order} isValidate={statut === Status.VALIDATED} cancelOrder={handleChange} />
            </div>}
        </div>
    );
};


interface CancelModalProps {
    cancelOrder: () => void;
    triggerButton?: ReactNode; // Permet de passer un composant personnalisé pour ouvrir le modal
}

const CancelModal = ({ cancelOrder, triggerButton }: CancelModalProps) => {

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);


    const handleSubmit = () => {

        cancelOrder();
        closeModal();
    }

    return (
        <>
            {/* Modal toggle */}
            <div className="flex justify-center m-5">
                {triggerButton ? (
                    <button onClick={openModal}>{triggerButton}</button> // Utilisation du bouton personnalisé si fourni
                ) : (
                    <button
                        onClick={openModal}
                        type="button"
                        className="w-full transition-all duration-300 rounded-lg border border-red-700 px-3 py-2 text-center text-sm font-medium text-red-700 hover:bg-red-700 hover:text-white focus:outline-none focus:ring-4 focus:ring-red-300 lg:w-auto"
                    >
                        Annuler
                    </button>
                )}
            </div>

            {/* Main modal */}
            {isOpen && (
                <div
                    tabIndex={-1}
                    aria-hidden="true"
                    className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50"
                >
                    <div className="relative p-4 w-full max-w-md h-auto">
                        {/* Modal content */}
                        <div className="relative p-4 text-center bg-white rounded-lg shadow sm:p-5">
                            <button
                                type="button"
                                onClick={closeModal}
                                className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                            >
                                <svg
                                    aria-hidden="true"
                                    className="w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <svg
                                className="text-gray-400 w-11 h-11 mb-3.5 mx-auto"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <p className="mb-4 text-gray-500 ">
                                Êtes-vous sûr de vouloir annuler cette commande ?
                            </p>
                            <div className="flex justify-center items-center space-x-4">
                                <button
                                    onClick={closeModal}
                                    className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10"
                                >
                                    Non, abandonner
                                </button>
                                <button
                                    onClick={handleSubmit}
                                    className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 "
                                >
                                    Oui, je suis sûr
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}




const OrderDetail = ({ cancelOrder, order, isValidate }: { order: Order, isValidate: boolean, cancelOrder: () => void }) => {
    const [isOpen, setIsOpen] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    const handleClickOutside = useCallback(
        (e: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
                closeModal();
            }
        },
        [],
    );

    const handleSubmit = () => {

        cancelOrder();
        closeModal();
    }

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);



    return (
        <>
            {/* Modal toggle */}
            <div className="flex justify-center">
                <button
                    type="button"
                    onClick={openModal}
                    className="w-full transition-all duration-300 inline-flex justify-center rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 lg:w-auto"
                    style={{ whiteSpace: 'nowrap' }} // Ajout de la propriété pour empêcher les retours à la ligne
                >
                    Voir details
                </button>


            </div>

            {/* Main modal */}
            {isOpen && (
                <div

                    tabIndex={-1}
                    aria-hidden="true"
                    className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50"
                >
                    <div className="relative p-4 w-full max-w-5xl h-auto" ref={modalRef}>
                        {/* Modal content */}
                        <section className="bg-white py-4 rounded-lg relative">
                            <div className="w-full max-w-5xl px-4 md:px-5 lg-6 mx-auto">
                                <div className='my-4 mb-8 flex justify-between'>
                                    <div></div>
                                    <h2 className="font-manrope font-bold text-4xl leading-10 text-black text-center">
                                        Detail de la commande
                                    </h2>
                                    <div>
                                        <button onClick={closeModal} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center">
                                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                            </svg>
                                            <span className="sr-only">Close modal</span>
                                        </button>
                                    </div>

                                </div>

                                <div className="main-box border border-gray-200 rounded-xl pt-6 max-w-xl max-lg:mx-auto lg:max-w-full">
                                    <div className="flex flex-col lg:flex-row lg:items-center justify-between px-6 pb-6 border-b border-gray-200">
                                        <div className="data">
                                            <p className="font-semibold text-base leading-7 text-black">
                                                Code Commande:{" "}
                                                <span className="text-indigo-600 font-medium">#{order.codeCommande}</span>
                                            </p>
                                            {isValidate && <p className="font-semibold text-base leading-7 text-black mt-4">
                                                Date de Payment :{" "}
                                                <span className="text-gray-400 font-medium"> 18th march 2021</span>
                                            </p>}
                                        </div>
                                        <div></div>
                                    </div>
                                    <div className="w-full px-3 min-[400px]:px-6">

                                        {(order.panier.items && order.panier.items.length > 0)
                                            && order.panier.items.map((item) => <Item key={item.codePanierItem} isReady={isValidate} item={item} />)}
                                    </div>
                                    <div className="w-full border-t border-gray-200 px-6 flex flex-col lg:flex-row items-center justify-between ">
                                        <div className="flex flex-col sm:flex-row items-center max-lg:border-b border-gray-200">
                                            {!isValidate &&

                                                <CancelModal triggerButton={
                                                    <div className="flex outline-0 py-6 sm:pr-6  sm:border-r border-gray-200 whitespace-nowrap gap-2 items-center justify-center font-semibold group text-lg text-black bg-white transition-all duration-500 hover:text-indigo-600">
                                                        <svg
                                                            className="stroke-black transition-all duration-500 group-hover:stroke-indigo-600"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={22}
                                                            height={22}
                                                            viewBox="0 0 22 22"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M5.5 5.5L16.5 16.5M16.5 5.5L5.5 16.5"
                                                                stroke=""
                                                                strokeWidth="1.6"
                                                                strokeLinecap="round"
                                                            />
                                                        </svg>
                                                        Annuler
                                                    </div>} cancelOrder={handleSubmit} />
                                            }

                                        </div>
                                        <p className="font-semibold text-lg text-black py-6">
                                            Prix Total: <span className="text-indigo-600"> {order.montant} CFA</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div >
            )}
        </>
    );
}



const icons = [faCloud, faServer, faNetworkWired];

const Item = ({ item, isReady }: { item: PanierItem, isReady: boolean }) => {

    const icon = useMemo(() => {
        const slug = item.produitService.categorie.slug.toLowerCase();
        if (slug === "paas") return 0;
        if (slug === "saas") return 1;
        return 2;
    }, [item.produitService.categorie.slug]);

    // const itemDate = useCallback((dateString: string): string => {
    //     const date = new Date(dateString);
    //     return date.toLocaleDateString('fr-FR', {
    //         day: '2-digit',
    //         month: 'long',
    //         year: 'numeric'
    //     });
    // }, []);
    return (
        <div className="flex flex-col lg:flex-row items-center py-6 border-b border-gray-200 gap-6 w-full">
            <div className="img-box max-lg:w-full">


                <FontAwesomeIcon icon={icons[icon]} className="size-24 text-gray-500" />
            </div>
            <div className="flex flex-row items-center w-full ">
                <div className="flex flex-col lg:flex-row w-full gap-x-8">
                    <div className="items-center">
                        <div className="">
                            <h2 className="font-semibold text-xl leading-8 text-black mb-3">
                                {item.produitService.nom}
                            </h2>
                            <p className="font-normal text-lg leading-8 text-gray-500 mb-3 ">
                                {item.planSouscription.nom}
                            </p>
                            <div className="flex flex-col text-sm">
                                {item.implementationServices && <p className="font-medium  leading-7 pr-4 mr-4">
                                    Support de Mise en Oeuvre
                                </p>}
                                {item.supportMaintenance && <p className="font-medium  leading-7 text-black ">
                                    Service Maintenance: <span className="text-gray-500">max {item.supportMaintenance.nombreTickets} tickets/mois</span>
                                </p>}
                                {item.siteWeb && <p className="font-medium  leading-7  ">
                                    Site Web
                                </p>}
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2  lg:flex-grow">
                        <div className="col-span-2 lg:col-span-1 flex items-center max-lg:mt-3">
                            <div className="flex gap-3 lg:block">
                                <p className="font-medium text-sm leading-7 text-black">
                                    Prix
                                </p>
                                <p className="lg:mt-2 font-medium text-sm leading-7 text-indigo-600">
                                    {item.prixTotal} CFA
                                </p>
                            </div>
                        </div>
                        <div className="col-span-2 lg:col-span-1 flex items-center max-lg:mt-3 ">
                            <div className="flex gap-3 lg:block">
                                <p className="font-medium text-sm leading-7 text-black">
                                    Statut
                                </p>
                                <p className={clsx("font-medium text-sm leading-6 whitespace-nowrap py-0.5 px-3 rounded-full lg:mt-3 ", {
                                    "bg-emerald-50 text-emerald-600": isReady

                                }, { "bg-yellow-100 text-yellow-600": !isReady })}>
                                    {isReady ? "Pret " : "En Attente"}
                                </p>
                            </div>
                        </div>
                        {/* <div className="col-span-3 lg:col-span-1 flex items-center max-lg:mt-3">
                            <div className="flex gap-3 lg:block">
                                <p className="font-medium text-sm whitespace-nowrap leading-6 text-black">
                                    Expected Delivery Time
                                </p>
                                <p className="font-medium text-base whitespace-nowrap leading-7 lg:mt-3 text-emerald-500">
                                    {itemDate(item.dateAjout)}
                                </p>
                            </div>
                        </div> */}
                    </div>

                </div>
            </div>
        </div >
    );
}