import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import coreApi from '../services/path/core_api';
import { Adresse } from '../types/model/adresseModel';



// Interface pour le contexte
interface AdresseContextType {
    adresses: Adresse[];
    loading: boolean;
    error: string | null;
    fetchAdresses: (codeUtilisateur: string) => void;
    createAdresse: (adresse: Adresse) => void;
    updateAdresse: (adresse: Adresse) => void;

}

// Création du contexte
const AdresseContext = createContext<AdresseContextType | undefined>(undefined);

// Hook pour utiliser le contexte
export const useAdresseContext = () => {
    const context = useContext(AdresseContext);
    if (!context) {
        throw new Error('useAdresseContext doit être utilisé dans un AdresseProvider');
    }
    return context;
};

// Provider pour le contexte
export const AdresseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [adresses, setAdresses] = useState<Adresse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Fonction pour récupérer la liste des adresses
    const fetchAdresses = useCallback(async (codeUtilisateur: string) => {
        setLoading(true);
        setError(null);

        // Vérifier si les adresses existent dans le localStorage
        const cachedAdresses = localStorage.getItem('adresses');
        if (cachedAdresses) {
            setAdresses(JSON.parse(cachedAdresses));
            setLoading(false);
        } else {
            try {
                // Appel API pour récupérer les adresses
                const response = await coreApi.get(`/adresses`);
                const fetchedAdresses = response.data;

                setAdresses(fetchedAdresses);
                // Sauvegarder dans le localStorage
                localStorage.setItem('adresses', JSON.stringify(fetchedAdresses));
            } catch (err) {
                setError("Erreur lors de la récupération des adresses.");
            } finally {
                setLoading(false);
            }
        }
    }, []);

    const createAdresse = useCallback(async (adresse: Adresse) => {
        setLoading(true);
        setError(null);

        try {
            // Appel API pour récupérer les adresses


            await coreApi.post(`/adresses/create-adresse/`, adresse);


            const response = await coreApi.get(`/adresses`);
            const fetchedAdresses = response.data;
            setAdresses(fetchedAdresses);

            // Sauvegarder dans le localStorage
            localStorage.setItem('adresses', JSON.stringify(fetchedAdresses));
        } catch (err) {
            setError("Erreur lors de la creations de l'adresse.");
        } finally {
            setLoading(false);
        }

    }, []);

    const updateAdresse = useCallback(async (adresse: Adresse) => {
        setLoading(true);
        setError(null);

        try {
            // Appel API pour récupérer les adresses


            await coreApi.put(`/adresses/update-adresse/`, adresse);


            const response = await coreApi.get(`/adresses`);
            const fetchedAdresses = response.data;
            setAdresses(fetchedAdresses);

            // Sauvegarder dans le localStorage
            localStorage.setItem('adresses', JSON.stringify(fetchedAdresses));
        } catch (err) {
            setError("Erreur lors de la creation de l'adresse.");
        } finally {
            setLoading(false);
        }

    }, []);

    useEffect(() => {
        // Clear adresses from localStorage when user changes (if needed)
        return () => {
            localStorage.removeItem('adresses');
        };
    }, []);

    // Fournir les adresses et les méthodes via le contexte
    return (
        <AdresseContext.Provider value={{ adresses, loading, error, fetchAdresses, createAdresse, updateAdresse }}>
            {children}
        </AdresseContext.Provider>
    );
};
