import React, { useId, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { dailyCost, initialLocation } from '../../constant/constant';
import { calculateCostDepla, getMaintenanceByType } from '../../constant/functions';
import { RootState } from '../../store';
import { MaintenanceProp } from '../../types/model/maintenance_prop';

interface ModalProps {
    isOpen: boolean;
    toggleModal: (e: any) => void;
    maintenances: MaintenanceProp[]

}

const ModalOdooSubscription: React.FC<ModalProps> = ({ isOpen, toggleModal, maintenances }) => {

    const {
        hostingType,
        website,
        numberOfItem,
        selectedProducts,

        numUsers,
        typePlan,
        location,
        typeMaintenance,
        totalPrice, numberInstallationDays,
        supportImplementation,
    } = useSelector((state: RootState) => state.subscription);

    const numberOfEmployees = numberOfItem;

    const maintenanceType = useMemo(() => getMaintenanceByType(typeMaintenance, maintenances), [typeMaintenance,maintenances])

    const deplacementForfait = useMemo(
        () => calculateCostDepla(location ?? initialLocation, numberInstallationDays ?? 3),
        [location, numberInstallationDays])

    const id = useId()

    if (!isOpen) return null;


    return (
        <div
            className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 overflow-y-auto overflow-x-hidden"
            aria-hidden="true"
        >
            <div className="relative p-4 w-full max-w-4xl max-h-full">
                <div className="relative bg-white rounded-lg shadow  px-6">
                    {/* Modal header */}
                    <div className="w-full flex flex-row items-center justify-between py-4 md:py-5  rounded-t ">
                        <div className='hidden md:block min-w-16 '></div>
                        <div className='min-w-16  '>
                            <h3 className="text-2xl font-semibold text-gray-900 text-center">
                                Détail de la souscription
                            </h3>
                        </div>
                        <div className=''>
                            <button
                                type="button"
                                onClick={toggleModal}
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            >
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                    </div>


                    {/* Modal body */}
                    <div className=" py-4 md:py-5">
                        <div className="space-y-4 text-lg leading-10  text-gray-800">
                            <div className='w-full flex flex-wrap justify-between'>
                                <div className='w-full md:w-1/2 flex flex-col gap-8'>
                                    <div className=''>
                                        <span className="text-xl leading-10 font-semibold underline underline-offset-4">Abonnement</span>

                                        <div className='px-8'>
                                            <ul className='list-outside list-disc'>
                                                <li>
                                                    <span>Instance Cloudapps Odoo </span>
                                                    <div className='w-full flex flex-col '>
                                                        <ul className='list-outside px-4 leading-8'>
                                                            <li>Type de plan : <span className='font-bold capitalize'>{typePlan}</span></li>
                                                            <li>Nombre d'utilisateur(s) : <span className='font-bold'>{numUsers}</span> </li>
                                                        </ul>
                                                    </div>

                                                </li>
                                                {
                                                    maintenanceType && (
                                                        <li>
                                                            <div className='flex flex-col w-full leading-8'>
                                                                <span className='font-medium'>Maintenace {maintenanceType.type}<span className='font-normal text-base'> ({maintenanceType.price} FCFA)</span></span>
                                                                <span className='px-4 '> {maintenanceType.value}  </span>
                                                            </div>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                    {
                                        supportImplementation && (
                                            <div>
                                                <span className="text-xl leading-10 font-semibold underline underline-offset-2">Installation</span>
                                                <div className='px-4'>
                                                    <span className='font-medium'>Mise en Oeuvre</span>
                                                    <ul className=' px-8 list-disc list-outside'>
                                                        {selectedProducts.map((product: any, index: any) => {

                                                            return (
                                                                <li key={`${id}-${index}`}>{product}</li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>


                                            </div>
                                        )
                                    }
                                </div>
                                <div className='w-full md:w-1/2'>
                                    <span className="text-xl leading-10 font-semibold underline underline-offset-2">Forfait</span>

                                    <div className='px-4'>
                                        <span className='font-medium'>Hebergement ({hostingType})</span>
                                        <ul className='px-8 list-outside list-disc'>
                                            <li>{numberOfEmployees} employes</li>
                                            {
                                                hostingType !== 'cloud' &&
                                                (
                                                    <><li>Localisation : <span className='capitalize'>{location}</span></li>
                                                        <li>Tarif journalier : <span className="font-bold" >{dailyCost} FcFa</span></li>
                                                        <li>Nombres de jours : <span className="font-bold" > {numberInstallationDays} Jours</span></li>
                                                        <li>Forfait de déplacement : <span className="font-bold" > {deplacementForfait}</span></li>
                                                    </>
                                                )
                                            } </ul>
                                    </div>
                                    {
                                        website && (
                                            <div className='px-4'>
                                                <span className='font-medium'>Site Web</span>

                                            </div>
                                        )
                                    }


                                </div>


                                <div className='mt-16'>
                                    <span>Coût Total : </span>
                                    <span className='font-semibold'>{totalPrice} FCFA</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ModalOdooSubscription;