import { CartItemParams, Panier, PanierItem } from "../types/model/cart";
import coreApi from "./path/core_api";

const cartService = {
    getCart: async (codeUtilisateur: string): Promise<Panier> => {
        try {
            const response = await coreApi.get<Panier>(`/paniers/search?codeUtilisateur=${codeUtilisateur}`);
            return response.data as Panier;
        } catch (error) {
            console.error("Error fetching cart:", error); // Log the error for debugging
            throw error; // Re-throw the error for handling in the calling function
        }
    },

    removeItemFromCart: async (itemId: number): Promise<Panier> => {
        try {
            const response = await coreApi.delete(`/panier-items/${itemId}/`);
            return response.data; // Return the updated cart
        } catch (error) {
            console.error("Error removing item from cart:", error); // Log the error
            throw error; // Re-throw the error for handling in the calling function
        }
    },

    // New method to add a PanierItem to the cart
    addCartItem: async (panierItem: CartItemParams): Promise<PanierItem> => {
        try {
            const response = await coreApi.post<PanierItem>('/panier-items/', panierItem);
            return response.data; // Return the updated cart
        } catch (error) {
            console.error("Error adding item to cart:", error); // Log the error
            throw error; // Re-throw the error for handling in the calling function
        }
    }
};

export default cartService;
