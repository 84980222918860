import React from 'react'
import { Images } from '../constant'

export const NotFound = () => {
    return (
        <div className="min-h-screen w-full flex items-center justify-center">
            <div className='container w-full h-full px-4 xl:px-16 2xl:px-0 md:flex '>
                <div className="flex items-center justify-center w-full py-10  md:w-1/2">
                    <div className="max-w-2xl ">
                        <div className="text-5xl text-center sm:text-start font-black text-gray-800 md:text-8xl">404 </div>
                        <div className="w-16 h-1 my-3 bg-primary md:my-6" />
                        <p className="text-2xl font-light leading-normal text-gray-600 md:text-3xl">
                            Le contenu que vous recherchez n’existe pas. Soit il a été supprimé, soit vous avez mal saisi le lien.{" "}
                        </p>
                        <p className="py-6 font-light leading-normal text-xl text-gray-400 md:text-2xl ">Désolé pour ça ! Veuillez visiter notre page d'accueil pour arriver là où vous devez aller.</p>
                        <a href="/">
                            <button className="px-4 py-2 mt-4 text-lg text-gray-600 transition-colors duration-300 transform border rounded-lg hover:bg-gray-100 focus:outline-none">
                                Aller à l'accueil
                            </button>
                        </a>
                    </div>
                </div>
                <div className="relative w-full pb-full md:flex md:pb-0 md:min-h-screen md:w-1/2">
                    <div className="flex items-center w-full h-full max-w-4xl mx-auto">
                        <img
                            className="object-cover w-full"
                            src={Images.error404}
                            alt="Error"
                        />
                    </div>
                </div>
            </div>
        </div>

    )
}
