import { faBan, faCheck, faClock, faHourglassEnd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { Transaction, StatutPaiement } from '../../types/model/transaction';


export const TransactionsTable = ({ transactions, cancelTransaction }: { transactions: Transaction[], cancelTransaction: (code: string) => void }) => {
    return (
        <div className="mt-6 flow-root sm:mt-8">
            <div className="divide-y divide-gray-200">
                {transactions.map((transaction) => (
                    <TransactionComponent key={transaction.codePaiement + transaction.statut} transaction={transaction} cancelTransaction={cancelTransaction} />
                ))}
            </div>
        </div>
    );
};


export const TransactionComponent = ({ transaction, cancelTransaction }: { transaction: Transaction, cancelTransaction: (code: string) => void }) => {
    const statut = useMemo(() => transaction.statut, [transaction])
    // Utilisation de useCallback pour éviter la recréation de la fonction
    const transactionDate = useCallback((dateString: string): string => {
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    }, []);

    // Utilisation de useMemo pour mémoriser les valeurs calculées
    const { statusLabel, icon, statusColorClass } = useMemo(() => {
        let icon = faCheck;
        let statusLabel = 'Actif';
        let statusColorClass = 'bg-green-100 text-green-800';

        switch (statut) {
            case StatutPaiement.CANCEL:
                icon = faBan;
                statusLabel = 'Annuler';
                statusColorClass = 'bg-red-100 text-red-800';
                break;

            case StatutPaiement.FAILURE:
                icon = faHourglassEnd;
                statusLabel = 'Echec';
                statusColorClass = 'bg-gray-100 text-gray-500';
                break;
            case StatutPaiement.PENDING:
                icon = faClock;
                statusLabel = 'En cours';
                statusColorClass = 'bg-yellow-100 text-yellow-800';
                break;
            default:
                break;
        }

        return { statusLabel, icon, statusColorClass };
    }, [statut]);

    const handleChange = useCallback(
        () => {
            cancelTransaction(transaction.codePaiement)
        },
        [cancelTransaction, transaction]
    )

    return (
        <div className="flex flex-wrap items-center gap-y-4 py-6">
            <dl className="w-1/2 sm:w-1/4 lg:w-auto lg:flex-1">
                <dt className="text-base font-medium text-gray-500 ">ID Transaction:</dt>
                <dd className="mt-1.5 text-base font-semibold text-gray-900 ">
                    <span className="hover:underline">
                        #{transaction.codePaiement}
                    </span>
                </dd>
            </dl>
            <dl className="w-1/2 sm:w-1/4 lg:w-auto lg:flex-1">
                <dt className="text-base font-medium text-gray-500 ">Date Creation:</dt>
                <dd className="mt-1.5 text-base font-semibold text-gray-900 ">
                    {transactionDate(transaction.dateCreation)}
                </dd>
            </dl>
            {transaction.dateUpdate && <dl className="w-1/2 sm:w-1/4 lg:w-auto lg:flex-1">
                <dt className="text-base font-medium text-gray-500 ">Date Modification:</dt>
                <dd className="mt-1.5 text-base font-semibold text-gray-900 ">
                    {transactionDate(transaction.dateUpdate)}
                </dd>
            </dl>
            }            <dl className="w-1/2 sm:w-1/4 lg:w-auto lg:flex-1">
                <dt className="text-base font-medium text-gray-500 ">Statut:</dt>
                <dd className={clsx("me-2 mt-1.5 inline-flex items-center rounded px-2.5 py-0.5 text-xs font-medium", statusColorClass)}>
                    <FontAwesomeIcon icon={icon} className="size-3 mr-1" />
                    {statusLabel}
                </dd>
            </dl>

            <dl className="w-1/2 sm:w-1/4 lg:w-auto lg:flex-1">
                <dt className="text-base font-medium text-gray-500 "> Montant:</dt>
                <dd className="mt-1.5 text-base font-semibold text-gray-900 ">
                    {transaction.montant} CFA
                </dd>
            </dl>
            {transaction.statut === StatutPaiement.PENDING && <div className="w-full grid sm:grid-cols-2 lg:flex lg:w-64 lg:items-center lg:justify-end gap-4">
                <CancelModal cancelTransaction={handleChange} />

                {transaction.stripeUrl && <button
                    type="button"
                    onClick={() => {
                        window.open(transaction.stripeUrl, "_blank")
                    }}
                    className="w-full transition-all duration-300 inline-flex justify-center rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 lg:w-auto"
                    style={{ whiteSpace: 'nowrap' }} // Ajout de la propriété pour empêcher les retours à la ligne
                >
                    Continuer
                </button>}
            </div>}
        </div>
    );
};


interface CancelModalProps {
    cancelTransaction: () => void;
    triggerButton?: ReactNode; // Permet de passer un composant personnalisé pour ouvrir le modal
}

const CancelModal = ({ cancelTransaction, triggerButton }: CancelModalProps) => {

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);


    const handleSubmit = () => {

        cancelTransaction();
        closeModal();
    }

    return (
        <>
            {/* Modal toggle */}
            <div className="flex justify-center lg:m-5">
                {triggerButton ? (
                    <button onClick={openModal}>{triggerButton}</button> // Utilisation du bouton personnalisé si fourni
                ) : (
                    <button
                        onClick={openModal}
                        type="button"
                        className="w-full transition-all duration-300 rounded-lg border border-red-700 px-3 py-2 text-center text-sm font-medium text-red-700 hover:bg-red-700 hover:text-white focus:outline-none focus:ring-4 focus:ring-red-300 lg:w-auto"
                    >
                        Annuler
                    </button>
                )}
            </div>

            {/* Main modal */}
            {isOpen && (
                <div
                    tabIndex={-1}
                    aria-hidden="true"
                    className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50"
                >
                    <div className="relative p-4 w-full max-w-md h-auto">
                        {/* Modal content */}
                        <div className="relative p-4 text-center bg-white rounded-lg shadow sm:p-5">
                            <button
                                type="button"
                                onClick={closeModal}
                                className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                            >
                                <svg
                                    aria-hidden="true"
                                    className="w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <svg
                                className="text-gray-400 w-11 h-11 mb-3.5 mx-auto"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <p className="mb-4 text-gray-500 ">
                                Êtes-vous sûr de vouloir annuler cet transaction?
                            </p>
                            <div className="flex justify-center items-center space-x-4">
                                <button
                                    onClick={closeModal}
                                    className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10"
                                >
                                    Non, abandonner
                                </button>
                                <button
                                    onClick={handleSubmit}
                                    className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 "
                                >
                                    Oui, je suis sûr
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}





