import { useCallback, useState } from "react";
import coreApi from "../services/path/core_api";
import { Adresse } from "../types/model/adresseModel";

// Custom hook for fetching an address
const useAdresses = () => {
    const [adresse, setAdresse] = useState<Adresse>();
    const [getAdresseloading, setLoading] = useState<boolean>(false);
    const [errorGetAdresse, setError] = useState<string | null>(null);

    const getAdresse = useCallback(async (codeAdresse: string) => {
        try {
            setLoading(true);
            const result = await coreApi.get(`/adresses/search?codeAdresse=${codeAdresse}`);
            setAdresse(result.data);
        } catch (error) {
            setError("Failed to get adresse");
        } finally {
            setLoading(false);
        }
    }, []);

    return { adresse, getAdresseloading, errorGetAdresse, getAdresse };
};

export default useAdresses;
