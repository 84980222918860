import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { contactUs } from "../constant/constant";
import useRoutes from "../hooks/useRoutes";
import { AppDispatch, RootState } from "../store";
import { logout } from "../store/authSlice";
import { RouteLink } from "../types/model/route";
import { User } from "../types/model/user_model";
import { Logo } from "./logo";



const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isShrunk, setIsShrunk] = useState(false);

  const { navRoutes } = useRoutes();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.auth);
  const { cart } = useSelector((state: RootState) => state.cart);

  const [isDropdown1Open, setIsDropdown1Open] = useState(false);
  const [isDropdown2Open, setIsDropdown2Open] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = useCallback(() => {
    setIsDropdown1Open((prev) => !prev);
    setIsDropdown2Open(false);
  }, []);

  const toggleDropdown2 = useCallback(() => {
    setIsDropdown2Open((prev) => !prev);
    setIsDropdown1Open(false);
  }, []);

  useEffect(() => {
    if (location.pathname !== '/product') {
      setIsOpen(false);
    }
    setIsDropdown1Open(false);
    setIsDropdown2Open(false);
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsShrunk(true);
      } else {
        setIsShrunk(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav
      className={clsx(
        "bg-white fixed w-full z-10 top-0 transition-all duration-500",
        {
          " py-2": !isShrunk,
          " shadow": isShrunk,
        }
      )}
    >
      <div className="container  mx-auto px-4 xl:px-16 2xl:px-0 flex justify-between items-center">
        <div className="flex-shrink-0">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className="text-sm xl:text-base flex flex-row gap-8 xl:gap-14 2xl:gap-24 items-center justify-center">
          <div className="hidden lg:flex space-x-8 text-gray-800">
            <ul className="flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white ">
              {navRoutes.map((route) => {
                if (route.subLink) {
                  if (!route.isPricing) {
                    return (
                      <SubRoute
                        key={route.name}
                        handleChange={toggleDropdown}
                        isDropdownOpen={isDropdown1Open}
                        route={route}
                      />
                    );
                  } else {
                    return (
                      <SubPricingRoute
                        key={route.name}
                        handleChange={toggleDropdown2}
                        isDropdownOpen={isDropdown2Open}
                        route={route}
                      />
                    );
                  }
                } else {
                  return (
                    <li key={route.name} className="relative group py-2 px-3 md:p-0">
                      <Link
                        to={route.path}
                        className="text font-bold group-hover:text-primary-300 group-hover:font-semibold"
                      >
                        {route.name}
                      </Link>
                      <div className="block h-0.5 bg-primary-300 absolute left-0 -bottom-2 w-full scale-x-0 group-hover:scale-x-100 transition-transform origin-bottom-right group-hover:origin-bottom-left"></div>
                    </li>
                  );
                }
              })}

            </ul>
          </div>

          <div className="hidden lg:flex space-x-6 2xl:space-x-8">

            <div className="flex">
              <div className="relative space-x-4  flex items-center">
                {cart && <Cart />}
                {user && <Profil
                  user={user}
                  logout={(e) => dispatch(logout())}
                />}

              </div>
            </div>
            <a
              href={contactUs}
              className="px-4 xl:px-8 py-2 border-2 border-primary-300 bg-transparent hover:bg-primary-300 hover:text-white font-semibold transition-all duration-200 rounded-full flex items-center justify-center"
            >
              Contactez-nous
            </a>
            {user == null && (
              <Link
                to="/signin"
                className="bg-primary-300 text-white px-4 xl:px-8 py-2 rounded-full hover:bg-gray-600 font-bold flex items-center justify-center"
              >
                Connexion
              </Link>
            )}
          </div>
        </div>
        <div className="lg:hidden flex space-x-4">
          <div className="flex">
            <div className="relative space-x-4  flex items-center">
              {(cart) && <Cart />}
              {user && <div>
                <Profil
                  user={user}
                  logout={(e) => dispatch(logout())}

                />
              </div>}

            </div>
          </div>
          <button
            onClick={toggleMenu}
            className="text-blue-800 hover:text-gray-900 focus:outline-none"
          >
            <svg
              className="w-8 h-8 stroke-gray-800"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <div
        className={clsx(
          "h-screen container mx-auto px-4 xl:px-16 2xl:px-0 lg:hidden flex flex-col justify-between inset-0 transform overflow-y-auto transition-all duration-500 md:duration-200 ease-in-out",
          {
            "max-h-0 opacity-0": !isOpen,
            "pb-8 max-h-[90vh] opacity-100": isOpen,
          }
        )}
      >
        <ul className="text-lg lg:text-3xl flex md:py-8 flex-col md:gap-4 py-4 lg:p-0 lg:space-x-8 rtl:space-x-reverse lg:flex-row md:mt-0 md:border-0 md:bg-white">
          {navRoutes.map((route) => {
            if (route.subLink) {
              if (!route.isPricing) {
                return (
                  <SubRoute
                    key={route.name}
                    handleChange={toggleDropdown}
                    isDropdownOpen={isDropdown1Open}
                    route={route}
                  />
                );
              }
              return (
                <SubPricingRoute
                  key={route.name}
                  handleChange={toggleDropdown2}
                  isDropdownOpen={isDropdown2Open}
                  route={route}
                />
              )

            } else {
              return (
                <li
                  key={route.name}
                  className="relative group py-2 px-3 md:p-0"
                >
                  <Link
                    to={route.path}
                    className="font-bold group-hover:text-primary-300 group-hover:font-semibold"
                  >
                    {route.name}
                  </Link>
                  <div className="block h-0.5 bg-primary-300 absolute md:left-0 bottom-0 md:-bottom-2 w-8 lg:w-full scale-x-0 group-hover:scale-x-100 transition-transform origin-bottom-right group-hover:origin-bottom-left"></div>
                </li>
              )
            }
          }
          )}
        </ul>

        <div className="w-full flex flex-col gap-4 pt-4">
          <a
            href={contactUs}
            className="px-8 py-2 border-2 border-primary-300 bg-transparent hover:bg-primary-300 hover:text-white font-semibold transition-all duration-200 rounded-lg flex items-center justify-center"
          >
            Contactez-nous
          </a>

          <Link
            to="/signin"
            className="bg-primary-300 text-white px-8 py-2 rounded-lg hover:bg-gray-600 font-bold flex items-center justify-center"
          >
            Connexion
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;



const Cart = () => {
  const { cart } = useSelector((state: RootState) => state.cart);

  const items = useMemo(() => cart ? cart.items.length : 0, [cart])


  return <Link to="/cart">
    <div className="dropdown inline-block relative">
      <div className=" font-semibold py-2 2xl:px-2 xl:px-0 lg:px-4 md:px-3 sm:px-2.5 xs:px-2 w-auto inline-flex items-center">
        <div className=" group flex items-center cursor-pointer">
          <div className="relative">
            <div className={clsx("absolute w-[23.5px] h-[23.5px] -top-2 -right-[4px] flex items-center justify-center bg-red-500 rounded-full",
              { "hidden": (items === 0) }
            )}>
              <span className="text-white md:text-sm sm:text-xs xs:text-xs text-center">
                {items}
              </span>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="size-8 stroke-gray-600 ">
              <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
            </svg>

          </div>
          <span className="hidden sm:block 2xl:mx-2 xl:mx-1 xl:text-base lg:text-2sm md:mx-1 xl:block lg:hidden md:hidden sm:hidden xs:hidden text-gray-800 group-hover:text-primary-300 font-bold group-hover:text-primary-300 group-hover:font-semibold">
            Panier
          </span>
        </div>
      </div>
    </div>


  </Link>;
}


const Profil = ({ user, logout }: { user: User, logout: (e: any) => void }) => {

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(prev => !prev);
  };
  const handleClickOutside = (e: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className=" flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
      <button
        type="button"
        className="group flex text-sm rounded-full md:me-0 focus:ring-2 focus:ring-blue-500"
        onClick={toggleDropdown}
      >
        <span className="sr-only">Open user menu</span>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-[40px] h-[40px] text-gray-600 group-focus:text-primary-300"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
          />
        </svg>
      </button>

      {/* Dropdown menu */}
      <div
        ref={dropdownRef}
        className={`z-50 ${isDropdownOpen ? 'block absolute top-[50px] -right-16' : 'hidden'} my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow`}
      >
        <div className="px-4 py-3">
          <span className="block text-sm text-gray-900">
            {user.nom} {user.prenom}
          </span>
          <span className="block text-sm text-gray-500 truncate">
            {user.email}
          </span>
        </div>
        <ul className="py-2" aria-labelledby="user-menu-button">
          {/* <li>
            <Link
              to="/dashboard"
              className="block space-x-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 "
            >
              <FontAwesomeIcon icon={faHouse} className='size-4 text-gray-600'></FontAwesomeIcon>
              <span>Dashboard</span>
            </Link>
          </li> */}
          <li>
            <Link
              to="/me/profil"
              className="block space-x-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 "
            >
              <FontAwesomeIcon icon={faUser} className="size-4 text-gray-600"></FontAwesomeIcon>
              <span>Mon Compte</span>
            </Link>
          </li>

          <li>
            <button
              onClick={logout}
              className="block px-4 py-2  w-full text-sm text-gray-700 hover:bg-gray-100 "
            >
              Se déconnecter
            </button>
          </li>
        </ul>
      </div>

    </div>
  )
    ;
}

interface SubRouteProps {
  route: RouteLink;
  isDropdownOpen: boolean
  handleChange: (e: any) => void
}

const SubRoute: React.FC<SubRouteProps> = memo(
  ({ route, isDropdownOpen, handleChange }) => {

    return (
      <li className=" ">
        <div
          // to={'/products'}

          className="group flex items-center  w-full py-2 px-3  md:w-auto  md:border-0 md:hover:text-primary-700 md:p-0 "
        >
          <Link to={'/products'} className="font-bold group-hover:text-primary-300 group-hover:font-semibold">
            {" "}
            {route.name}
          </Link>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            onClick={handleChange}
            stroke="currentColor"
            className={`ml-2 h-5 w-5 font-bold group-hover:text-primary-300 group-hover:font-semibold  transition-transform duration-500 md:duration-200 ${isDropdownOpen ? "rotate-180" : ""
              }`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m19.5 8.25-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>

        <div
          className={clsx(
            "lg:absolute left-0 right-0 lg:mt-8 border-gray-200 lg:shadow-sm  lg:bg-white lg:border-t  transform overflow-hidden transition-all duration-500 md:duration-200 ease-in-out",
            {
              "max-h-0 opacity-0": !isDropdownOpen,
              "max-h-[1000px] opacity-100": isDropdownOpen
            })
          }>
          <div className="grid md:min-w-screen md:w-screen px-4 py-2 lg:py-10 mx-auto text-gray-900  sm:grid-cols-2 xl:sm:grid-cols-3 gap-8 lg:gap-24 px-8 lg:px-24 2xl:px-48">
            {route.subLink?.map(sub => (
              <div key={sub.title} className="flex flex-col ">
                <h3 className="font-semibold text-md lg:text-2xl xl:text-4xl uppercase lg:text-center">{sub.title}</h3>
                <div className="mt-2 mb-6 h-[0.5px] lg:h-[2px] w-full bg-primary-500"></div>
                <div className="w-full flex flex-col gap-1 md:gap-4">
                  {sub.routes?.map(subR => (
                    <div key={subR.name} className="px-4">
                      <li
                        key={route.name}
                        className="relative group py-2 px-3   md:p-0  "
                      >
                        <Link
                          to={subR.path}
                          className="capitalize group-hover:text-primary-300 group-hover:font-semibold"
                        >
                          {" "}
                          {subR.name}
                        </Link>
                        <div className="block h-0.5  bg-primary-300 absolute left-0 -bottom-2 w-16 scale-x-0 group-hover:scale-x-100 transition-transform origin-bottom-right group-hover:origin-bottom-left"></div>
                      </li></div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </li>
    );
  }
)



const SubPricingRoute: React.FC<SubRouteProps> = memo(({ route, isDropdownOpen, handleChange }) => {

  return (
    <li className=" ">
      <button
        onClick={handleChange}

        className="group flex items-center  w-full py-2 px-3  md:w-auto  md:border-0 md:hover:text-primary-700 md:p-0 "
      >
        <span className="font-bold group-hover:text-primary-300 group-hover:font-semibold">
          {" "}
          {route.name}
        </span>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          // onClick={toggleDropdown}
          stroke="currentColor"
          className={`ml-2 h-5 w-5 font-bold group-hover:text-primary-300 group-hover:font-semibold  transition-transform duration-500 md:duration-200 ${isDropdownOpen ? "rotate-180" : ""
            }`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m19.5 8.25-7.5 7.5-7.5-7.5"
          />
        </svg>
      </button>

      <div
        className={clsx(
          "lg:absolute left-0 right-0 lg:mt-8 border-gray-200 lg:shadow-sm  lg:bg-white lg:border-t  transform overflow-hidden transition-all duration-500 md:duration-200 ease-in-out",
          {
            "max-h-0 opacity-0": !isDropdownOpen,
            "max-h-[400px] opacity-100": isDropdownOpen
          })
        }>
        <div className="grid md:min-w-screen md:w-screen px-4 py-2 lg:py-10 mx-auto text-gray-900  sm:grid-cols-2 xl:sm:grid-cols-3 gap-8 lg:gap-24 px-8 lg:px-24 2xl:px-48">
          {route.subLink?.map((sub, index) => (
            <div key={`${sub.title}_${index}`} className="flex flex-col ">
              <h3 className="font-semibold text-md lg:text-2xl xl:text-4xl uppercase lg:text-center">{sub.title}</h3>
              <div className="mt-2 mb-6 h-[0.5px] lg:h-[2px] w-full bg-primary-500"></div>
              <div className="w-full flex flex-col gap-1 md:gap-4">
                {sub.routes?.map(subR => (
                  <div key={subR.name} className="px-4">
                    <li
                      key={route.name}
                      className="relative group py-2 px-3   md:p-0  "
                    >
                      <Link
                        to={subR.path}
                        className="capitalize group-hover:text-primary-300 group-hover:font-semibold"
                      >
                        {" "}
                        {subR.name}
                      </Link>
                      <div className="block h-0.5  bg-primary-300 absolute left-0 -bottom-2 w-16 scale-x-0 group-hover:scale-x-100 transition-transform origin-bottom-right group-hover:origin-bottom-left"></div>
                    </li></div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </li>
  );
})
