import React, { useId, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const Pagination = ({ totalPages, currentPage, handlePageChange }: { totalPages: number, currentPage: number, handlePageChange: (page: number) => void }) => {

  const getPaginationRange = () => {
    const pageNumbers = [];
    const delta = 2; // Nombre de pages à afficher de part et d'autre de la page active
    const rangeWithDots = [];

    // Si il y a moins de 7 pages, afficher toutes les pages
    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
      return pageNumbers;
    }

    // Si currentPage est dans les premières pages
    if (currentPage <= delta + 2) {
      for (let i = 1; i <= Math.min(3 + delta, totalPages - 4); i++) {
        rangeWithDots.push(i);
      }
      rangeWithDots.push('...');
      rangeWithDots.push(totalPages);
    }
    // Si currentPage est dans les dernières pages
    else if (currentPage >= totalPages - delta - 1) {
      rangeWithDots.push(1);
      rangeWithDots.push('...');
      for (let i = totalPages - (2 + delta); i <= totalPages; i++) {
        rangeWithDots.push(i);
      }
    }
    // Si currentPage est au milieu
    else {
      rangeWithDots.push(1);
      rangeWithDots.push('...');
      for (let i = currentPage - delta; i <= currentPage + delta; i++) {
        rangeWithDots.push(i);
      }
      rangeWithDots.push('...');
      rangeWithDots.push(totalPages);
    }

    return rangeWithDots;
  };

  const id = useId();
  const paginationRange = useMemo(getPaginationRange, [totalPages, currentPage]);

  return (
    <nav className="flex items-center justify-center" aria-label="Page navigation example">
      <ul className="flex h-8 items-center -space-x-px text-sm">
        <li>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="flex h-8 items-center justify-center rounded-s-lg border border-e-0 border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <span className="sr-only">Previous</span>
            <FontAwesomeIcon icon={faArrowLeft} className="h-5 w-5" />
          </button>
        </li>

        {paginationRange.map((page, index) =>
          page === '...' ? (
            <li key={id + index}>
              <span className="flex h-8 items-center justify-center border px-3 leading-tight text-gray-500">...</span>
            </li>
          ) : (
            <li key={id + index}>
              <button
                onClick={() => handlePageChange(page as number)}
                className={`flex h-8 items-center justify-center font-semibold border px-3 leading-tight ${currentPage === page
                  ? 'border-primary-300 bg-primary-300  text-white'
                  : 'border-gray-300 bg-white text-gray-600 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                  }`}
              >
                {page}
              </button>
            </li>
          )
        )}

        <li>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="flex h-8 items-center justify-center rounded-e-lg border border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <span className="sr-only">Next</span>
            <FontAwesomeIcon icon={faArrowRight} className="h-5 w-5" />
          </button>
        </li>
      </ul>
    </nav>
  );
};
export default Pagination;