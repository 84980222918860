import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cameroonCities, dailyCost, monthlySubscription } from "../../../constant/constant";
import { calculateCostDepla, calculateInstallationCost, calculateNumberDays, calculatePlanCost } from "../../../constant/functions";
import { AppDispatch, RootState } from "../../../store";
import { setLocation, setNumberInstallationDays } from "../../../store/subscriptionSlice";

interface ModalProps {
    onClose: (e: any) => void;
}


const ModalFormOdooLocal: React.FC<ModalProps> = ({ onClose }) => {

    const dispatch = useDispatch<AppDispatch>();
    const {

        numberOfItem,
        location,
        isAnnual,
        hostingType, numUsers,
        typePlan
    } = useSelector((state: RootState) => state.subscription);

    const pricePerMonth = useMemo(() => calculatePlanCost(typePlan), [typePlan]);


    const [selectedCity, setSelectedCity] = useState<string>(location ?? 'Douala');

    const handleCityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCity(event.target.value);
    };


    const nDays = useMemo(
        () =>
            calculateNumberDays(
                numberOfItem,

            ),
        [numberOfItem]
    );

    const deplacementForfait = useMemo(
        () => calculateCostDepla(selectedCity, nDays),
        [selectedCity, nDays])

    const cost = useMemo(
        () =>
            calculateInstallationCost(
                deplacementForfait,
                dailyCost,
                nDays


            ),
        [deplacementForfait, nDays]
    );

    useEffect(() => {
        dispatch(setLocation(selectedCity))
        dispatch(setNumberInstallationDays(nDays))
    }, [nDays, selectedCity, dispatch])


    return (
        <div className="space-y-4 text-base  text-gray-800">
            <span>
                Une mise en place réussie commence par une analyse approfondie des
                besoins de votre entreprise.
            </span>
            <div className="w-full flex flex-wrap font-medium text-gray-700 gap-x-4 items-center">
                <label htmlFor="city-select" className="block">Votre entreprise est situer à </label>
                <select id="city-select" value={selectedCity} onChange={handleCityChange}
                    className="appearance-none bg-white text-gray-700 border border-gray-500 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white  transition
                 ease-in-out duration-500">
                    {cameroonCities.map((city) => (
                        <option key={city} value={city}>
                            {city}
                        </option>
                    ))}
                </select>


            </div>
            <div>
                <span className="font-semibold text-lg underline underline-offset-2">Prérequis : </span>
                <ul className="px-8 py-2 list-outside list-disc">
                    {numberOfItem < 50
                        ? (<li>Serveur Linux 4 vcpu de 4Go de Ram </li>)
                        : (<li>Serveur Linux 4 vcpu de 16Go de Ram </li>)}
                </ul>
            </div>

            <div>
                <span className="font-semibold text-lg underline underline-offset-2">Forfait d'installation :</span>
                <ul className="px-8 py-2 list-outside list-disc">
                    <li className="">
                        <span className="">
                            Instance odoo {hostingType} ({numUsers}{" "}
                            {numUsers > 1 ? "utilisateurs" : "utilisateur"})
                        </span>
                        <span className="font-medium">
                            <span className="uppercase font-bold"> : {pricePerMonth.toLocaleString()} FcFa</span>

                            {(isAnnual && hostingType === "cloud") && ` x ${monthlySubscription}`}
                        </span>
                    </li>                    <li>Tarif journalier : <span className="font-bold" >{dailyCost} FcFa</span></li>
                    <li>Nombres de d'employes : <span className="font-bold" > {numberOfItem}</span></li>
                    <li>Nombres de jours : <span className="font-bold" > {nDays} Jours</span></li>
                    <li>Forfait de déplacement : <span className="font-bold" > {deplacementForfait}</span></li>


                </ul>
            </div>




            <div className="border-t py-4 mx-4">
                <p className="flex flex-wrap items-center text-base leading-relaxed text-gray-800 font-medium">
                    <span>Coût du projet :</span>{" "}
                    <span className="ml-8 text-3xl md:text-5xl text-gray-800">
                        {cost} FcFa
                    </span>
                </p>
            </div>
            <div className="flex items-center">
                <button
                    onClick={onClose}
                    type="button"
                    className="px-16 py-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-lg text-center font-semibold"
                >
                    Fermer
                </button>
            </div>
        </div>
    );
};

export default ModalFormOdooLocal;


