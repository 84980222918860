import axios from 'axios';
import urls from '../../constant/urls';

const authApi = axios.create({
  baseURL: urls.authUrl,  // URL définie dans le fichier .env
  headers: {
    'Content-Type': 'application/json',
  },
});

authApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token")
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(`error ${error}`);
  }
);

export default authApi;
