import React, { createContext, useState, useContext, ReactNode, useMemo } from "react";
import { FormDataState } from "../types/model/form_data_model";
import { User } from "../types/model/user_model";

// Define the type for the context
interface FormContextProps {
    formData: FormDataState;
    setFormData: React.Dispatch<React.SetStateAction<FormDataState>>;
}

// Create the context with an initial value of null
const FormContext = createContext<FormContextProps | null>(null);

// Create a provider component to wrap the application
export const FormProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const user: User | null = JSON.parse(localStorage.getItem("user") ?? "null");

    const [formData, setFormData] = useState<FormDataState>({
        firstName: user?.nom ?? "",
        lastName: user?.prenom ?? "",
        company: "",
        email: user?.email ?? "",
        address: "Littoral-cameroun",
        postalCode: "",
        city: "",
        country: "Cameroun",
        taxNumber: "",
        phoneNumber: user?.telephone ?? "",
        methodOfPayment: ""
    });
    const state = useMemo(() => ({ formData, setFormData }), [formData]);
    return (
        <FormContext.Provider value={state}>
            {children}
        </FormContext.Provider>
    );
};

// Create a custom hook to access the form context
export const useFormContext = (): FormContextProps => {
    const context = useContext(FormContext);
    if (!context) {
        throw new Error("useForm must be used within a FormProvider");
    }
    return context;
};
