import React from "react";
import clsx from "clsx";
import ImageMotion from "./commons/wrappers/imageMotion";
import { Images } from "../constant";
import { ProductOdoo } from "../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FontionnalityModel } from "../types/model/service_product_model";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

export const ProductView = ({
  product,
  index,
}: {
  product: ProductOdoo;
  index: number;
}) => {
  const containerClasses = clsx(
    "flex",
    "flex-col",
    "items-center",
    "px-0",
    "-py-0",
    "mx-0",
    "w-full",
    "md:px-8 xl:px-0",
    "flex-col-reverse ",
    "gap-x-8 py-8 md:py-10",
    index % 2 !== 0
      ? clsx("sm:flex-row-reverse", "lg:pr-24", "lg:gap-16")
      : "sm:flex-row",
    "sm:justify-between",
    index % 2 === 0 && "2xl:gap-32 lg:gap-24"
  );

  const imageView = (
    <ImageMotion
      key={product.name}
      classNames="bg-gray-50 w-full h-full flex items-center "
      Component={
        <img 
          src={product.exImageUrl ?? (Images.background as string)}
          alt={product.name}
          className={"object-fit w-full max-h-[600px]"}
          loading="lazy"
          decoding="async"
        />
      }
    />
  );

  return (
    <div className="flex flex-col w-full gap-8 md:gap-16">
      <div className={containerClasses}>
        <div className=" flex flex-col h-full w-full md:w-1/2  ">
          <div className="w-full flex flex-row items-center justify-start gap-4  ">
            {product.src && (
              <div className="min-h-14 h-14 w-14 min-w-14 md:min-h-20 md:h-20 md:w-20 md:min-w-20 bg-gray-50 shadow-md rounded-md flex items-center justify-center">
                <img loading={'lazy'} src={product.src} alt={product.name} />
              </div>
            )}
            <h4 className="font-medium text-primary-700 text-2xl lg:text-4xl space-x-4 md:space-x-0 font-semibold flex flex-row">
              <span>{product.name}</span>
            </h4>
          </div>
          <div className="my-4 mt-10 block sm:hidden w-full">{imageView}</div>
          <div className="flex flex-col items-left my-6 md:my-0 mx-0">
            <div />
            <div className=" my-2 lg:my-8 w-full font-normal text-justify text-lg leading-8 text-base flex flex-col gap-4">
              {
                product.description
                  ?.split("\n")
                  .map((value) => (
                    <p key={value.replaceAll(" ", "_")}>{value}</p>
                  ))}
            </div>
          </div>
          {product.fonctionnalite && (
            <div
              className="w-full  grid grid-cols-2
                  gap-x-2 gap-y-4 items-center"
            >
              {product.fonctionnalite.map((fn, index) => (
                <li
                  key={fn.name}
                  className="font-medium text-lg flex items-center"
                >
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className={clsx("mr-4", {
                      "text-blue-500": index % 3 === 0,
                    },
                      {
                        "text-gray-600": index % 3 === 1,
                      },
                      {
                        "text-primary-700": index % 3 === 2,
                      })}
                  />
                  {fn.name}
                </li>
              ))}
            </div>
          )}
        </div>
        <div
          className={clsx(
            "hidden sm:block mb-8 w-full md:w-1/2 h-full flex items-center justify-start",
            { "justify-center": index % 2 !== 0 }
          )}
        >
          {imageView}
        </div>
      </div>
      {product.fonctionnalite && (
        <div className="relative pb-16 md:pb-16 xl:mb-0 w-full h-full flex flex-wrap justify-center gap-y-16 lg:gap-y-8 gap-x-[55px] xl:gap-x-10 2xl:gap-x-[55px] px-8 md:px-0">
          {product.fonctionnalite?.map((fn, index) => (
            <div
              className="w-full sm:w-[calc(50%-55px)] lg:w-[calc(33.33%-55px)] xl:w-[calc(22%)] 2xl:w-[calc(25%-55px)]"
              key={"key-" + fn.name}
            >
              <Fonctionnalite index={index} subDetail={fn} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Fonctionnalite = ({
  subDetail,
  index,
}: {
  subDetail: FontionnalityModel;
  index: number;
}) => {
  return (
    <div className="relative transition-all duration-300 ease-in-out overflow-hidden w-full hover:scale-110 shadow shadow-lg">
      <div
        className={clsx("h-[5px] w-full rounded-t-lg", {
          "bg-blue-500": index % 3 === 0,
        },
          {
            "bg-gray-400": index % 3 === 1,
          },
          {
            "bg-primary-700": index % 3 === 2,
          })}
      ></div>
      <div className="py-8 w-full px-4 flex flex-col gap-8 items-center">
        {subDetail.icon && (
          <div className="h-20 w-20 flex items-center justify-center rounded-full bg-gray-100">
            <FontAwesomeIcon
              icon={subDetail.icon}
              className={clsx("w-8 h-8", {
                "text-blue-500": index % 3 === 0,
              },
                {
                  "text-gray-600": index % 3 === 1,
                },
                {
                  "text-primary-700": index % 3 === 2,
                })}
            />
          </div>
        )}
        <p className="text-xl font-semibold mt-1 text-center">
          {subDetail.name}
        </p>
        <p className="text-base font-base text-center">
          {subDetail.description}
        </p>
      </div>
    </div>
  );
};
