import React from 'react'
import { Employe } from '../../../types'

export const EmployeComponent = ({ employe }: {
    employe: Employe
}) => {
    return (

        <div className='w-full  h-full flex flex-col md:flex-row  md:gap-8 xl:gap-8 items-center '>
            <div className='w-full py-8  md:w-36 md:h-36 flex items-center justify-center' >
                <div className='w-36 h-36  rounded-full bg-gray-100'>
                    <img src={employe.image} alt={employe.name} loading="lazy" className="object-fit w-full" />
                </div>
            </div>
            <div className=' w-full lg:w-2/3  flex flex-col gap-4'>
                <h4 className='font-bold text-2xl lg:text-3xl'><strong>{employe.name}</strong>, {employe.role}</h4>
                <p className='text-justify'>{employe.description}</p>
            </div>
        </div>
    )
}
